@import "colors";
@import "common/utils";
@import "fonts";

body > footer.mtb {

  $link-color-map: (
          main:$cream-3,
          hover:$cream-2,
          active:$cream-4,
          visited:$cream-3
  );

  background-color: $blue-4;
  padding: 32px 4px;

  h5, h6 {
    color: #ffffff;
    font-family: $font-header-primary;

    &.copyright {
      font-size: 0.75rem;
      letter-spacing: 1.2px;
    }
  }
  a {
    @include link($link-color-map);
  }

  .links {
    @include media-breakpoint-up(md) {
      flex: 1 0 50%;
    }
    @include media-breakpoint-up(lg) {
      flex: 1 0 33%;
    }

    &> div {
      padding: 0 4px;
    }
    a {
      margin-top: 16px;
    }
  }

  .pay {
    @include media-breakpoint-up(md) {
      flex: 1 0 50%;
    }
    @include media-breakpoint-up(lg) {
      flex: 1 0 33%;
      order: 2;
    }
  }

  .social-legal {
    @include media-breakpoint-up(lg) {
      flex: 1 0 33%;
      order: 1;
    }
  }

  .social {
    a:first-child {
      padding-left: 0;
    }
    img {
      min-width: 2.5rem;
    }
  }
}