.product-view {

  $xs-height: 260px;
  $sm-height: 393px;
  $md-height: 317px;
  $lg-height: 408px;
  $xl-height: 492px;

  .product-image-gallery {
    display: flex;
    position: relative;
    height: $xs-height + 40px;
    background-color: white;

    @include media-breakpoint-up(sm) {
      height: $sm-height;
    }
    @include media-breakpoint-up(md) {
      height: $md-height;

      .zoom-area{
        display: block;
      }
    }
    @include media-breakpoint-up(lg) {
      height: $lg-height;
    }
    @include media-breakpoint-up(xl) {
      height: $xl-height;
    }

    &.video {
      background-color: transparent;
      #magnify-product {
        display: none;
      }
    }

    .gallery-image {
      display: none;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      margin: auto;
      &.visible {
        display: block;

        @include media-ie11 {
          display: flex;
          flex: 0 0 auto;
        }

        &.hidden {
          visibility: hidden;
        }
      }

      &.gallery-video {
        height: $xs-height;
        display: block;
        .video {
          height: $xs-height;
        }
        @include media-breakpoint-up(sm) {
          height: $sm-height;
          .video {
            height: $sm-height;
          }
        }
        @include media-breakpoint-up(md) {
          height: $md-height;
          .video {
            height: $md-height;
          }
        }
        @include media-breakpoint-up(lg) {
          height: $lg-height;
          .video {
            height: $lg-height;
          }
        }
        @include media-breakpoint-up(xl) {
          height: $xl-height;
          .video {
            height: $xl-height;
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      #magnify-product {
        display: none !important;
      }
    }

    &.video {
      display: block;
    }
  }
}

.more-views {
  overflow-x: auto;
  text-align: left;

  .product-image-thumbs {
    margin: 8px 0;
    padding: 0;
    height: 77px;
    white-space: nowrap;

    li {
      display: inline-block;
      background-color: $white;
    }

    a {
      display: inline-block;
      border: 1px solid transparent;
      &.active {
        border-color: $blue-4;
      }

      &.video-thumb{
        position: relative;

        &:after{
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height:100%;
          left:0;
          top:0;
          background: url(-mage-img('play-icon.svg')) no-repeat center center;
          background-size: 40% auto;
        }
      }
    }
  }
}
.no-touch .product-image-thumbs a:hover {
  border-color: #c7c7c7;
}
