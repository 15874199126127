.checkout-cart-index {
  .product-cart-items {
    @extend .container;
    @extend .card;

    &__loader {
      display: none;
      position: absolute;
      background-color: rgba($white, .5);
      z-index: 2;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .product-cart-item {
      @extend .py-3;
      border-bottom: 1px solid silver;
    }
  }
  .product-cart-name {
    @extend .mb-1;

    max-width: 250px;
    @include media-breakpoint-up(lg) {
      max-width: 310px;
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }

    .brand-name {
      color: $brown-7;
      font-size: $font-size-sm;
      font-weight: $font-weight-normal;
      text-transform: none;
    }

    .product-name {
      font-size: $font-size-orig;
      font-weight: $font-weight-bold;
    }
  }
  .product-cart-image {
    img {
      width: 100%;
    }
  }
  .product-cart-info {
    .product-cart-availability {
      @extend .mb-2;
      font-weight: $font-weight-bold;
      font-size: $font-size-sm;

      &.in-stock {
        color: $green-7;
      }

      &.not-available {
        color: $red-4;
      }
    }
    .product-cart-messages {
      font-size: $font-size-sm;

      .item-msg {
        @extend .alert;
        display: inline-block;
        padding: 0.25rem 0.5rem;

        &.error {
          @extend .alert-danger;

          &:before {
            @include fa-solid();
            content: "\f06a";
          }
        }
      }
    }
    .product-cart-options {
      @extend .row;
      @extend .justify-content-start;

      font-size: $font-size-sm;

      .heading {
        @extend .col-auto;
        font-weight: $font-weight-bold;
        //white-space: nowrap;
        max-width: 50%;

        &:after {
          content: ':';
        }

        &.qty {
          @extend .align-self-center;
        }
      }

      .value {
        @extend .col;
        max-width: 200px;

        &.qty {
          .form-control {
            display: inline-block;
            width: auto; // not 100%
            //height: auto;
            padding: 0.25rem 0.5rem;
          }

          .large-qty-update-form {
            .btn {
              @extend .ml-1;
            }
          }
        }
      }

      .break {
        @extend .w-100;
        @extend .mb-1;
      }
    }
    .product-cart-price {
      @extend .mb-2;

      .multiple-qty {
        font-size: $font-size-sm;
        @extend .mb-1;

        .each {
          display: inline-block;
        }

        del {
          @extend .mr-2;
        }
      }

      .row-total {
        font-weight: $font-weight-bold;
        font-size: $font-size-orig;

        .sale {
          //color: $red-4;
        }

        del {
          display: block;
          font-size: $font-size-sm;
          font-weight: $font-weight-normal;

          @include media-breakpoint-up(md) {
            padding-left: 0.5rem;
          }
        }
      }

      @include media-breakpoint-up(md) {
        position: absolute;
        right: 1.5rem;
        top: 0;
        text-align: right;
      }

      del {
        display: inline-block;
        color: $grey-4;
      }

      .savings {
        display: inline-block;
        padding-top: 0.5rem;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
        color: $green-7;

        @include media-breakpoint-up(md) {
          display: block;
          padding-top: 0;
          padding-left: 0.75rem;
        }
      }
    }
    .product-cart-additional {

      .store-credit {
        @extend .mt-3;
        @extend .mb-0;
        @extend .alert;
        @extend .alert-success; // TODO: Shouldn't this be green?
        @extend .d-inline-block;
        padding: 0.25rem 0.5rem;
        font-size: $font-size-sm;
        font-weight: bold;

        .fas {
          display: inline-block;
          padding-right: 0.25rem;
        }

        a {
          font-weight: $font-weight-normal;
          text-decoration: underline;
          white-space: nowrap;
        }
      }

      .member-only {
        @extend .mt-2;
      }
    }
    .cart-links {
      @extend .mt-3;
      font-size: 0.75rem;
      white-space: nowrap;

      a {
        display: inline-block;

        &:not(:first-of-type) {
          @extend .ml-2;

          &:before {
            content: '|';
            @extend .mr-3;
          }
        }
      }
    }

  }
}

.free-items {
  &__header {
    display: flex;
    align-items: center;

    &__title,
    &__button {
      font-size: $font-size-base;
      line-height: 1;
    }

    &__title {
      margin-bottom: 0;
    }

    &__controls {
      margin-left: auto;
      display: flex;
    }

    &__button {
      flex-grow: 0;
      flex-shrink: 0;
      padding: 0 10px;
      display: block;

      &:last-child,
      &.toggle{
        padding-right: 0;
      }

      &,
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &__free {
    color: $green-7;
  }

  .product-cart-items {
    top: 0 !important;
  }

  &--collapsed{
    .free-items__header__button{
      &--hide-collapse{
        display: none;
      }
      &--show-collapse{
        display: block;
      }
    }
  }

  &:not(&--collapsed){
    .free-items__header__button{
      &--hide-collapse{
        display: block;
      }
      &--show-collapse{
        display: none;
      }
    }
  }
}

.customer-account-mtb .account-sidebar,
.sales-order-view,
.checkout-cart-index,
.free-items{
  .card {
    @extend .mb-3;
    .card-header {
      background-color: inherit;
      border: none;

      .card-title {
        @extend h5;
        font-size: 0.75rem;
      }
    }

    .card-body {
      @extend .pt-2;
      a {
        text-decoration: underline;

        &.btn, &.pdp {
          text-decoration: none;
        }
      }
    }
  }
}
