.builder-page {
  .tooltip-close {
    top: 0.5rem;
    right: 0.5rem;
  }

  .builder-product-card {
    @extend .col-12;
    @extend .col-md-4;
    @extend .col-lg-3;
    @extend .mb-3;

    // Necessary due to builder elements possibly overriding
    line-height: 1.5;
    min-width: 288px;

    .product-image {
      min-width: unset;
    }

    .product.card {
      min-width: 272px;
    }
  }
}