.tackle-insurance {
  .card {
    border: 0;
    display: flex;
    flex-direction: column;
    height: 100%;

    .card-body {
      padding-bottom: 0;
      flex-grow: 1;
    }

    .product-name {
      color: $blue-4;
      margin-bottom: 0.5rem;


      .brand {
        color: $brown-7;
      }
      .last-purchase-date {
        color: $grey-1;
      }
      .last-purchase-date,
      .brand {
        font-size: 12px;
      }

      .name {
        font-size: 1.25rem;
        line-height: 1.2;
        font-family: $font-header-primary;
        text-transform: uppercase;
        font-weight: 600;
        @include media-breakpoint-up(md) {
          min-height: 3rem; /* Allow for 2 lines of text */
        }
      }
    }

    .reviews {
      margin-bottom: 1rem;
      min-height: 21px; // Avoid reflowing content when Powerreviews loads

      .p-w-r {
        margin-left: 0;
        .pr-snippet {
          margin: 0;
        }
      }
    }

    .product-image {
      margin: 0rem -1.25rem 0.5rem; /* Offset the padding on the card body */

      .img-fluid {
        width: 100%;
      }
    }

    .product-description {
      p {
        font-size: 13px;
        flex-grow: 1;
      }
    }

    .select-container {
      margin-bottom: 0.5rem;
    }

    .card-footer {
      background: none;
      border: 0;
      justify-content: flex-end;

      label.error {
        background-color: #c00;
        color: white;
        border-radius: 4px;
        padding: 0.2rem 0.4rem;
        font-weight: bold;
      }
    }
  }
}