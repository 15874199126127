#product-options-wrapper {

  dd.swatch-attr {
    margin-bottom: 0; // Margin is applied to the swatches themselves
  }

  .product-attr {

    /* === LABEL & VALIDATION ERRORS === */
    &__label, &__error {
      // Need to have the same height so they are interchangeable without
      // the page content shifting
      margin: 0 0 .25rem;
      padding: .25rem .75rem .25rem 0;
      font-size: .75rem;
      
      .attr-label {
        text-transform: uppercase;
      }
    }

    &__label {
      color: $green;
      font-weight: $font-weight-normal;

      span {
        &:first-of-type {
          &:after {
            content: ':';
          }
        }
      }
    }

    &__error {
      padding-left: 0.5rem;
      background: $red-5;
      color: $white;
      border-radius: $border-radius;
      font-weight: $font-weight-bold;
      display: inline-block;

      &:before {
        @include fa-solid;
        content: '\f06a';
      }

      &__message {
        margin-left: 0.25rem;
      }
    }

    /* === SWATCHES === */

    &__swatch-list {
      padding-left: 0;
      margin-bottom: 0;

      &__option {
        float: left;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;

        &__link {
          display: block;
          position: relative;
          font-size: 0.75rem;
          line-height: normal;
          text-align: center;
          text-decoration: none;
          background-color: white;

          &:hover {
            color: $green; // Where is the black text color coming from?
          }

          &:not(.has-image) {
            box-sizing: border-box;
            padding: 0.5rem 0.75rem;
            height: 2.25rem;
            min-width: 1.5rem;
            // Vertically center the option text
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            border: 1px solid #cccccc;

            // Add an invisible pseudo-element with the width of bold text.
            // When the text swatch is selected and bolded, the container
            // won't expand.
            span {
              &:after {
                display: block;
                content: attr(title);
                font-weight: $font-weight-bold;
                height: 1px;
                color: transparent;
                overflow: hidden;
                visibility: hidden;
              }
            }
          }

          &.has-image {
            height: 2.5rem;
            width: 2.5rem;
            overflow: hidden;

            // Need to use a div with background-image here to avoid odd white space between
            // the image and the border
            .swatch-image {
              width: 100%;
              height: 100%;
              background-size: 100% 100%;
              border: 1px solid #cccccc;
            }
          }

        }

        &.selected {
          outline: 1px solid $green;

          .product-attr__swatch-list__option__link { // TODO: Better way to reference this?
            &.has-image {
              &:before {
                display: block;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%; // Expand to match swatch
                width: 100%;
                box-sizing: border-box;
                border: 2px solid white;
              }
            }

            &:not(.has-image) {
              font-weight: $font-weight-bold;
            }
          }
        }

        &.not-available, &.out-of-stock {
          display: none;
          .product-attr__swatch-list__option__link { // TODO: Better way to reference this?
            &:not(.has-image) {
              background-color: #f5f5f5;
              color: $grey-1;
              font-weight: $font-weight-normal; // Keep unbolded in case also selected
            }

            &.has-image {
              &:after {
                border-top: 2px solid white;
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                left: -50%;
                transform: rotate(-45deg);
                transform-origin: 100% 0;
              }
            }
          }
        }
      }
    }
  }
}