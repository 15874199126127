#pr-questiondisplay {
  .p-w-r {
    margin: 0;
    color: inherit;

    .pr-qa-display {
      background-color: inherit;
      padding: 0;

      .pr-btn-answer:focus,
      .pr-btn-ask-question:focus {
        outline: none;
        box-shadow: none;
        .pr-qa-display-text {
          text-shadow: none;
          background-image: none;
          background-size: unset;
          background-position: unset;
          background-repeat: unset;
        }
      }

      &.pr-qa-display-desktop {
        .pr-qa-display-search {
          .pr-qa-display-searchbar {
            width: 50%;
          }
        }
      }

      .pr-btn-show-more {
        margin-left: 0.1rem;
        @include media-breakpoint-up(lg) {
          margin-left: -1.5rem;
        }

        color: #77ae6a;
        text-decoration: underline;
        background: unset;

        &:active,
        &:focus {
          box-shadow: none;

          .pr-qa-display-text {
            text-decoration: none;
            text-shadow: unset;
            background-image: unset;
            background-size: unset;
            background-position: unset;
            background-repeat: unset;
          }
        }

        .pr-qa-display-text {
          color: inherit;
        }
      }

      .pr-qa-display-headline {
        margin: 0 0 20px;

        h1 {
          margin-top: 0;
          letter-spacing: inherit;
          text-align: center;

          abbr {
            display: none;
          }

          &:after {
            content: 'Customer Questions & Answers';
            color: $green;
            font-size: 2rem;
          }
        }

        .pr-qa-display-thankyou-headline {
          @extend .mb-5;
          width: 100%;
        }

        .pr-qa-display-search {
          @extend %heading-box;

          form {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
          }

          label {
            width: auto !important;
            display: block;
            font-weight: $font-weight-bold;
            font-size: map-get($font-sizes, 'h4')!important;
            margin-bottom: 0;
            text-transform: uppercase;
            color: inherit;

            @include media-breakpoint-down(md) {
              display: none;
            }
          }

          .pr-qa-display-searchbar {
            display: block;

            @include media-breakpoint-down(md) {
              flex: 1 0 auto;
            }

            input {
              @extend %form-control;
            }

            button {
              &.pr-qa-search-btn {
                @extend %button-secondary;
                cursor: pointer;
                border-radius: 0!important;
                padding: 0!important;
                height: 34px!important;

                .pr-search-icon {
                  > svg > path {
                    fill: $cream-6!important;
                  }
                }
              }
            }
          }
        }

        .pr-qa-display-sort {
          .pr-qa-sort-headline {
            font-size: 13.712px !important;
            font-weight: 700 !important;
            font-family: $font-family-base;
            text-transform: none;
          }

          .pr-qa-sort-group {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            &::after {
              display: none;
            }

            label {
              flex: 1 0 auto;
              margin-bottom: 0;
            }
          }

          .pr-qa-sort {
            @extend %form-control;
            appearance: menulist !important;
          }
        }
      }

      .pr-qa-display-question {
        margin-bottom: 0;

        .pr-qa-display-questionlist {
          margin: 0 2rem;

          .pr-qa-display-item {

            .pr-qa-display-item-icon {
              background-color: $green;
              border-color: $green;
            }

            .pr-qa-display-item-text { // Question title
              text-align: left;
              margin: 0;
              font-size: 16px !important;
              font-family: $font-family-base;
              line-height: 1.1;
              color: $green !important;
            }
          }

          .pr-qa-display-item-details {
            .pr-subscript {
              bottom: inherit;
            }
          }

          .pr-cross-icon {
            circle, line {
              stroke: currentColor;
            }
          }
        }
      }

      .pr-qa-display-ask-question {
        @include media-breakpoint-down(md) {
          margin: 0 2rem 25px;

          &.pr-question-submitted {
            .pr-qa-display-thankyou-headline {
              float: none;
            }
          }
        }

        .pr-accessible-btn {
          &:focus {
            box-shadow: none;
          }
        }
      }

      .pr-btn-answer {
        color: $green-6;

        span {
          color: $green-6;
          text-decoration: underline;
        }
      }

      .pr-qa-display-answer {

        .pr-qa-display-answer-by-container {

          &.pr-qa-display-answer-by-container-expert {
            border-left-color: $green-6;
          }

          .pr-qa-display-answer-details {
            .pr-qa-display-answer-text {
              line-height: 1.6;
            }

            .pr-qa-display-answer-icon {
              background: transparent;
              border-color: transparent;

              .pr-badging-icon {
                color: $secondary;
              }

              g[fill] {
                fill: currentColor !important;
              }
  
              polygon {
                fill: $body-bg;
              }
            }

            .pr-helpful-btn {
              background-color: transparent;
            }
          }
        }
      }

      .pr-btn-ask-question {
        color: $secondary;

        .pr-cross-icon {
          color: inherit;
          background: unset;

          svg {
            circle,
            line {
              stroke: currentColor;
            }
          }
        }

        span {
          color: inherit;
        }
      }
    }

    .form-group {
      .btn-toggle {
        .pr-btn {
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
}