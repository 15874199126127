/*
 * Skeleton element for loading transitions
 */

$selector: ".skeleton-element";

#{$selector}{
    $delay: 100;
    $duration: 100;

    background: #E9E7E5;
    height: 30px;
    border-radius: 1px;
    margin-top: 1rem;

    animation-name: skeletonElementAnimation;
    animation-duration: 2100ms;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.42, 0, .58, 1);

    @for $i from 0 to 8{
        $animation-delay: $delay * $i;

        &:nth-child(#{$i}){
            animation-delay: #{$animation-delay}ms;
        }
    }

    &:last-child{
        margin-bottom: 1rem;
    }
}


@keyframes skeletonElementAnimation {
    0%, 10%, 60%{
        opacity: 1;
    }
    30%, 50%{
        opacity: .4;
    }
}
