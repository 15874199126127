@import "../colors";
@import "../browsers";
@import "../common/typography";
@import "../common/utils";

$inverse_aspect_ratio: 3/4;
$image_width: (
                "xl": 16.5rem,
                "lg": 13.5rem,
                "md": 14.25rem,
                "sm": 20.25rem,
                "xs": 17rem
              );

@function CalculateMinHeight($width) {
  @return calc(#{$width} * #{$inverse_aspect_ratio});
}

.catalogsearch-result-index,
.catalog-category-view {
  .page-title {
    //text-align: center;

    h1 { font-size: 1.8rem; }

    span.product-count { font-size: 0.8125rem; }

    #products-count{
      text-transform: none;
      font-size: 1.5rem;
      color: #6c757d;
      display: inline-block;
    }
  }

  .tooltip-close {
    top: 0.5rem;
    right: 0.5rem;
  }

  @include media-breakpoint-down(md) {
    .row.mt-3.mb-3:first-of-type {
      margin-bottom: 0px !important;
    }
    .container.mt-4 {
      margin-top: 0px !important;
    }
  }
}

@mixin my-price {
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-family: $font-header-primary;
  font-weight: $font-weight-bold;
}

@mixin price-box{
  .price-box {
    position: relative;
    .club-savings, .regular-savings, .earn-credit {
      color: $grey-7;
      @extend .my-2;
      font-weight: $font-weight-light;
      font-size: $font-size-sm;
      margin-top: 0 !important;

      span { white-space: nowrap; }
    }

    .minimal-price, .price-from, .price-to {
      font-weight: $font-weight-bold;

      .starting-at {
        font-weight: $font-weight-light !important;
      }
    }

    .price-line {
      font-size: $font-size-orig * .875;
      position: relative;
      margin-left: 1px;
      margin-right: 1px;
      color: $body-color;
      &:first-of-type { // First line is larger
        font-size: $font-size-orig * .875;

        &.sale-price {
          font-weight: $font-weight-bold;
        }
      }

      &.club-price {
        font-size: $font-size-sm;
        font-weight: $font-weight-light !important;
        .price {
          font-weight: $font-weight-light !important;;
        }
      }

      &.has-sale {
        .price { color: $red-3 !important; }
      }

      &.regular-price, &.sale-price {
        font-weight: $font-weight-bold;
      }

      &.former-price {
        font-size: $font-size-orig * .875;

        .price {
          @extend .text-strikethrough;
          font-weight: $headings-font-weight;
        }
      }

      > span { // .price-label and .price
        position: relative;
        background-color: $white;
        white-space: nowrap;
        z-index: 1; // Overlay the dotted border

        &.price-label {
          display: inline-block;
        }
      }
    }

    .nobr {
      white-space:nowrap
    }
    .non-member-description {
      font-size: $font-size-sm;
      font-weight: $font-weight-light;

      .tooltip-title {
        position: relative;
        z-index: 99;
        color: $grey-7;
        line-height: 1.25rem;
        background-color: $white;
        text-decoration: underline;
        cursor: pointer;
      }
      .tooltip-title {
        cursor: pointer;
        span {
          text-decoration: underline;
        }
      }
    }
  }
}

#builder-category-description {
  @include media-breakpoint-down(lg) {
    margin-top: 5px;
  }
}

.category-title,
.breadcrumb {
  @include media-breakpoint-down(md) {
    padding-left: 0.5rem;
  }
}

.category-products {
  .toolbar-top,
  .products-grid {
    @include media-breakpoint-down(md) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}

// Product card
@mixin product-card {


  .item {
    a {
      &:hover {
        text-decoration: none;
      }
    }
    background-color: $white;
    padding: 0 .75rem;

    &.product.card {
      border: none;
      position: relative;
      height: 100%;
      display: flex;
      text-align: center;
      flex-direction: column;

      .member-only {
        margin: 0 auto 20px auto;

        @include media-breakpoint-down(md) {
          margin: -8px auto 8px;
        }
      }

      &.concise {
        .product-name-wrapper {
          @extend .text-center;
        }

        .member-only {
          @extend .justify-content-center;
        }

        .price-box-concise {
          text-align: center;
          font-size: 0.8125rem;

          .former-price, .regular-price {
            color: $grey-1;
            text-decoration: line-through;
          }

          .sale-price, .club-price {
            color: $red-3;
            font-weight: bold;
          }
        }
      }

      .outofstock-overlay {
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;

        > span {
          width: 60%;
          background-color: rgba(34, 25, 36, .7);
          border-radius: 3px;
          color: white;
          padding: 0.5rem 1rem;
          text-align: center;
        }
      }

      @include media-ie11() {
        display: block; // Flex layout broken in IE11
        flex-direction: inherit;
      }

      .product-image {
        display: block;
        margin: 0 -0.75rem;
        position: relative;

        .product-card-flag {
          position: absolute;
          top: 0;
          left: 0;
          bottom: auto;
          padding: 0.25rem 0.5rem;
          line-height: 1.2;
          background-color: #dd5638;
          color: white;
          font-size: 13px;
          //border-radius: 0 0.5rem 0.5rem 0;
          max-width: 80%;

          @include media-breakpoint-up(lg) {
            font-size: 12px;
          }

          .product-card-flag-content {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: block;
          }
        }

        img {
          width: 100%;
          height: auto;
        }
      }
      .product-name-wrapper {
        font-size: $font-size-base;
        line-height: 1.25em;

        @include media-breakpoint-up(md) {
          margin-bottom: 1.25rem !important;
        }

        .brand-name {
          color: $brown-7;
          font-size: $font-size-sm;
          margin-bottom: 0.125rem;
          display: block;
        }

        .product-name {
          color: $green;
          font-weight: $font-weight-bold;
        }
      }

      @include price-box;

      .and-up {
        font-family: $font-header-primary;
      }

      .non-member-pricing .regular-price .price,
      .member-pricing .club-price .price,
      .member-pricing.club-pricing-unavailable .regular-price .price {
        @include my-price;
      }

      .member-pricing.club-pricing-available .regular-price .price {
        font-weight: normal;
      }
    }

    .swatches {
      min-height: 20px;
      max-height: 20px;
      margin-bottom: 0.75rem;

      .configurable-swatch-list 
      {
        line-height: $font-size-orig;

        @include media-breakpoint-down(md) {
          font-size: $font-size-orig * .625;
        }

        .available-options {
          font-size: 0.75rem;
          color: $grey-4;
        }
      }
    }

    .reviews {
      min-height: 28px; /* Avoid reflowing content when PowerReviews loads */
      margin-top: 0.75rem;

      @include media-breakpoint-down(md) {
        margin-top: 0.5rem;
      }
    }

    .configurable-swatch-list {
      .col-auto {
        padding: 0 4px;
      }

      .swatch-link {
        display: block;
        overflow: hidden;
        border-radius: 16px;
        width: 16px;
        height: 16px;

        img {
          vertical-align: inherit;
          width: 16px;
          height: 16px;
        }
      }
    }

    .rating-box {
      & > a {
        &:hover {
          text-decoration: none;
        }

        display: flex;
        justify-content: center;
        justify-items: center;
        align-items: center;
        span {
          margin: auto 2px;
        }
        .star {
          @include stars();
          &.star-empty {
            position: relative;
            margin-right: 8px;
          }
          &.star-wrapper {
            left: 0;
            margin: 0;
          }
          &.star-filled {
            left: 0;
          }
        }
      }
    }
  }
}

.category-products {

  .items-per-page-selector {
    @extend .d-flex;
    @extend .justify-content-end;
    line-height: 2.25rem; // vertical center with Sort By button on lg

    .label {
      font-weight: bold;
      margin-right: 1rem;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        display: inline-block;

        &:first-of-type {
          a {
            border: none;
          }
        }

        &:last-of-type {
          a {
            padding-right: 8px; // Align with product cards
          }
        }

        a {
          font-size: 0.75rem;
          display: inline-block;
          padding: 0 1rem;
          color: $blue-5;
          border-left: 1px solid $grey-3;

          &.active {
            text-decoration: none;
            font-weight: bold;
            color: $blue-5;
          }

          &:hover, &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .products-grid {
    @extend .my-4;

    > div{
      margin-bottom: 1rem;
    }

    &__hidden{
      opacity: 0;
    }
  }

  // TODO: Re-add something to account for CLS?
  //.product-image{
  //  min-width: map_get($image_width, "sm");
  //  min-height: CalculateMinHeight(map_get($image_width, "sm"));
  //
  //  // iPhone 5/SE
  //  @media screen and (max-width: 360px) {
  //    min-width: map_get($image_width, "xs");
  //    min-height: CalculateMinHeight(map_get($image_width, "xs"));
  //  }
  //  @include media-breakpoint-up(md) {
  //    min-width: map_get($image_width, "md");
  //    min-height: CalculateMinHeight(map_get($image_width, "md"));
  //  }
  //
  //  @include media-breakpoint-up(lg) {
  //    min-width: map_get($image_width, "lg");
  //    min-height: CalculateMinHeight(map_get($image_width, "lg"));
  //  }
  //
  //  @include media-breakpoint-up(xl) {
  //    min-width: map_get($image_width, "xl");
  //    min-height: CalculateMinHeight(map_get($image_width, "xl"));
  //  }
  //
  //}
  
  @include product-card; 
}

@include media-breakpoint-down(sm) {
  .toolbar-bottom .toolbar  {
    .pager {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .sorter {
      display: none;
    }
  }
}

.toolbar {
  color: $blue-5;
  font-size: 0.75rem;

  .desktop-only {
    @extend .d-none;
    @extend .d-lg-inline-block;
  }

  .tablet-up-only {
    @extend .d-none;
    @extend .d-md-inline-block;
  }

  .custom-select {
    width: inherit;
  }

  label {
    font-weight: $font-weight-bold;
  }

  .pages {
    display: inline-flex;
    font-family: $font-header-primary;
    font-size: $font-size-orig;
    font-weight: $font-weight-xbold;

    span {
      $size: 2rem;

      &,
      & a{
        display: block;
        border-radius: $size - 0.2;
      }

      margin-left: 0.5rem;
      text-align: center;
      min-width: $size;
      line-height: $size - 0.1;
      background: #fff;
      transition: all 200ms linear;

      a{
        width: 100%;
        height: 100%;
        border: solid 1px $brown-5;
        padding: 0 0.63rem;
      }

      &:hover {
        background-color: #EDE9CC;
      }

      &.current {
        color: #fff;
        background-color: $green-5;
        border: solid 1px $green-5;
      }
    }
  }

  .dropdown {

    @include media-breakpoint-up(lg) {
      display: inline-block;

      &.bs-prototype-override {
        display: inline-block !important;
      }
    }

    .caret {
      position: absolute;
      right: 1rem;
    }
  }

  .btn {
    text-transform: none; // Uppercase doesn't fit on mobile
    background-color: white;
    border: none;
    box-shadow: inset 0 0 0 1px $blue-5-half;
    width: 100%;
    font-size: 0.8125rem;
    color: $blue-5;
    padding: 0.5rem 1.5rem;
    border-radius: 0.4rem;
    line-height: 0.8125rem;
    height: 2.125rem;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      width: auto;
      padding: 0.75rem;
      height: auto;
      font-size: 0.75rem;
    }

    &:hover, &:focus {
      box-shadow: inset 0 0 0 3px $blue-5;
    }

    &.dropdown-toggle {
      padding-right: 2.25rem; // accommodate the caret

      &:after {
        display: none; // Remove the bootstrap caret
      }
    }
  }
}

.category-brands {
  .brands-container {
    @extend .my-4;
    .brand-card {
      border-top: none;
      @extend .my-3;
    }

    .brand-name {
      font-size: 1rem;
      color: $green;
      line-height: 1.5;
      text-align: center;
      font-family: $font-primary;
    }
  }
}

.did-you-mean {
  a {
    text-decoration: underline;
  }
}