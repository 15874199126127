$alert-danger: #FF0000;

// Form Validation
.validation-advice, label.error, p.required {
  clear: both;
  color: $alert-danger;
  font-size: 0.75rem;
  font-weight: 400 !important;
  line-height: 1rem;
  margin: 0.5rem 0;
}

label.required em, span.required {
  color: $alert-danger;
}
label.required em {
  margin-right: 4px;
}
span.required {
  margin-left: 4px;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: middle;
}

// Create
.highlighted-radio {
  display: block;
  background-color: #fafaf9;
  border-top: 1px solid #e7e7e7;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #e3e3e3;
  border-radius: 3px;
  font-weight: normal;
  cursor: pointer;
  position: relative;
  padding: 10px 10px 10px 36px;
  text-align: left;
  font-size:14px;
  line-height: 20px;

  input {
    position: absolute;
    top: 16px;
    left: 30px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  span.sub-text {
    display: block;
    font-size: x-small;
  }
}

input, textarea, select, option, .form-control {
  font-size: 1rem;
}

// iOS doesn't crop fields areas when they are larger than the viewport width.
// The field looks correct but generates an horizontal scroll due to this.
@supports (-webkit-overflow-scrolling: touch) {
  body{
    &.onestepcheckout-index-index{
      input, select{
        &.form-control{
          overflow-x: hidden;
        }
      }
    }
  }
}

