@import "colors";

.form-search {
  .input-group-append {
    .btn {
      border-radius: 0 !important;
    }
  }
}

.search-autocomplete {
  z-index: 1000;
  width: 100%!important; // Override calculated value by the library to fill all the way to the button (included)

  ul.wyomind-elasticsearch {
    background-color: #fff;
    border: 1px solid #d5e4eb;
    border-bottom: 0;
    padding-left: 0;

    li{
      padding: 0;
      border-bottom: 1px solid #d5e4eb;

      .image-column{
        margin-right: 6px;
        .image {
          background-color: #fff;
        }
      }

      a {
        display: flex;
        padding: 6px;
        text-decoration: none;

        .info {
          width: calc(100% - 5rem);
          margin: auto 0;
        }

        .name{
          font-size: .875rem;
          line-height: 1;
        }

        .prices{
          font-size: .75rem;
          margin-top: 8px;
          margin-bottom: 10px;
        }

        .price-box .minimal-price-link {
          padding: 0;
        }

        .text-grey{
          color: $grey-4;
        }
      }

      &.selected:not(.all-results){
        a{
          text-decoration: none;
          background-color: #ecf3f6;
        }
      }

      &.title,
      &.title a{
        padding: 6px;
        text-transform: uppercase;
        color: $grey-5;
      }

      &.title,
      &.title a,
      &.all-results a{
        background-color: $cream-bg;
      }

      &.all-results a{
        color: $blue-4
      }

      .price-box {
        margin: 0;

        .old-price {
          display: inline-block;

          .price {
            text-decoration: line-through;
            color: $grey-1;
          }
        }
        .special-price {
          display: inline-block;
          .price {
            color: $red-3
          }
        }
      }

      .clearer {
        clear: both;
      }
    }
  }
}
