@import "./common/stars";
$selector: '.plp-menu';
$grayStar: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4wLjMsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iaWNvbiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojOEQ4QzgzO30NCgkuc3Qxe2ZpbGw6I0ZGRkZGRjt9DQo8L3N0eWxlPg0KPHBhdGggY2xhc3M9InN0MCIgZD0iTTQzMiw1MTJIODBjLTQ0LjE4LDAtODAtMzUuODItODAtODBWODBDMCwzNS44MiwzNS44MiwwLDgwLDBoMzUyYzQ0LjE4LDAsODAsMzUuODIsODAsODB2MzUyDQoJQzUxMiw0NzYuMTgsNDc2LjE4LDUxMiw0MzIsNTEyeiIvPg0KPHBvbHlnb24gY2xhc3M9InN0MSIgcG9pbnRzPSIyNTYsNzMuMzcgMzA5LjI5LDE4MS4zNSA0MjguNDUsMTk4LjY3IDM0Mi4yMywyODIuNzIgMzYyLjU4LDQwMS40IDI1NiwzNDUuMzYgMTQ5LjQyLDQwMS40IA0KCTE2OS43NywyODIuNzIgODMuNTUsMTk4LjY3IDIwMi43MSwxODEuMzUgIi8+DQo8L3N2Zz4NCg==");
$orangeStar: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4wLjMsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iaWNvbiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojREQ1NjM4O30NCgkuc3Qxe2ZpbGw6I0ZGRkZGRjt9DQo8L3N0eWxlPg0KPHBhdGggY2xhc3M9InN0MCIgZD0iTTQzMiw1MTJIODBjLTQ0LjE4LDAtODAtMzUuODItODAtODBWODBDMCwzNS44MiwzNS44MiwwLDgwLDBoMzUyYzQ0LjE4LDAsODAsMzUuODIsODAsODB2MzUyDQoJQzUxMiw0NzYuMTgsNDc2LjE4LDUxMiw0MzIsNTEyeiIvPg0KPHBvbHlnb24gY2xhc3M9InN0MSIgcG9pbnRzPSIyNTYsNzMuMzcgMzA5LjI5LDE4MS4zNSA0MjguNDUsMTk4LjY3IDM0Mi4yMywyODIuNzIgMzYyLjU4LDQwMS40IDI1NiwzNDUuMzYgMTQ5LjQyLDQwMS40IA0KCTE2OS43NywyODIuNzIgODMuNTUsMTk4LjY3IDIwMi43MSwxODEuMzUgIi8+DQo8L3N2Zz4NCg==");

#{$rootSelector} {
  #{$selector} {
    $gutter: 8px;
    $max-height-size: 200vh;
    $max-height-tf-on: cubic-bezier(0.5, 0, 1, 0);
    $max-height-tf-off: cubic-bezier(0, 1, 0, 1);
    $max-height-transition: max-height 600ms #{$max-height-tf-off};
    $menu-transform: left 200ms linear;

    background: $cream-bg;

    .form-check{
      display: flex;
      align-items: flex-start;

      @include ie-and-edge {
        line-height: 1;
      }

      @include firefox {
        line-height: 1;
      }

      .form-check-input {
        flex-grow: 0; // Safari and Safari Mobile fix
        flex-shrink: 0; // Safari and Safari Mobile fix
        margin-top: 0;
      }
    }

    &__list {
      margin-bottom: 0;
      border-bottom: solid 1px $grey-6;

      &__title {
        font-family: $font-header-primary;
        font-size: .9rem;
        text-transform: uppercase;
        color: $blue-4;
        font-weight: 900;
        user-select: none;
        display: flex;
        align-items: center;
        min-height: 2.25rem;

        &--collapser {
          cursor: pointer;
          justify-content: space-between;

          &:after {
            font-family: "Font Awesome 5 Free";
            -webkit-font-smoothing: antialiased;
            font-size: 1.5rem;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            content: '\f078';
            display: block;
            margin-left: .5rem;
          }

          &#{$selector}__list__title--active {
            &:after {
              transform: rotate(180deg);
            }
          }
        }
      }

      &__content {
        margin-left: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        overflow: hidden;

        #{$selector}__list__content {
          margin-left: 10px;
          margin-bottom: 0;
        }

        &__block {
          padding-left: 0;
          margin-bottom: 0;
          overflow: hidden;

          &:last-child {
            margin-bottom: $gutter;
          }
        }

        li {
          $spacing: .25rem;

          font-family: $font-primary;
          font-size: .85rem;
          font-weight: 400;
          color: $blue-4;
          line-height: 1.1rem;
          padding-top: $spacing;
          padding-bottom: $spacing;
          background-color: $cream-bg;
        }

        &__reviews {
          display: inline-flex;
          margin-top: .6rem;

          label {
            cursor: pointer;
            padding: 0 0.1em;
          }

          label:first-of-type {
            padding-left: 0;
          }

          .star {
            $size: 26px;

            pointer-events: none;
            background: $star_100;
            display: inline-block;
            width: $size;
            height: $size;
            margin-right: 3px;
          }

          input[type="radio"] {
            display: none;
          }

          input[type="radio"]:checked~label i.star {
            background: $star_0;
          }

          &:hover {
            input[type="radio"]~label i.star {
              background: $star_100;
            }

            input[type="radio"]:hover~label .star {
              background: $star_0;
            }
          }
        }

        &__toggle {
          padding: $gutter 0 ($gutter*2);
          display: block;
          color: $blue-4;
          text-decoration: underline;
          cursor: pointer;
          user-select: none;
          background-color: $cream-bg;

          &__on {
            display: block;
          }

          &__off {
            display: none;
          }

          .fas {
            margin-left: .3rem;
            font-size: 0.8em;
          }
        }

        &--on {
          >#{$selector}__list__content__toggle {
            #{$selector}__list__content__toggle {
              &__on {
                display: none;
              }

              &__off {
                display: block;
              }
            }
          }
        }
      }
    }

    &__in-stock {
      padding: #{$gutter} 0;

      label {
        font-size: .75rem;
      }
    }

    &__actions {
      display: none;
      background: $cream-bg;
      box-shadow: 0 1px 1px 0 rgba($black, .1);

      &--bottom{
        position: fixed;
        left: 0;
        bottom: 0;
        width: 75%;
        padding-top: 1rem;
        z-index: map-get($plpZIndexMap, 'basic');
        transition: $menu-transform;
        background: linear-gradient(to bottom, rgba(249,247,240,0) 0%, #{$cream-bg} 20%, #{$cream-bg} 100%);
      }

      &__clear {
        margin-right: .5rem;
      }
      .plp-filters__filter{
        &--tags{
          margin-bottom: 0!important;
          .btn{
            font-size: .65rem;
          }
          .plp-filters__clear{
            font-size: .8rem;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      padding-top: 1rem;
      padding-right: 2rem;
    }

    @include media-breakpoint-down(sm) {
      position: fixed;
      left: 0;
      top: 0;
      z-index: map-get($plpZIndexMap, 'menu');
      padding: 0 0 120px;
      height: 100vh;
      overflow: auto;
      transition: $menu-transform;
      box-shadow: 1px 0 1px 0 rgba($black, .05);

      &:not(#{$selector}--active) {
        &,
        // position: fixed inside position: fixed without transform
        // (due to Chromium fixed+transform bug)
        #{$selector}__actions--bottom{
          left: -100%;
        }
      }

      &__list {
        &--disabled{

          a,
          b {
            &,
            &:focus{
              text-decoration: none;
              user-select: none;
              cursor: default;
              color: rgba($blue-4, 0.4);
            }
          }
        }

        &__title,
        &__content li {
          padding-left: $gutter;
          padding-right: $gutter;
        }

        &__content {
          li {
            padding-top: 0;
            padding-bottom: 0;
            a,
            .form-check{
              display: block;
              padding-top: $gutter + 1.5;
              padding-bottom: $gutter + 1.5;
            }
          }
          &__toggle {
            padding-left: $gutter;
            padding-right: $gutter;
          }
        }
      }

      &__in-stock,
      &__actions {
        padding: $gutter;
      }

      &__actions {
        display: block;
      }
    }
  }
}