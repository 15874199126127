.breadcrumb {
  @extend .mb-0;
  background-color: transparent;
  padding: 0;

  .breadcrumb-item+.breadcrumb-item {
    padding-left: 0.75rem;
  }

  li {
    color: #A4997B;
    font-size: 0.75rem;

    a {
      color: #A4997B;
    }

    &.active {
      a {
        color: $blue-4;
      }
    }
  }
}