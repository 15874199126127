@import "colors";
@import "common/utils";
@import "~bootstrap/scss/variables";

$line-throw-shadow: 0 1px 1px 0 rgba($black,0.1);
$short-throw-shadow: 0px 10px 5px 0px rgba($black, .2);
$line-throw-shadow-inset: inset #{$line-throw-shadow};
$long-throw-shadow: 0 60px 30px -10px rgba(.3, .3, .3, .6);

$nav-gutter: 16px;
$nav-gutter-l3: 8px 16px;

$anim-duration: 250ms;
$anim-out-duration: 200ms;
$anim-timing-function: cubic-bezier(0.42, 0.0, 0.58, 1);

header.karls-header{

  @import "header/icons";

  .divider{
    background: $white;
    color: black;
    padding: 1rem;
    margin: 0;
    font-size: .75rem;
  }

  .fix {
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    position: fixed;
  }

  $nav-item-border: 1px dashed #d3cec1;
  $md-link-font-size: .69rem;

  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 100;

  #dy_Causes_Banner {
    order: 1;
  }

  .logo {
    height: 2.5rem;
    margin: 0 0 0.75rem 0.5rem;
  }

  // iPhone 5/SE
  @media screen and (max-width: 360px) {
    .logo {
      height: 2rem;
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: 0;
    
    .logo {
      height: auto;
      margin: 0;
    }
    .logo-main{
      min-height: 4rem;
      max-height: 4rem;
    }
    .divider{
      display: none;
    }

    #dy_Causes_Banner {
      order: 0;
    }
  }

  $nav-link-color: #{$blue-4};
  $nav-link-font-size: 0.75rem;
  $nav-link-text-transform: uppercase;
  $nav-link-letter-spacing: normal;
  $nav-link-font-weight: normal;

  &.reduced {
    margin-bottom: 1.5rem;
    box-shadow: $line-throw-shadow;

    #karls-nav-brand {
      padding: 1rem 0;
    }

    .logo {
      margin: 0;
      height: 2.5rem;

      @include media-breakpoint-up(md) {
        height: 4rem !important;
      }
    }
  }

  &,
  #karls-nav-brand,
  #karls-nav-categories,
  nav.nav-lvl-0,
  nav.nav-lvl-1,
  nav.nav-lvl-2{
    background: $white;
  }

  .nav-item a.nav-link {
    color: $blue-4;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: normal;

    @include media-breakpoint-down(sm){
      transition: background-color 100ms linear;
      &:not(.back){
        &:hover{
          background-color: #cee0b6;
        }
      }
    }
  }

  .nav-item.nav-lvl-0 a.nav-link {
    font-weight: 500;
    align-items: center;

    * {
      font-size: 0.75rem;
    }

    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }

  .nav-item.nav-lvl-1 a.nav-link {
    font-weight: 700;
    text-transform: none;
    align-items: center;
  }

  .nav-item.nav-lvl-2 a.nav-link {
    text-transform: none;
    font-size: 9pt;
    font-weight: normal;

    @include media-breakpoint-down(sm) {
      font-size: 10pt;
    }
  }

  #karls-links-md {
    display: none;
    padding: .4rem ($grid-gutter-width/2) 0;
    text-align: right;
    & > a {
      margin: 0 0 0 20px;
      padding: 0;
      display: inline-block;
      font-size: $md-link-font-size;
    }
    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  #karls-links {
    @include media-breakpoint-up(md) {
      display: none;
    }

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
    margin: 0;

    & > a {
      flex: 1;
      text-align: center;
      text-transform: uppercase;
      margin: 24px 0;
      padding: 12px 0;
      justify-content: center;
    }
    & > a:first-child {
      border-right: $nav-item-border;
    }

    &.top {
      display: none;
    }

    &.categories {
      display: flex;
    }
  }

  #karls-nav-brand {
    padding: 1rem 0;
    order: 0;
    font-size: $md-link-font-size;
    transition: opacity 200ms ease-out;

    @include media-breakpoint-down(sm) {
      box-shadow: $line-throw-shadow;

      > .container{
        > .row {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .karls-nav__cart {
        .shopping-cart {
          margin-right: 0.5rem;
          margin-left: 0;
        }
      }
    }
    @include media-breakpoint-up(md) {
      padding: 12px 0 8px 0;
    }
  }

  #karls-nav-categories{
    order: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    z-index: 100;

    &.open {
      z-index: 111;
      min-height: 100%;
    }

    .account {
      padding: $nav-gutter;
      font-size: .6875rem;

      &__brown{
        color: $brown-7;
      }

      &__icon{
        width: 16px;
      }

      &__green{
        color: $blue-4;
      }
    }

    .nav-explore{
      display: none;
    }

    @include media-breakpoint-down(sm) {
      width: calc(80%);

      .nav-explore{
        display: block;

        &__item{
          margin: 0;
        }

        &__link{
          display: block;
          transition: background-color 100ms linear;
          padding: $nav-gutter;
          font-weight: bold;
          text-transform: uppercase;
          border-top: 1px solid currentColor;
          font-size: .75rem;

          &,
          &:hover{
            color: $blue-4;
            text-decoration: none;
          }

          &:hover{
            background-color: #cee0b6;
          }
        }
      }

      a.nav-link {
        padding: $nav-gutter-l3;

        .menu-desc {
          text-transform: none;
          letter-spacing: 0.3px;
          color: $grey-1;
        }

        &.nav-link-toggler,
        &.nav-lvl-0-category-end {
          padding: $nav-gutter;
        }
      }
    }

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: static;
      box-shadow: $line-throw-shadow;

      .nav-item {
        a.nav-link {
          h5 {
            margin-bottom: 0;
          }

          * {
            color: $nav-link-color;
          }
        }

        &.account {
          display: none;
        }
      }
    }

    @include media-ie11() {
      $wrapper-size: 1200px;
      max-width: $wrapper-size;
      margin-left: auto;
      margin-right: auto;

      @include media-breakpoint-up(md) {
        .nav-item {
          justify-content: space-around;
          min-width: 0;
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: auto;

          @media only screen and (max-width: $wrapper-size){
            h5 {
              font-size: .6rem;
            }
          }
        }

        a.nav-link-toggler {
          i.more {
            background-size: auto 9px;
          }
        }
      }
    }
  }

  .nav-explore{
    display: none;

    &__item{
      margin: 1rem 0;
    }

    &__link{
      &--highlight{
        color: $red-3;
      }
    }

    @include media-breakpoint-up(md){
      display: block;
      text-align: center;
      background: $white;
      padding: .75rem 0;
      box-shadow: $line-throw-shadow;
      order: 2;
      font-size: .6875rem;

      &__item{
        display: inline-block;
        margin: 0 1rem;
      }
    }

    @include media-breakpoint-only(md){
      &__item{
        margin: 0 .5rem;
      }
    }
  }

  /* drawer helper */
  nav.nav-lvl-0,
  nav.nav-lvl-1,
  nav.nav-lvl-2 {
    width: 100%;

    .nav-item {
      // display: flex;

      a.nav-link {
        width: 100%;

        & > img {
          width: 20%;
          max-width: 62px;
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: 20%;
        }

        & > span{
          flex-grow: 5;
          flex-shrink: 5;
          flex-basis: 100%;
        }

        & > .more{
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: auto;
          width: 12px;
        }
      }

      a.nav-link-toggler {
        i.more {
          background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>') center center no-repeat;
        }

        &.expanded {
          i.more {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>') center center no-repeat;
          }
        }
      }

      @include media-breakpoint-up(md) {
        display: flex;

        a.nav-link {
          margin: 0 1rem;
          border-bottom: 2px solid transparent;
          transition: border-bottom-color $anim-out-duration linear;
          line-height: 0.75rem;

          &:not(.btn) {
            padding: 0;
          }
          &:hover {
            border-bottom: 2px solid $red-4;
          }
        }

        a.nav-link-toggler {
          i.more {
            $width: 16px;
            background-image: url(-mage-img('chevron-dn.svg'));
            background-size: ($width - 2) auto;
            width: $width;
            margin: 0 2px;
            transition: 50ms;
          }

          &[aria-expanded="true"] {
            i.more {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      &.drawer {
        &.md-open {
          display: flex;
          max-height: none;
        }
      }
    }
  }
  /* end drawer helper */

  nav.nav-lvl-0,
  nav.nav-lvl-1 {

    .nav-item {
      border-top: solid 1px currentColor;

      &:first-child {
        border: none;
      }
      &.shop-all {
        border-bottom: none;

        span.nav-link {
          padding: 8px !important;
        }

        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      a.nav-link {
        display: flex;
        text-transform: uppercase;

        &.shop-all-btn {
          text-transform: capitalize;
        }

        & > img {
          margin-right: 16px;
        }

        & > div {
          flex: 3;

          & > span {
            color: #8b887f;
            text-transform: none;
            line-height: 1.2rem;
            font-weight: 400;
            letter-spacing: 0;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      .nav-item {
        border: none !important;

        &:last-child {
          border: none;
        }
      }
    }

    &.drawer{
      @include media-breakpoint-down(sm){
        transition-duration: $anim-out-duration;
        transition-timing-function: $anim-timing-function;
        transition-property: opacity, transform, left;
        opacity: 0;
        left: 100%;
        transform: translateX(-100%);
        top: 32px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
        pointer-events: none;

        @include media-ie11{
          transition-property: all;
        }

        .nav-item.nav-lvl-0 {
          display: block;
        }

        &.open {
          opacity: 1;
          left: calc(40%);
          transition-duration: $anim-duration;
          transform: translateX(-50%);
          pointer-events: all;

          @include media-ie11{
            height: calc(100vh - #{$mobile-header-height-open-ie});
          }
        }
      }
    }
  }

  nav.nav-lvl-1 {
    z-index: 100;
    display: flex;
    flex-direction: column;
    order: 3;
    left: 0;
    width: 100%;

    .nav-category{
      order: -2;
    }

    .nav-link{
      &:not(.btn){
        padding: $nav-gutter;
      }
    }

    .back-wrapper {
      padding: 0 #{$nav-gutter};
      background-color: #DD5638;
      color: $white;

      a {
        color: inherit;
        background-image: url('/skin/frontend/karls/default/images/chevron-lt-white.svg');
      }
    }

    .nav-item {
      &.sub-nav-menu {
        order: -2;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        & > a.nav-link {
          text-transform: uppercase;
          padding: $nav-gutter;

          &.back {
            font-size: .6875rem;
            font-weight: 400;
            text-transform: none;
            padding-top: 20px;
            background-position: 0 55%;
          }

          & > h5 {
            margin: 0;
          }
        }
      }

      &.shop-all {
        order: -1;

        a.nav-link.shop-all-btn {
          text-align: center;
          display: block;
        }

        @include media-breakpoint-down(sm) {
          a.nav-link.shop-all-btn {
            &,
            &:hover{
              background: none !important;
              border: none !important;
              box-shadow: none !important;
              padding: $nav-gutter-l3;
              text-align: left;
            }
          }
        }

        @include media-breakpoint-down(sm) {
          a.nav-link.shop-all-btn {
            &,
            &:hover{
              padding-top: 12px !important;
              padding-bottom: 0px !important;
            }
          }
        }

        @include media-breakpoint-up(md) {
          order: 0;
          margin-bottom: 1.25rem;

          a.nav-link.shop-all-btn {
            color: $white;
            font-size: .75rem;
            text-transform: uppercase;
            letter-spacing: normal;
            font-weight: 500;
          }
        }
      }

      @include media-breakpoint-up(md) {
        a.nav-link {
          &:not(.btn) {
            margin-bottom: 8px;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      margin: 0;
      padding: 0;
      position: absolute;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: center;

      box-shadow: $long-throw-shadow;

      .nav-item.nav-lvl-1 {
        display: flex;
        flex-direction: column;
        margin: 12px 12px 0;

        &.sub-nav-menu {
          display: none;
        }

        &.shop-all {
          margin-bottom: 24px;
          width: 100%;
          order: 1;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  nav.nav-lvl-2 {
    .nav-item {
      a.nav-link {
        text-transform: none;
      }
    }

    @include media-breakpoint-up(md) {
      display: inline;
      .nav-item {
        width: auto;
        a.nav-link {
          text-transform: none;
          margin-bottom: 12px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .nav-item {
        a.nav-link {
          padding: $nav-gutter-l3 !important;
          line-height: $line-height-base * .75 !important;
        }
      }
    }
  }

  span.nav-lvl-1 {
    @include media-breakpoint-down(sm) {
      a.nav-link:not(.nav-lvl-2 .nav-link):not(.shop-all-btn) {
        padding-bottom: 2px !important;
      }
    }
  }

  span.nav-lvl-2 {
    &.shop-all {
      @include media-breakpoint-down(sm) {
        a.nav-link {
          text-transform: capitalize !important;
          margin-bottom: 16px;
          line-height: 1.125!important;
        }
      }
    }
  }


  /* animations */
  nav.nav-lvl-0 {

    @include media-breakpoint-down(sm) {
      .nav-lvl-1 {
        &.nav-item,
        a.nav-link {
          border-top: none !important;
        }
      }
    }

    &.drawer {
      position: fixed;
      overflow: hidden;

      @include media-breakpoint-down(sm) {
        position: relative;
      }
    }
  }

  nav.nav-lvl-1 {
    @include media-breakpoint-down(sm) {
      &.drawer {
        overflow: hidden;
        position: relative;
        display: none;
        transition-duration: $anim-out-duration;
        transition-timing-function: $anim-timing-function;
        transition-property: opacity, transform, left;
        left: 100%;
        opacity: 0;
        transform: translateX(-100%);
        width: calc(80%);
        box-shadow: none !important;

        &.open {
          transition-duration: $anim-duration;
          display: flex;
          left: calc(40%);
          opacity: 0;
          transform: translateX(-100%);

          .nav-link {
            padding-left: 24px !important;
          }
        }

        .nav-item{
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: auto;
        }

        &.fly {
          left: 50%;
          transform: translateX(-50%);
          opacity: 1;
        }
      }
    }

    @include media-breakpoint-up(md) {
      height: auto;

      &.drawer {
        display: none;
        max-height: 0;
        overflow: hidden;
        transition: max-height 150 $anim-timing-function;

        &.open {
          max-height: none;
          display: flex;
        }
      }
    }
  }
  /* end animations */

  // mobile menu toggler
  .menu-toggle {
    display: inline-block;
  }

  .navbar-toggler {
    border: none;
    padding: 0 0.5rem;

    &:focus {
      border: none;
      outline: none;
    }

    .icon {
      display: inline-block;
      width: 1.875rem;
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    transition: transform 500ms linear, box-shadow $anim-out-duration $anim-timing-function;
    width: 100%;
    &.scrolling {
      box-shadow: $short-throw-shadow;
    }
    &.is-hidden {
      transform: translateY(-100%);
    }
  }

  .contact-button {
    color: currentColor;
    display: block;
    font-size: 0.75rem;
    font-weight: 500;
    padding-left: 0;
    padding-right: 0;
    border: none;
    margin: 0 0.5rem 0.75rem 0;
    text-transform: none;
    text-decoration: underline;
  }

  .overlay-header {
    background-color: $primary;
    height: 48px;
    color: $white;
    margin-left: 0;
    margin-right: 0;

    .close {
      color: inherit;
      opacity: 1;
      text-shadow: none;
      float: none;
      font-size: 2rem;
      padding: .25rem .5rem;
      border: none;
      line-height: normal;
      &:focus {
        outline: none;
      }
    }

    .logo-container {
      padding: 0;
    }

    .shopping-cart {
      color: inherit;
      background-image: url('/skin/frontend/karls/default/images/header/cart-white.svg');
      vertical-align: middle;
      margin-right: 0.5rem;
      margin-left: 0;
      width: 1.5rem;
      height: 1.5rem;

      .in-the-cart {
        font-size: .69rem;
        left: .8rem;
        padding: 0 0.1rem;
        min-width: 1rem;
      }
    }

    .logo {
      margin: 0 0.5rem;
      height: 2rem;
    }
  }

  .sticky-header {
    z-index: 99;
    transition: transform $anim-out-duration $anim-timing-function;
    transform: translateY(-100%);

    @include media-breakpoint-up(md) {
      display: none;
    }

    &.visible {
      transition-duration: $anim-duration;
      transform: translateY(0);
    }

    .navbar-toggler {
      font-size: 1.5rem;
      color: #fff;
    }
  }
}

html {
  &,
  & body{
    height: 100%;
  }

  &.scroll-lock {
    &,
    body {
      overflow: hidden;
      -webkit-overflow-scrolling: unset!important;
      // iOS required
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: fixed;
      // END - iOS Required

      &.nav-menu-open {
        header.fixed {
          position: relative;
        }
      }
    }
  }
}

body {
  .nav-overlay{
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    background: #F9F7EF;
    opacity: 0;
    transition-duration: $anim-out-duration;
    transition-timing-function: $anim-timing-function;
    transition-property: opacity;

    @include media-breakpoint-up(md){
      display: none;
    }

    .shopping-cart {
      color: inherit;
      background-image: url('/skin/frontend/karls/default/images/header/cart-white.svg');
      vertical-align: middle;
    }
  }

  &.nav-menu-open {
    width: 100%;
    overflow: hidden;

    header.karls-header {
      #karls-nav-brand {
        opacity: 0;
        pointer-events: none;
      }

      nav.nav-lvl-1.open {
        z-index: 111;
      }
    }

    @include media-breakpoint-down(sm){
      .nav-overlay{
        transition-duration: $anim-duration;
        z-index: 100;
        opacity: 1;
        pointer-events: all;
      }

      .nav-wrapper {
        bottom: 0;
        z-index: 111;
        overflow: auto;
        pointer-events: all;
        background-color: rgba(0, 0, 0, 0.5);

        .nav-lvl-0.drawer,
        .nav-lvl-1.drawer,
        .nav-lvl-2.drawer {
          height: auto;
          top: 0;

          &.fly {
            width: 100%;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .nav-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      pointer-events: none;
    }
  }

  .karls-header{
    &-search{
      $size: 32px;
      $border-radius: 4px;
      display: block;

      @include media-breakpoint-up(md) {
        order: -1;
      }

      // Remove system's clear button IE
      ::-ms-clear {
        display: none;
        width : 0;
        height: 0;
      }
      ::-ms-reveal {
        display: none;
        width : 0;
        height: 0;
      }
      .form-search{
        position: relative;

        // Remove system's clear button Chrome
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
          display: none;
        }
        .search-autocomplete{
          left: 0!important;
        }
        &-clear{
          display: none;
          position: absolute;
          top: 50%;
          right: 2.25rem;
          transform: translateY(-50%);
          z-index: 4;
          width: $size * .6;
          height: $size * .6;
          cursor: pointer;

          &:before,
          &:after{
            content: '';
            display: block;
            width: $size * .6;
            height: 1px;
            background: $blue-4;
            position: absolute;
            top: 50%;
            left: 50%;
            transform-origin: center center;
          }

          &:before{
            transform: translate(-50%, -50%) rotate(45deg);
          }

          &:after{
            transform: translate(-50%, -50%) rotate(-45deg);
          }

        }

        .karls-header-search__arranger{
          display: flex;
          border-radius: $border-radius;

          .form-control,
          .btn{
            height: $size;
            border-color: $blue-4;
          }

          .form-control{
            flex-basis: auto;
            flex-grow: 1;
            flex-shrink: 1;
            padding-right: 2rem;
            font-size: 1rem;
            border-radius: $border-radius 0 0 $border-radius;
            border-right: none!important;
            -webkit-appearance: none;

            @include media-ie11() {
              line-height: 1;
            }

            &,
            &:focus {
              border-color: $blue-4;
              box-sizing: border-box;
            }

            &:focus{
              box-shadow: -.05rem 0 0.3rem 0.05rem $brown-5;
              & + .input-group-append .btn{
                box-shadow: .05rem 0 0.3rem 0.05rem $brown-5
              }
            }
          }

          .btn{
            width: 2rem;
            padding-left: 0;
            padding-right: 0;
            text-align: center;
            border-radius: 0 $border-radius $border-radius 0!important;
            border-width: 1px !important;
            border-left: none!important;
            line-height: 1;
            font-size: 1.125rem !important;
          }
        }

        &:not(.dirty){
          $text-accent: #8c8873;

          .form-search-clear{
            &:before,
            &:after{
              background: $brown-6;
            }
          }

          .karls-header-search__arranger{
            .form-control,
            .btn{
              &,
              &:hover,
              &:focus{
                background: #fff!important;
                border: solid 1px transparent;
                border-color: #828282 !important;// Only keep !important the color as per .btn-seconadary:focus issue
                color: $text-accent;
              }
            }

            .btn {
              color: $blue;
            }

            .form-control{
              &,
              &:focus,
              & + .input-group-append .btn{
                box-shadow: none!important;
              }

              &::placeholder{color: #828282;}
              &::-webkit-input-placeholder{color: #828282;}
              &::-moz-placeholder{color: #828282;}
              &:-ms-input-placeholder{color: #828282;}
            }
          }
        }
      }

      @include media-breakpoint-up(md){
        #search_mini_form{
          max-width: 303px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm){
  body{
    .karls-header{
      #karls-nav-brand{
        &.fix{
          .form-search{
            display: none;
          }
        }
      }
    }
  }

  .karls-header{
    #karls-nav-brand{
      &:not(.fix){
        position: relative;
      }
    }
  }
  
  .nav-menu-open.help-pop-opened {
    margin-top: 0px !important;
  }
}


.karls_above_header_carousel_block,
.karls_header_bellow_top_menu_mobile {
  .carousel-control-next,
  .carousel-control-prev {
    width: auto !important;
  }

  .carousel-inner {
    padding: 0 30px;
  }
}

.karls_above_header_carousel_block {
  background: $white;
}

.karls_header_bellow_top_menu_desktop {
  @include media-breakpoint-up(md) {
    #karls_header_3_columns_block_bellow_top_menu {
      padding-top: 1.5rem;
      text-align: center;
    }
  }
}
