$inverse_aspect_ratio: 3/4;
$image_width_aboutbox: (
                "xl": 22.999rem,
                "lg": 18.999rem,
                "md": 22.5rem,
                "sm": 22.458rem,
                "xs": 19rem
              );

@function CalculateMinHeightAboutbox($width) {
  @return calc(#{$width} * #{$inverse_aspect_ratio});
}

.bait-card-page {
  padding-bottom: 3rem;

  h1.page-title {
    font-size: 2em;
    line-height: 1.0;
    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
    }
  }

  .price-disclaimer {
    line-height: 1;
    @include media-breakpoint-down(sm) {
      padding: 0 1rem;
    }
  }

  .mtb-logo {
    width: auto;
    max-height: 100px;
    min-height: 6.25rem;
  }

  .card {
    border: 0;
    display: flex;
    flex-direction: column;
    height: 100%;

    .card-body {
      padding-bottom: 0;
      flex-grow: 1;
    }

    .product-name {
      color: $blue-4;
      margin-bottom: 0.5rem;

      .brand {
        font-size: 12px;
      }
      .name {
        font-size: 1.6rem;
        line-height: 1.2;
        font-family: $font-header-primary;
        text-transform: uppercase;
        font-weight: 600;
        @include media-breakpoint-up(md) {
          font-size: 1.25rem;
          min-height: 3rem; /* Allow for 2 lines of text */
        }
      }
    }

    .reviews {
      margin-bottom: 1rem;
    }

    .product-image {
      margin: 0rem -1.25rem 0.5rem; /* Offset the padding on the card body */
      min-width: map_get($image_width_aboutbox, "sm");
      min-height: CalculateMinHeightAboutbox(map_get($image_width_aboutbox, "sm"));

      @media screen and (max-width: 360px) {
        min-width: map_get($image_width_aboutbox, "xs");
        min-height: CalculateMinHeight(map_get($image_width_aboutbox, "xs"));
      }

      @include media-breakpoint-up(md) {
        min-width: map_get($image_width_aboutbox, "md");
        min-height: CalculateMinHeightAboutbox(map_get($image_width_aboutbox, "md"));
      }

      @include media-breakpoint-up(lg) {
        min-width: map_get($image_width_aboutbox, "lg");
        min-height: CalculateMinHeightAboutbox(map_get($image_width_aboutbox, "lg"));
      }

      @include media-breakpoint-up(xl) {
        min-width: map_get($image_width_aboutbox, "xl");
        min-height: CalculateMinHeightAboutbox(map_get($image_width_aboutbox, "xl"));
      }

      .video-wrapper { /* From https://css-tricks.com/fluid-width-video/ */
        position: relative;
        padding-bottom: 75%; /* 4:3 like images */
        height: 0;
        margin: 0 0.5rem;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .img-fluid {
        width: 100%;
      }
    }

    .price {
      font-weight: bold;
      margin-bottom: 0.5rem;
      font-size: 1.25rem;
      font-family: $font-header-primary;
      font-weight: 600;
    }

    .product-description {
      p {
        font-size: 13px;
        flex-grow: 1;
      }
    }

    .card-footer {
      background: none;
      border: 0;
      justify-content: flex-end;

      .reviews {
          min-height: 1.364rem;
      }

      .btn {
        white-space: inherit;
        font-family: $font-header-primary;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
}
