@import "../colors";
@import "../fonts";
@import "../common/utils";

.modal-alt {

  /*
  Many of the styles in here are repeats of the base .modal provided by Bootstrap, but
  overridden in models.scss. Once the outdated styling of modals is replaced with this,
  these styles can be trimmed down so it's not repeating the base styles.
   */

  .modal-dialog {
    @extend .modal-dialog-centered;
  }

  &.fade.in {
    opacity: 1 
  }

  .modal-header {
    background-color: white;
    color: $body-color;
    padding: 1rem;

    .modal-title, .close {
      color: inherit;
    }

    .modal-title {
      letter-spacing: inherit;

      &-long {
        font-size: 1rem;
        line-height: 1.4;
        @include media-breakpoint-up(md) {
          font-size: 1.25rem;
        }
      }
    }

    .close {
      margin: -1rem -1rem -1rem auto;
      padding: 1rem;
      opacity: 0.5;
    }
  }

  .modal-body {
    font-size: 0.8125rem;

    p {
      color: $body-color; // CONSIDER: Why is this green elsewhere?
    }

    ul {
      li {
        list-style-type: disc; // CONSIDER: Why?!?
      }
    }

    .form-group {
      &:last-child {
        @extend .mb-0;
      }
    }
  }

  &__add-to-next-box {
    &__shipping, &__billing {
      @extend .mt-2;
      font-size: 0.75rem;

      h6 {
        @extend .mb-1;
        font-size: 0.8125rem;
      }
    }

    .modal-footer {
      .btn {
        @extend .mb-0; // TODO: We need to figure out something better to deal with wrapping buttons
      }
    }
  }
}
