.gifts {
  .how-gifts-work {
    background-color: $green-3;
  }

  .btn {
    width: 180px;
  }

  .caption {
    @include media-breakpoint-up(lg) {
      min-height: 3.75rem;
    }
  }

  .printable {
    background-image: url(-mage-img('gifts/printable.svg'));
    height: 80px;
    background-repeat: no-repeat;
    z-index: 1;
    position: absolute;
    width: 100px;
    top: -25px;
  }

  .printable-flat {
    height: 68px;
    width: 136px;
    margin: -24px 0 -16px;
  }

  .reviews {
    .trustpilot {
      background: $white;
    }
  }

  .subscription-dropdown {
    height: 42px;
    margin: 16px auto;
    max-width: 250px;
    padding: 0;

    &.error {
      border: 1px solid #F05630;
      background-color: #FFF9F8;
    }
  }

  .subscription-dropdown .dropdown-toggle {
    display: block;
    text-decoration: none;
    line-height: 1.75rem;
    width: 100%;
    padding: 6px 6px 6px 0;
    max-width: 250px;
    text-align: left;

    &:after {
      margin-top: 0.75rem;
      float: right;
    }

    &.box_count .option-value {
      display: inline-block;
      width: 100%;
      line-height: 1rem;
      margin-top: 0;
      margin-right: -3.5rem;
    }

    &.disabled {
      cursor: not-allowed;
      padding-left: initial;
    }
  }

  .subscription-dropdown .dropdown-menu {
    width: #{(calc(100% + 2px))};
    max-width: 250px;
    left: -1px !important;
    padding-top: 0;
    padding-bottom: 0;

    a:focus, a:hover {
      background-color: $blue-2;
      text-decoration: none;
    }

    li {
      a {
        display: block;
        width: 100%;
        padding: 0.5rem;
      }
    }
  }

  .subscription-dropdown .option-value {
    padding-left: 8px;
  }

  .subscription-dropdown {
    .species, .box_count {
      a, .option-value {
        padding-left: 8px;
        font-weight: 600;
      }
    }
  }

  .subscription-dropdown .dropdown-menu li.active {
    display: none;
  }

  .btn-gift-plan {
    display: block;
    border-radius: 0.4rem;
    border: 3px solid #e9e9e9;
    background-color: white;
    padding: 0.75rem;
    width: 100%;

    &:active, &:focus {
      border-color: #abb98d;
      outline: none;

      .fa-angle-right {
        color: #abb98d;
      }
    }

    .price {
      font-weight: normal;
    }

    .savings {
      display: inline-block;
      font-weight: bold;
      text-transform: uppercase;
      padding-top: 0.25rem;
      @extend .text-success;
    }

    .months {
      font-weight: bold;
      font-size: 1rem;
    }

    .media {
      .media-body {
        text-align: left;
      }
    }

    .fa-angle-right {
      float: right;
      margin-top: 1.2rem;
      font-size: 2rem;
      color: #999999;
    }
  }

  .tab-content {
    background-color: white;
    padding: 1rem;

    .box-image-wrapper {
      position: relative;

      .badge {
        position: absolute;
        top: 0.5rem;
        right: 0;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    .box-title {
      h2 {
        .box-title-prefix {
          font-size: 1rem;
          color: $body-color;
        }
      }
    }

    .feature-bullets {
      li {
        list-style-type: disc;
      }
    }
  }

  #sizeTabs {
    &.nav-tabs {
      border-bottom: 3px solid #abb98d;
      align-items: center;

      .nav-item {
        margin-bottom: -3px;
      }

      .nav-link {
        border-width: 3px;

        .title {
          text-decoration: underline;
          text-transform: uppercase;
        }

        .subtitle {
          font-size: 0.75rem;
          color: #999999;
        }

        &.active {
          border-color: #abb98d #abb98d white;
          background-color: white;

          .title {
            text-decoration: none;
          }
        }
      }
    }
  }
}