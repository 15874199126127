// Bootstrap overrides
$blue:    #33534b !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dd5536 !default;
$orange:  #DD5638 !default;
$green:   #34534B !default;
$yellow:  #EED634 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
$cream:   #CBC07D !default;
$brown:   #5F311F !default;

$primary: $green;
$secondary: $orange;

$body-bg:                         #F9F7EF;
$body-color:                      #3B3A3E;
$success: #28a745 !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:      Gotham SSm, sans-serif !default;
$font-family-base:            $font-family-sans-serif !default;
$font-size-base:              0.875rem !default; // ~14px in most browsers
$font-size-orig:              1rem;

$font-weight-xbold:           900;

$line-height:                 1.125rem !default;

$h1-font-size:                2.85rem !default;
$h2-font-size:                2rem !default;
$h3-font-size:                1.6875rem !default;
$h4-font-size:                1.125rem !default;
$h5-font-size:                1.0625rem !default;
$h6-font-size:                0.9375rem !default;

$paragraph-margin-bottom:     0.5rem;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
      sm: 540px,
      md: 752px,
      lg: 960px,
      xl: 1152px
) !default;

$grid-gutter-width:           16px !default;

// Modals

$modal-md:                         660px !default;

// Forms

$input-focus-border-color:   #EDE9CC !default;
$input-btn-focus-box-shadow:  0 0 0 0.2rem #EEE9CE;
$input-border-color:         #EEE9CE !default;
$input-plaintext-color:      #908B86 !default;
