$selector: '.karls-club-decision';

#{$selector} {
  @extend .card;
  margin: 1rem 0;
  padding: 1.5rem 1.5rem 0.5rem;

  .karls-benefits {
    @include media-breakpoint-up(lg) {
      margin-top: 4rem;
    }
  }

  &__inner {
    .calculating {
      @extend .container;
      text-align: center;
      margin-bottom: 1rem;
      font-size: 0.75rem;
      font-weight: bold;
      white-space: nowrap;
      font-family: Gotham SSm, Helvetica, Arial, sans-serif;

      @include media-breakpoint-up(md) {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }

      #{$selector}__karls-head {
        margin-right: 0.5rem;
        width: 40px;

        animation: shake 1s;
        animation-iteration-count: infinite;
      }

      @keyframes shake {
        0% { transform: translate(1px, 1px) rotate(0deg); }
        10% { transform: translate(-1px, -2px) rotate(-1deg); }
        20% { transform: translate(-3px, 0px) rotate(1deg); }
        30% { transform: translate(3px, 2px) rotate(0deg); }
        40% { transform: translate(1px, -1px) rotate(1deg); }
        50% { transform: translate(-1px, 2px) rotate(-1deg); }
        60% { transform: translate(-3px, 1px) rotate(0deg); }
        70% { transform: translate(3px, 1px) rotate(-1deg); }
        80% { transform: translate(-1px, -1px) rotate(1deg); }
        90% { transform: translate(1px, 2px) rotate(0deg); }
        100% { transform: translate(1px, -2px) rotate(-1deg); }
      }
    }
  }

  &__loader {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &__heading {
    @extend .mb-3;
    font-size: 1.25rem;
    line-height: 1.25;
    color: $blue-4;
    font-weight: bold;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    em {
      color: $orange;
      font-style: normal;
    }
  }

  &__subheading {
    @extend .mb-3;
    font-size: 0.875rem;
    line-height: 1.25;
    color: $blue-4;
    font-weight: bold;
  }

  &__footnote {
    @extend .mt-2;
    font-size: 0.750rem;
    color: $grey-1;
  }

  &__option-picker {
    @include media-breakpoint-up(md) {
      padding: 0.5rem;
    }

    label {
      color: $blue-4;
      position: relative;
      padding-left: 3rem;
      margin-bottom: 0;
      font-size: 0.750rem;
      line-height: 1.5;

      .heading {
        font-weight: bold;
        font-size: 1rem;
      }

      //font-weight: bold;
      a.learn-more {
        font-weight: normal;
        color: $orange;
        text-decoration: underline;
      }

      .club-price {
        display: inline-block;
        margin-top: 0.5rem;
        font-size: 0.875rem;
        font-weight: bold;
        line-height: 1.25;

        .free-shipping {
          font-size: 0.75rem;
          white-space: nowrap;
          color: $green;
        }

        .store-credit {
          font-weight: normal;
          font-size: 0.75rem;
        }
      }

      .regular-price {
        display: inline-block;
        margin-top: 0.25rem;
        font-size: 0.875rem;
        font-weight: bold;
      }
    }

    input[type="radio"] {
      position: absolute;
      top: 6px;
      left: 0;
      bottom: 0;
      height: 32px;
      width: 32px;
      border: 2px solid $blue-4;
      padding: 0;
      background-color: white;
      border-radius: 0;

      &:after {
        content: 'Select';
        position: relative;
        top: 27px;
        font-size: 12px;
        left: -5px;
        white-space: nowrap;
      }

      @supports not ((-ms-ime-align:auto) or (-ms-high-contrast: none)) {
        /* Non-IE styles here */
        -webkit-appearance: none;

        &:checked:before {
          content: " ";
          margin: auto;
          position: absolute;
          top: 0px;
          left: 0;
          right: 0;
          bottom: 4px;
          border-left: 6px $orange solid;
          border-bottom: 6px $orange solid;
          transform: rotate(310deg);
          width: 26px;
          height: 16px;
          display: block;
        }
      }
    }
  }

  .club-price {
    color: $orange;
  }

  .regular-price {
    color: $blue-4;
  }
}