.elite-lander {
  .mobile-hero {
    background: url(-mage-img('subscription/elite/mobile-hero.png')) no-repeat top -95px center;
    background-size: 800px;
    min-height: 160px;
  }

  .elite-hero {
    @include media-breakpoint-up(xl) {
      background: url(-mage-img('subscription/elite/desktop-hero.png')) no-repeat right top -10px;
      background-size: 765px;
      min-height: 375px;
    }

    @include media-breakpoint-only(lg) {
      background: url(-mage-img('subscription/elite/desktop-hero.png')) no-repeat right top;
      background-size: 625px;
      min-height: 375px;
    }

    @include media-breakpoint-only(md) {
      background: url(-mage-img('subscription/elite/desktop-hero.png')) no-repeat right top 5px;
      background-size: 410px;
      min-height: 350px;
    }

    background: none;
  }
  .swipe {
    &::before {
      content: "";
      background: url(-mage-img('arrow-draw.svg')) no-repeat center bottom;
      width: 30px;
      height: 30px;
      display: inline-block;
      transform:scaleX(-1);        /* W3C compliant browsers */
      margin-bottom: -5px;
    }
    &::after {
      content: "";
      background: url(-mage-img('arrow-draw.svg')) no-repeat center bottom;
      width: 30px;
      height: 30px;
      display: inline-block;
      margin-bottom: -5px;
    }
  }

  .card-wrapper {
    visibility: hidden;
    @include media-breakpoint-up(md) {
      visibility: visible;
    }

    hr {
      border-width: 4px;
    }
    .elite {
      hr {
        border-color: $brown-3;
      }
    }

    .species {
      min-height: 40px;
    }
  }
}