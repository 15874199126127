.tooltip-wrapper {
  display: inline;
  position: relative;

  [data-toggle="popover"] {
    color: $white;
    background-color: #40B599;
    border-radius: 45px;
    text-decoration: none;
    display: inline-block;
    margin-left: 4px;

    > * {
      font-size: 12px;
      padding: 4px 6px;
      text-align: center;
      margin: 4px;
    }
  }

  .tip-container {
    display: inline-block;
    font-size: 16px;
    line-height: 1.2;
  }

  .tip.light {
    color: #40b599;
    opacity: 0.6;
    background-color: $white;
  }

  .tip-container .tooltip {
    position: absolute !important;
    z-index: 5;
    min-width: 200px;
  }

  .tip-container .popover {
    width: 300px;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);

    .popover-header {
      font-size: 1.125rem;
    }

    .popover-body {
      font-size: 14px;
    }
  }

  .tip-container .popover-title {
    font-size: 14px;
  }

  .tip-container .tooltip-inner {
    padding: 16px;
    background-color: $white;
    border: 1px solid $grey-1;
  }
}
