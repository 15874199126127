@import "../colors";

@mixin my-price {
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-family: $font-header-primary;
  font-weight: 700;
}

// TODO: consolidate with product card styles in category.scss?
.product-card {
  background: $white;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-content: center;
  align-items: center;

  & > a {
    &:hover {
      text-decoration: none;
    }

    @include media-ie11() {
      max-width: 100%;
      flex: 0 0 auto;
    }
  }

  .product-image {
    display: block;

    img {
      width: 100%;
      height: auto;
    }
  }

  .product-name {
    min-height: 42px;
    max-height: 42px;
    overflow: hidden;
    text-align: center;
  }

  .and-up {
    font-family: $font-header-primary;
  }

  .non-member-pricing .regular-price .price,
  .member-pricing .club-price .price,
  .member-pricing.club-pricing-unavailable .regular-price .price {
    @include my-price;
  }

  .member-pricing.club-pricing-available .regular-price .price {
    font-weight: normal;
  }

  .product-pricing {
    min-height: 69px;
    max-height: 42px;
    overflow: hidden;
    text-align: center;
  }

  // TODO: consolidate this with ratings in category.scss?
  .product-card-reviews {
    min-height: 38px;
    text-align: center;

    .no-rating {
      font-size: 0.75rem;
    }

    .rating-box {
      width: inherit;
      & > a {
        &:hover {
          text-decoration: none;
        }
        display: flex;
        justify-content: center;
        justify-items: center;
        align-items: center;
        .star {
          @include stars();
          &.star-empty {
            position: relative;
            margin-right: 8px;
          }
          &.star-wrapper {
            left: 0;
            margin: 0;
          }
          &.star-filled {
            left: 0;
          }
        }
      }
    }
  }
}
