.wizard-steps {
  .current {
    .number {
      background-color: $red-3;
    }

    p {
      @extend .text-green;
    }
  }

  :not(current) {
    .number {
      background-color: $green-3;
      color: $cream-3;
    }

    p {
      color: $green-3;
    }
  }

  p {
    vertical-align: middle;
  }

  .number {
    border-radius: 45px;
    display: inline-block;
    font-weight: 600;
    height: 22px;
    width: 22px;
    line-height: 1.2;

    @include media-breakpoint-up(md) {
      height: 37px;
      width: 37px;
      font-size: 1.75rem;
      line-height: 1.4;
    }
  }
}