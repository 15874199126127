$selector: ".accordion";

#{$selector}{
    color: $green;
    text-align: left;

    &__toggler,
    &__content{
        background: white;
        font-weight: $font-weight-normal;
        padding: 1rem 1.5rem;
        box-shadow: 0 1px 1px 0 rgba($black, 0.1);
        position: relative;
    }

    &__toggler{
        cursor: pointer;
        margin-top: 1rem;
        padding-right: 3rem;
        font-weight: bold;

        &:after{
            $size: 10px;
            content: "";
            position: absolute;
            top: 50%;
            right: 20px;
            display: block;
            
            width: $size;
            height: $size;
            
            border-right: solid 1px $green;
            border-bottom: solid 1px $green;

            transform: translateY(-70%) rotate(45deg);
            transform-origin: center center;

        }

        &--active{
            & + #{$selector}__content{
                display: block;
            }

            &:after{
                transform: translateY(-60%) rotate(225deg);
            }
        }
    }

    &__content{
        display: none;
        padding: 0 1.5rem 1rem;
    }
}