.slick-initialized { visibility: visible !important; }
.slick-slide {
  &:focus, a {
    outline: none;
  }
}

.slick-dots li.slick-active button:before {
  color: $blue-4;
}

.slick-dots li button:before {
  font-size: 1.5rem;
  color: $brown-2;
}

.slick-arrow {
  background-image:  url(-mage-img('slick/arrow-slider.svg')) !important;
  background-repeat: no-repeat !important;
  background-color: $green !important;
  background-position: 17px 10px !important;
  border-radius: 30px;
  height: 40px !important;
  width: 40px !important;
  z-index: 10;

  &:hover {
    background-color: $blue-3 !important;
  }

  @include media-breakpoint-down(sm) {
    display: none !important;
  }

  &.slick-prev {
    transform: scaleX(-1) translateX(-35%) translateY(0%)!important;
  }
  &.slick-next {
    transform: translateX(-35%) translateY(0%)!important;
  }
  &:before,&:after {
    content: '' !important;
  }
}