@import "../common/typography";
@import "../colors";

body.catalog-product-view {
  background-color: white;

  .product-view-wrapper {
    @extend .px-0;
  }

  .product-content-wrapper {
    background-color: $body-bg;
    border-top: 1px dashed $grey-1;

    .section:first-of-type {
      border-top: none;
    }
  }

  .product-breadcrumbs {
    @extend .container;
    @extend .my-3;

    .breadcrumb-item {

      // Mobile, only show the parent category crumb as a button
      @include media-breakpoint-down(sm) {
        &:not(.parent-category) {
          display: none;
        }

        &.parent-category {
          display: block;
          width: 100%;
          position: relative;

          &:before {
            @include fa-solid();
            content: '\f053';
            position: absolute;
            left: 0.75rem;
            font-size: 1rem;
            // Vertically center within the button
            display: flex !important;
            align-items: center;
            height: 100%;
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 0.5rem 2rem;
            border: 2px solid $blue-4;
            border-radius: 2rem;
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: $font-weight-bold;
            color: $blue-4;
            &:hover { text-decoration: none; }
          }
        }

        &:before {
          display: none;
        }

        + .breadcrumb-item {
          padding-left: 0;
        }
      }

    }
  }

  .product-view {
    h1, h2, h3 {
      font-family: $font-header-primary;
      color: $blue-4;
    }
    h1 {
      text-align: left;
      font-size: 1.7rem;
    }
    h2 {
      text-align: left;
      font-size: 2rem;
      font-weight: 700;
    }
    h3 {
      text-align: center;
    }
    p {
      color: $grey-1;
    }
    .non-member-description {
      position: relative;
      text-transform: none;
      font-weight: $font-weight-normal;
      font-size: 0.750rem;

      .non-member-description-headline {
        font-weight: $font-weight-bold;
        color: $orange;
        font-size: 0.875rem;
      }

      .tooltip-title {
        cursor: pointer;
        span {
          text-decoration: underline;
        }
      }
    }
    .ratings {
      display: flex;
      @include media-breakpoint-down(md) {
        .rating-box {
          margin-left: 0;
        }
      }
    }

    .rating-links {
      margin-left: 16px;
    }

    .price-info {
      margin: 0.5rem 0;

      .alert-bundle-club-price p {
        color: #dd5638 !important;
      }

      .new-price-line {
        overflow: auto;

        .price-inline {
          float: left;
          margin-right: 5px;

          &.former-price {
            span {
              color: $blue-4;
              &.price {
                @extend .text-strikethrough;
              }
            }
          }

          &.has-sale {
            .price { color: $red-3 !important; }
          }

          &.regular-price {
            span {
              color: $blue-4;
            }
          }
          
          .price {
            font-size: 1.3rem;
          }
        }

        .sale-price .price {
          font-weight: $font-weight-bold;
          color: $red-3;
        }
      }

      .price-line {
        position: relative;
        overflow: hidden;
        color: $blue-4;
        font-size: $font-size-base;
        line-height: 1;

        .price {
          font-size: 1.4rem;
          font-family: $blue-4;
        }

        .price-label {
          //text-transform: uppercase;
          line-height: 1.5rem;
          color: $grey-1;
        }

        &:first-of-type, .price-as-configured {
          font-weight: $font-weight-bold;
          color: $red-3;

          .price {
            font-size: 1.3rem;
          }
          .price-label {
            color: $red-3;
          }
        }
      }

      .affirm-as-low-as {
        @extend .mb-3;
        min-height: 1.375rem;

        .affirm-modal-trigger {
          white-space: nowrap;
        }
      }

      .earn-credit {
        text-align: left;
        font-style: italic;
        color: $red-3;
        font-weight: bold;
        span { white-space: nowrap; }
      }

      .store-credit {
        //         text-upper text-green font-weight-bold mb-3
        @extend .text-upper;
        @extend .text-green;
        @extend .mb-3;
        text-transform: none;
        font-weight: $font-weight-bold;
        font-size: ($font-size-orig * .75);
      }

      .club-price {
        .price {
          font-weight: normal;
        }

        .price,
        .price-label {
          font-size: 1.2rem;
          color: $blue-4;
        }
      }

      .club-savings {
        @extend .text-upper;
        font-weight: bold;
        margin-top: 0.5rem;
      }

      .minimal-price, .price-from, .price-to {
        display: inline-block;
        color: #5f5f5e;
        margin-bottom: 0.5rem;
      }
    }

    @include media-breakpoint-up(md) {
      .product-view-intro-wrapper {
        position: relative;
        align-content: flex-start;
      }

      .product-view-media-wrapper {
        position: absolute;
      }
    }

    @include media-breakpoint-down(md) {
      .product-view-media-wrapper {
        order: 1;
      }

      .product-stats {
        order: 2;
      }
    }
  }

  .product-card-flag {
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    padding: 0.25rem 0.5rem;
    line-height: 1.2;
    background-color: #dd5638;
    color: white;
    font-size: 13px;
    max-width: 80%;
    z-index: 1; // Pull it in front of the product image
    margin-left: 8px;

    @include media-breakpoint-up(lg) {
      font-size: 14px;
    }

    .product-card-flag-content {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
  }

  .product-stats {
    position: relative;

    .zoom-area {
      height: 0;
      top: 0;
      left: $grid-gutter-width / 2;
      right: $grid-gutter-width / 2;
      padding-top: calc(100% - #{$grid-gutter-width}); // Responsive square hack
      padding-left: calc(100% - #{$grid-gutter-width}); // Responsive square hack
      position: absolute;
      display: none;
    }

    .member-only {
      margin-bottom: 1rem;
      font-size: 1rem;
  
      &:before {
        width: 24px;
        height: 27px;
      }
    }
  }

  .rating-links, .no-rating {
    a {
      color: $grey-1;
      font-size: .75rem;
    }
  }

  .product-review-summary {
    @extend .mb-2;
    min-height: 1.7em;
    display: inline-flex;
    vertical-align: middle;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    .p-w-r {
      margin: 0 !important;
      font-size: 13px;
      
      @include media-breakpoint-down(md) {
        font-size: 10px;
        
        .pr-star-v4 {
          width: 16px;
          height: 16px;
        }
      }

      .pr-snippet-review-count {
        font-size: inherit !important;
        margin-right: 0.5em;

        @include media-breakpoint-down(md) {
          margin-right: 0.2em;
        }
      }

      .pr-review-snippet-container {
        margin-bottom: 0 !important;
      }
    }

    .pr-snippet-write-review-link {
      display: none !important;
    }
  }

  #pr-questionsnippet {
    @extend .mb-2;
    min-height: 1.7em;
    display: inline-flex;
    vertical-align: middle;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    // IE10+ only - moves the question snippet 1px up to align link underlines
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-top: -1px;
    }
    
    .p-w-r {
      margin: 0 !important;
      font-size: 13px;

      @include media-breakpoint-down(md) {
        font-size: 10px;
      }

      .pr-snippet-qa .pr-snippet-qa-answer-link {
        font-size: inherit;
      }
    }

    .pr-snippet-read-and-write {
      display: inline-block;
      vertical-align: middle;
    }

    .pr-snippet-write-review-link {
      display: none;
    }

    abbr.pr-qa-large,
    .pr-ask-question-link {
      display: none;
    }

    .pr-qa-snippet-container {
      display: inline-block;
      vertical-align: middle;

      &.pr-no-qa {
        display: none !important;
      }
    }

    .pr-snippet-qa {
      display: flex;

      &::before {
        content: '|';
        margin-right: 0.7em;

        @include media-breakpoint-down(md) {
          margin-right: 0.5em;
        }
      }

      .pr-snippet-qa-metrics {
        margin-right: 0;
      }
    }
  }

  .section {
    border-top: 1px dashed $grey-1;
    padding: 2rem 0;
    h2, h3 {
      margin: 0 auto 1.5rem;
    }

    h3.product-name {
      font-size: $h4-font-size;
      text-align: left;
      margin-bottom: .5rem;

      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
  }

  .long-description {
    p {
      @extend .mb-3;
      line-height: 1.375rem;
    }

    ul {
      li {
        list-style-type: disc;
      }
    }
  }

  .karls-pledge {
    font-weight: 600;
    line-height: 1rem;
  }

  .product-reviews {
    a.btn.btn-fat {
      padding: 1.25rem 1.75rem;
      color: $white !important;
      font-weight: 600;
      border-radius: 2.5rem;
      font-size: 1rem;
      max-width: unset;
    }

    h3 {
      text-align: left;
      font-size: $h4-font-size;

      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    .summary {
      @extend .ml-2;
      font-size: 0.75rem;

      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    .reviews {
      h5 {
        text-transform: unset;
      }

      .customer-review {
        margin: 1rem 0;
        padding: 8px;

        .rating-box {
          @include media-breakpoint-down(md) {
            margin-left: 0;
            margin-right: 0;

            .stars {
              margin-bottom: 0.5rem!important;
            }
          }
        }

        .review-meta {
          @extend .mb-3;

          p {
            margin: 0;
            padding: 0;
          }
        }

        .review-title {
          margin-top: 1.5rem;
        }
      }
    }

    .histogram {
      .star-num, .star-count {
        line-height: 21px;
        white-space: nowrap;
      }

      .star-bar-wrapper {
        background-color: #DCD7C9;
        width: 100%;
        height: 21px;
        border-radius: 42px;
        padding: 0;
      }

      .star-bar-fill {
        background-color: $blue-4;
        height: 100%;
        border-radius: 42px;
      }
    }
  }

  .rating-box {
    @include media-breakpoint-down(md) {
      margin-left: auto;
      margin-right: auto;
      width: 200px;
    }

    .stars {
      @extend .mb-3;
      display: flex;
      justify-content: left;
      justify-items: left;
      
      &:hover {
        text-decoration: none;
      }

      span {
        margin: auto 2px;
      }

      .star {
        @include stars();
      }
    }
  }

  .and-up {
    font-family: $font-header-primary;
  }

  .btn-write-review {
    width: 100%;

    @include media-breakpoint-down(md) {
      margin-top: 1rem;
    }

    @include media-breakpoint-only(md) {
      width: 300px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }

  .video-container {
    @include media-breakpoint-only(md){
      float: none;
      width: 100%;
      min-height: initial;
      display: inline-block;

      embed,
      object,
      iframe {
        position: initial;
        width: 100%;
        height: 316px;
      }
    }

    @include media-breakpoint-up(lg) {
      position: relative;
      float: right;
      width: 83%;
      min-height: 408px;

     embed,object,iframe {
       position: absolute;
       right: 0;
       width: 100%;
       height: 388px;
     }
    }
  }

  .video-button-wrapper {
    margin-bottom: 2rem;

    .btn-play-video {
      @include media-breakpoint-down(sm) {
        margin-left: auto;
        margin-right: auto;
      }

      .row {
        margin: -.375rem -1.2rem;

        .col:nth-child(1) {
          padding: .375rem 1.2rem;

          @include ie-and-edge() {
            flex: 0 0 auto;
            padding: 0.6rem 1.2rem;
            width: auto;
          }
        }

        .col:nth-child(2) {

          @include ie-and-edge() {
            padding: 0.375rem 0.75rem 0.375rem 0;
            flex: 0 0 auto;
            width: auto;
          }
          .fas {
            font-size: 1.5rem;
            padding-top: 0.15rem;
            padding-right: 0.15rem;
          }
        }
      }
    }
  }

  .product-specs {
    margin-bottom: 1rem;

    h3 {
      @extend .mb-2;
      text-align: left;
      font-size: $h4-font-size;

      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
    .specs {
      @extend .mb-2;
      color: $grey-1;
    }
  }

  .magnify-image {
    width: 41px;
    height: 41px;
    border: 1px solid #d2d4d4;
    border-radius: 22px;
    background: $white url(-mage-img('mag-glass-small.svg')) center no-repeat;
    position: absolute;
    top: 13px;
    left: 25px;
    z-index: 5;
    &:hover {
      background-color: #d2d4d4;
      transition: background-color 0.5s ease;
      border: 1px solid #d2d4d4;
    }
    &:focus {
      outline: none;
    }
  }

  .tooltip-close {
    top: 0.5rem;
    right: 0.5rem;
  }
}

.add-to-cart {
  .add-btn-wrapper {
    margin-bottom: 1.5rem;
    .col-auto {
      margin: 0 auto;
    }

    .or {
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      margin: 0.25rem 0;
    }
  }

  .pre-order-announce {
    font-family: $font-primary;
    font-weight: 600;
    color: $green-7 !important;
    font-size: 1.25rem;
    @extend .mb-0;
  }
}

.karls-benefits {
  .props {
    @extend .mb-3;
    ul {
      &.fa-ul {
        margin-left: 1.6em; // Better left alignment with header
        @extend .mb-0;

        > li {
          margin-bottom: 2px;
        }
      }
      font-size: $font-size-sm;
    }

    @include media-ie11() {
      .media-body {
        flex: 0 0 auto;
      }
    }
  }

  .shipping-returns {
    @extend .pt-3;
    border-top: 1px dashed $grey-1;
  }
}
