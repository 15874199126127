@import "utils";

body {
  background: $cream-bg;

  &.modal-open,
  &.nav-menu-open,
  &.contact-sidebar-open {
    .BeaconFabButtonFrame {
      display: none !important;
    }
    .BeaconContainer {
      display: none !important;
    }
  }

  &:not([class*="customer-"]):not([class*="sales-order-"]) {
    .BeaconFabButtonFrame {
      display: none;
    }
  }

  &.help-pop-opened:not(.modal-open):not(.contact-sidebar-open):not(.nav-menu-open) {
    .BeaconFabButtonFrame {
      display: block !important;
    }
  }


  p {
    color: $blue-4;
  }
}

// Remove the primary container gutters on tablets and smaller devices
body > .container {
  @include media-breakpoint-down(md) {
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }
}

*.price {
  font-weight: 700;
}

a.link {
  text-decoration: underline;
}

.truncated_full_value {
  display: none;
}

.d-block, .d-xs-block, .d-sm-block, .d-md-block, .d-lg-block, .d-xl-block {
  &.hidden {
    display: none !important;
  }
}

// Copied from https://codepen.io/scottzirkel/pen/yNxNME
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: $body-color;
  text-align: center;
  height: 1.5em;
  &:before {
    content: '';
    background: #818078;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: $body-color;
    font-weight: $font-weight-bold;
    padding: 0 .5em;
    line-height: 1.5em;
    background-color: white;
    font-family: $font-family-sans-serif;
  }
}