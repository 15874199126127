@import "../colors";
@import "../flex";

#added-to-cart {
  .modal-dialog {
    @include media-breakpoint-up(sm) {
      max-width: 420px;
    }

    .modal-header {
      h5.modal-title {
        color: $green-6;
        font-family: Gotham SSm, Helvetica, Arial, sans-serif;
        font-size: $font-size-orig;
        text-transform: unset;
        font-weight: $font-weight-bold;
      }
    }
  }

  .savings {
    color: $red-3;
  }

  .value-prop {
    @extend %flex-column-center;
  }

  .first-year {
    background-color: $cream-3;
    padding: 6px 8px;
  }

  .flex-column-center {
    @extend %flex-column-center;
  }

  .a {
    .regular-price {
      color: $red-3;
    }
  }
  
  .c {
    .regular-price {
      font-weight: normal;
      text-decoration: line-through;
      color: $grey-1;
      margin-right: 0.5rem;
      display: inline-block;
    }
    .club-price {
      color: $blue-4;
      display: inline-block;
    }
  }

  .d {
    .club-price {
      color: $blue-4;
    }
    .regular-price {
      color: $grey-1;
    }
  }

  .add-to-cart-lower {
    border-top: 1px solid #e9ecef;
  }
}
