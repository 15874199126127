@import "account";

.account-create {
  h1, h2 {
    margin-bottom: 0;
  }
  h2 {
    font-size: 0.5rem;
  }

  .members {
    border-top: 1px solid #DCD7C9;
  }
}