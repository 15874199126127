@import "./common/stars";
$selector: '.plp-cards';

#{$rootSelector} {
  #{$selector} {
    margin-top: 0 !important;
    padding-top: 0!important;
    border-top: none;

    &__card{
      &__image {
        &.sold-out {
          position: relative;

          &:before {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, 0);
            width: 75%;
            z-index: 1;
            height: 100%;
            background: url(-mage-img('products/sold-out.png')) no-repeat center center;
            background-size: contain;
          }
        }
      }

      .card{
        padding-bottom: 36px;

        .plp-reviews{
          position: absolute;
          left: 0;
          right: 0;
          bottom: 10px;
        }
      }

      .star {
        $size: 20px;

        border-radius: 3px;
        padding: 3px;
        pointer-events: none;
        background: $star_100;
        display: inline-block;
        width: $size;
        height: $size;
        margin-right: 2px;

        &__fill0 {
          background: $star_0;
        }

        &__fill25 {
          background: $star_25;
        }

        &__fill50 {
          background: $star_50;
        }

        &__fill75 {
          background: $star_75;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      justify-content: center;
    }
  }
}