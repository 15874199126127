/*
 * This handles the element to display an overlay loader icon
 */

$selector: '.loading-lock';

#{$selector}{
    $indexes: (
        overlay: 98,
        loadingIcon: 99
    );

    $loadingSize: 64px;

    position: relative;
    overflow: hidden;

    // :before = overlay color
    // :after = loading indicator

    &:before,
    &:after{
        content: '';
        display: block;
    }

    &:before{
        position: absolute;
        left: 0;
        top: 0;
        z-index: map_get($indexes, 'overlay');
        width: 100%;
        height: 100%;
        background: rgba($white, .6);
    }

    &:after{
        width: $loadingSize;
        height: $loadingSize;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: map_get($indexes, 'loadingIcon');
        transform: translate(-50%, -50%);
        background: url(-mage-img('loaders/green-lg.gif'));
        background-size: contain;
    }
}