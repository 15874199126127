@import "../colors";

$mobile-header-height-open: 148px;
$mobile-header-height-open-ie: 96px;

@function -mage-img($relative-path) {
  $svg: str-index($relative-path, '.svg');
  @if $svg {
    @return '/skin/frontend/karls/default/images/' + $relative-path;
  }
  @return 'https://res.cloudinary.com/mysterytacklebox/image/fetch/q_auto,f_auto/https://karls.qa.catchco.io/skin/frontend/karls/default/images/' + $relative-path;
}

@function -mage-img-base($relative-path) {
  $svg: str-index($relative-path, '.svg');
  @if $svg {
    @return '/skin/frontend/karls/default/images/' + $relative-path;
  }
  @return 'https://res.cloudinary.com/mysterytacklebox/image/fetch/q_auto,f_auto/https://karls.qa.catchco.io/skin/frontend/base/default/images/' + $relative-path;
}

.arrow-rt {
  background: url(-mage-img('chevron-rt.svg')) center no-repeat;
  margin-left: 16px;
}

.arrow-lt {
  background: url(-mage-img('chevron-lt.svg')) center no-repeat;
}

.arrow-up {
  background: url(-mage-img('chevron-up.svg')) center/contain no-repeat;
  width: 16px;
  margin: 0 2px;
}

.arrow-dn {
  background: url(-mage-img('chevron-dn.svg')) center/contain no-repeat;
  width: 16px;
  margin: 0 2px;
}

.back {
  @extend .arrow, .arrow-lt, .pl-3;
  background-position: left;
}

.fwd {
  @extend .arrow, .arrow-rt, .pr-3;
  background-position: right;
}

@mixin mirror() {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

/** UTIL FOR SHOWING THE BREAKPOINT ON THE PAGE
just drop this html on your page somewhere

<div id="media-query">
    <span></span>
</div>

**/
#media-query {

  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99999;
  color: #ddffdd;
  background-color: #0a995c;
  border: 2px solid #55aa55;

  & > span:after {
    font-size: 20px;
    margin: 0 40px;

    @include media-breakpoint-up(lg) {
      content: 'Extra Large';
    }

    @include media-breakpoint-only(lg) {
      content: 'Large';
    }

    @include media-breakpoint-only(md) {
      content: 'Medium';
    }

    @include media-breakpoint-only(sm) {
      content: 'Small';
    }

    @include media-breakpoint-only(xs) {
      content: 'Extra Small';
    }
  }
}


@mixin link($color-map) {
  $merged: map-merge($default-link-colors, $color-map);
  color: map-get($merged, 'main');
  &:visited {
    color: map-get($merged, 'visited');
  }
  &:hover {
    color: map-get($merged, 'hover');
  }
  &:active {
    color: map-get($merged, 'active');
  }
}

.todo {
  @extend .p-2;
  color: white;
  background-color: darkred;

  &:before {
    content: '// TODO: ';
    font-weight: bold;
  }
}

@mixin table-default {
  width: 100%;
  td, th {
    border: 1px solid #d6cdc1;
    padding: 4px;
    vertical-align: top;
  }
  th {
    white-space: nowrap;
  }
  thead {
    tr {
      background-color: $white;
    }
  }
  tbody {
    tr {
      &.even, &:nth-child(even) {
        background-color: $white;
      }
      &.odd, &:nth-child(odd) {
        background-color: $cream-bg;
      }
    }
  }
  thead, tbody, tfoot {
    td, th {
      &.a-right {
        text-align: right;
      }
      &.a-center {
        text-align: center;
      }
      &.a-left {
        text-align: left;
      }
    }
  }
}

// Comment from karls.js:
// Bootstrap and Prototype don't play nice, in the sense that
// prototype is a really wacky horrible library. It'll
// hard-code CSS to hide an element when a hide() event
// is fired. See http://stackoverflow.com/q/19139063
// To overcome this with dropdowns that are both
// toggle style and hover style, we'll add a CSS
// class which has "display: block !important"
.bs-prototype-override {
  display: block !important;
}