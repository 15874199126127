$selector: ".list-items";

#{$selector}{
  $border: solid 1px $blue-4;
  border-bottom: #{$border};
  margin: 0;
  padding: 0;
  list-style: none;

  &__item{
    border-top: #{$border};
    position: relative;
    padding: 1rem .2rem;
    color: $blue-4;
    font-size: $font-size-base;
    line-height: 1;
    user-select: none;

    small{
      color: $brown-7;
    }

    p{
      margin: .25rem 0!important;
    }

    &--arrow{
      padding-right: 2rem;
      cursor: pointer;

      &:after{
        $size: .7rem;
        content: "";
        display: block;
        width: $size;
        height: $size;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: rotate(45deg) translateY(-50%);
        border-top: #{$border};
        border-right: #{$border};
        pointer-events: all;
      }
    }
  }
}