.filters-sidebar {

  .filter-state {
    .currently {

      ul {
        margin: 0;
        padding: 0;

        li {
          display: inline-flex;
          align-items: center;
          margin-bottom: 0.75rem;

          .badge {
            padding: 0.5rem 0.75rem;
            background-color: white;
            border: 1px solid #34534b;
            color: #34534b;
            font-size: 0.750rem;
            margin-right: 1rem;
            font-weight: normal;

            .btn-remove {
              margin-left: 0.5rem;
            }
          }

          &.clear-all {
            @include media-breakpoint-down(md) {
              display: block; // Break to next line
            }

            a {
              font-size: 0.750rem;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .filters-heading { // Desktop only
    h2 {
      margin-bottom: 0;
      line-height: 2.75rem; // TODO: This should align it with the toolbar content
      font-size: 1.25rem;
      font-weight: bold;
      font-family: $font-header-primary;
      padding: 0 0.5rem;
    }
  }

  #narrow-by-list {
    // heading
    dt {
      border-top: 1px solid #eee9ce;

      .filter-name {
        font-family: $font-header-primary;
        font-size: 1rem;
        line-height: 2.75rem;
        margin: 0;
        padding: 0 0.875rem;

        @include media-breakpoint-up(lg) {
          padding: 0 0.5rem 0 0;
        }

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:is([data-toggle="collapse"]) {
          cursor: pointer;

          &:after {
            font-family: "Font Awesome 5 Free";
            content: "\f068";
            float: right;
            color: grey;
            font-size: 1rem;
            line-height: 2.75rem;
          }
        }

        &.collapsed:after {
          content: "\f067";
        }
      }
    }

    dd {
      margin-bottom: 1rem;

      ol {
        margin: 0;
        padding: 0;

        li {
          margin-left: 0.9375rem;
          margin-right: 2rem;

          font-family: $font-family-sans-serif;
          font-size: 0.8125rem;
          font-weight: $font-weight-normal;
          color: $blue-4;
          line-height: 1.6;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;

          @include media-breakpoint-down(sm) {
            background-color: #fff;
            span.count {
              color: #8c8c8c;
            }
          }
        }

        li.cat-filter-lvl-1 {
          margin-left: 1rem;
        }

        &#topBrands {
          li {
            margin-left: 1rem;
          }
        }

        @include media-breakpoint-up(lg) {
          li.cat-filter-lvl-1 {
            margin-left: 0;
          }

          &#topBrands {
            li {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) { // Filter modal only shown on phone and tablet breakpoints

    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;

    $anim-timing-function: cubic-bezier(0.42, 0.0, 0.58, 1);
    $in-duration: 250ms;
    $out-duration: 200ms;

    &.visible {
      z-index: 9999;
      pointer-events: all;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#333, .5);
      z-index: 0;
      transition: opacity $out-duration $anim-timing-function;
      opacity: 0;
    }

    #filters {
      position: absolute;
      top: 0;
      right: 0;
      width: 80%;
      height: 100%;
      background-color: $white;
      transition: transform $out-duration $anim-timing-function, opacity $out-duration $anim-timing-function;
      transform: translateX(100%);
      display: flex;
      flex-flow: column;
      max-width: 360px;
      opacity: 0;

      .root {
        padding: 1rem;
        flex: 1;
        overflow: auto;
      }

      .filters-header, .filters-footer {
        margin: 1rem 0.875rem;
      }

      .filters-header {
        h2 { margin-bottom: 0; }
      }

      .filter-state {
        margin: 0 0.875rem;
      }

      .block-layered-nav {
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: scroll;
      }
    }

    &.open {
      .overlay {
        transition-duration: $in-duration;
        opacity: 1;
      }

      #filters {
        transition-duration: $in-duration;
        transform: translateX(0);
        opacity: 1;
      }
    }
  } // End sm breakpoint
}

// Applied filters on desktop
#applied-filters-placeholder {
  .currently {

    ul {
      margin: 0 0 0.5rem;
      padding: 0;

      li {
        display: inline-flex;
        align-items: center;
        margin-bottom: 0.75rem;

        .badge {
          padding: 0.5rem 0.75rem;
          background-color: white;
          border: 1px solid #34534b;
          color: #34534b;
          font-size: 0.750rem;
          margin-right: 0.5rem;
          font-weight: normal;

          .btn-remove {
            margin-left: 0.5rem;
          }
        }

        &.clear-all {
          a {
            font-size: 0.750rem;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

#filter-brand_filter {
  .search {
    margin: 0 1rem 0.5rem 0.5rem;

    @include media-breakpoint-down(md) {
      margin-left: 1rem;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;
      margin-right: 0.5rem;
    }

    .form-control {
      font-size: 0.875rem;
    }
  }

  #allBrands {
    li {
      @include media-breakpoint-up(lg) {
        margin-left: 0rem;
      }

      &:first-child {
        border-top: none;
      }
    }
  }

  .show-all, .no-results {
    margin-left: 1rem;
    padding: 0.25rem 0;
    font-size: .8125rem;

    @include media-breakpoint-up(lg) {
      margin-left: 0rem;
    }
  }

  .show-all a {
    text-decoration: underline;
  }

  .highlight {
    font-weight: bold;
  }
}