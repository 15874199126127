.karls-club {
  .bg-gradient-primary {
    background: $primary linear-gradient(180deg, #526c64, $primary) repeat-x;
  }
  
  .club-badge {
    max-width: 62px;
  }

  .loading-app {
    text-align: center;

    &::after {
      content: '';
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border-width: 2px;
      border-style: solid;
      border-color: transparent;
      border-top-color: $primary;
      animation-name: loading-spin;
      animation-timing-function: linear;
      animation-duration: 0.75s;
      animation-iteration-count: infinite;
      display: inline-block;

      @keyframes loading-spin {
        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  .manage-membership {
    .list-group-item.active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    + .collapse {
      .list-group {
        .list-group-item-action:not(:last-child) {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
  }

  .undo-link {
    text-decoration: underline !important;
    cursor: pointer;
  }

  .alert {
    p {
      color: inherit;
    }
  }

  .alert-danger,
  .alert-success {
    h6 {
      text-transform: none;
      color: inherit;
    }
  }

  .membership-benefits {
    .benefit-icon {
      font-size: 2rem;
    }

    ol li {
      list-style-type: inherit;
    }
  }
}

.karls-club-modal {
  @include media-breakpoint-down(sm) {
    margin: 0;
    min-height: 100%;

    .modal-content {
      min-height: 100vh;
      // Fixes iOS weird footer glitch
      min-height: -webkit-fill-available;
      border-radius: 0;
    }

    .react-scroll-locky,
    .modal-inner-content-wrapper {
      flex: 1 1 auto;
      display: flex;
      flex-flow: column;
    }
  }

  .logo.cancel-flow {
    max-width: 133px;
  }

  .btn {
    max-width: none;
  }

  .back-modal-button {
    color: #34534b;
    padding-left: 0;
    box-shadow: none !important;
    text-decoration: none !important;

    svg {
      margin-right: 0.3rem;
      margin-top: -0.1rem;
    }
  }

  .list-group-item {
    border-radius: 4px;
  }

  &.loading {
    .modal-content {
      &::before {
        content: '';
        opacity: 0.5;
        background-color: #000;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 9;

        @include media-breakpoint-up(sm) {
          border-radius: .3rem;
        }
      }

      &::after {
        content: '';
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border-width: 2px;
        border-style: solid;
        border-color: transparent;
        border-top-color: #fff;
        animation-name: loading-spin;
        animation-timing-function: linear;
        animation-duration: 0.75s;
        animation-iteration-count: infinite;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -12px 0 0 -12px;
        z-index: 10;

        @keyframes loading-spin {
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }

  .modal-view-plans {
    .current-plan-badge {
      position: absolute;
      bottom: -1px;
      right: -1px;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
      padding: .4em .4em 0.25em; // Better vertical alignment
    }

    .change-plan-terms p {
      font-size: 12px;
    }
  }

  .modal-keep-membership {
    .payment-image {
      width: 30px;
    }
  }
}

.karls-club,
.karls-club-modal {
  .bg-gradient-primary {
    background: $primary linear-gradient(180deg, #526c64, $primary) repeat-x;
  }
}