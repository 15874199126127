.pro-lander {
  .pro-hero {
    background: url(-mage-img('subscription/pro/mobile-hero.jpg')) no-repeat;
    background-position: center bottom;
    position: relative;
    height: 635px;
    width: 100%;
    margin: 0;
    background-size: cover;

    @include media-breakpoint-up(lg) {
      background: url(-mage-img("subscription/pro/desktop-hero.jpg")) no-repeat;
      background-position: center bottom;
      height: 605px;
    }

    .free-shipping.absolute {
      position: absolute;
      right: #{(calc(50% - 140px))};
      bottom: 180px;
    }
  }

  .elite-option {
    img {
      max-width: 70%;
      border-radius: 0;
      margin-top: 10px;
    }

    h3, a {
      font-size: 1rem;
      font-family: $font-header-secondary;
      line-height: 1.3;
      text-transform: uppercase;
      letter-spacing: .04em;
    }

    h3 {
      color: #eeebd6 !important;
      font-size: $font-size-orig;
    }

    a {
      font-size: $font-size-base;
    }
  }

  .species-option {
    img {
      max-width: 45%;
    }

    @include media-breakpoint-up(md) {
      p {
        min-height: 2rem;
      }
    }
  }

  .pro-species {
    background-color: $blue-4;
  }
}