@mixin stars() {
  position: relative;
  width: 64px;
  height: 12px;
  margin: auto 2px;
  background-repeat: repeat-x;
  background-color: transparent;
  background-size: contain;

  .amount {
    line-height: 1;
  }

  &.star-wrapper {
    position: absolute;
    left: 0;
    margin: 0;
    width: 65px;
  }
  &.star-filled {
    background-image: url(-mage-img('products/star-filled.svg'));
    position: inherit;
    max-width: 64px;
    margin: 0;
    min-width: 20%;
    left: 0;
  }
  &.star-empty {
    background-image: url(-mage-img('products/star-empty.svg'));
  }
  &.star-lg {
    width: 192px;
    height: 36px;
    max-width: 192px;

    > .star-wrapper {
      width: 194px;
      height: 36px;
    }
  }
}