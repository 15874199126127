#shopping-cart-totals-table, #checkout-review-table {
  width: 100%;
  font-size: .75rem;

  td {
    @extend .py-1;
    vertical-align: top;
  }

  tr {
    td:first-child {
      padding-right: 1em;
    }
  }

  td.a-right {
    @extend .text-right;
  }

  tr.msrp-saving-total {
    .price {
      color: $red-3;
      font-weight: 700;
    }
  }

  tr.order-total {
    td {
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
    }
  }

  .price {
    font-weight: normal;
  }

  .free-shipping {
    font-weight: $font-weight-bold;
    color: $green-7;
    font-size: $font-size-base;
    text-transform: uppercase;
  }

  strong > .price {
    font-weight: bold;
  }
}

body{
  &.checkout-cart-index {

    .btn-proceed-checkout {
      border-radius: 2rem;
      @include media-breakpoint-down(sm) {
        padding: 0.75rem 1.5rem;
        font-size: 1rem;
      }
    }

    .cart-estimated-total {
      text-align: center;

      .estimated-total {
        font-weight: bold;
        font-size: $font-size-orig;
      }
    }

    .checkout-method-buttons {
      @extend .mt-3;
      @extend .mt-md-0;
      @extend .text-md-right;

      .btn {
        @extend .btn-fill;

        @include media-breakpoint-up(md) {
          width: auto; // Not full-width on md and up
        }

        &.btn-proceed-checkout {
          &:before {
            @include fa-solid();
            content: "\f023";
            vertical-align: -0.1rem;
            font-size: 1.25rem;
            padding-right: 0.25rem;

            @include media-breakpoint-up(md) {
              vertical-align: -0.15rem;
            }
          }
        }
      }
    }

    .coupon-code-card {
      @extend .mt-3;

      a[data-toggle="collapse"] {
        font-size: 0.75rem;
      }

      .form-control.error {
        border: 1px solid $red-4;
        background-color: $red-1;
      }

      .coupon-code-body {
        @extend .mt-2;
      }

      .applied {
        @extend .mt-3;
        font-size: 0.75rem;

        .active-code {
          font-weight: $font-weight-bold;

          .fas {
            color: $green-7;
            display: inline-block;
            margin-right: 0.25rem;
          }
        }

        a.remove {
          text-decoration: none !important;
          text-transform: uppercase;
        }
      }
    }

    .page-title {
      @extend .mt-3;
      text-align: center;

      h1 {
        font-size: 2.85rem;
      }
    }

    .cart-empty {
      @extend .my-4;
      text-align: center;
    }

    h1 {
      font-size: 1.5rem;
      @extend .mb-0;

      .cart-quantity {
        color: $grey-1;
        font-size: $font-size-orig;
        font-weight: $font-weight-normal;
        white-space: nowrap;
        text-transform: none;
      }
    }

    @mixin container-fix {
      max-width: none;
      margin-left: auto;
      margin-right: auto;
    }

    .container {
      @include media-breakpoint-down(sm) {
        @include container-fix;
      }
    }

    img.cart-thumb {
      width: 59px;
      height: auto;
      @include media-breakpoint-up(sm) {
        width: 100%;
      }
    }

    .continue-shopping {
      text-align: center;
    }

    #crosssell-products-list {

      .crosssell-product {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px dotted $grey-3;

        .img-fluid {
          @extend .mb-2;
          width: 100%;
        }

        &:last-of-type {
          border: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }

        @include media-breakpoint-up(md) {
          border: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }

        .product-name-wrapper {
          @extend .mt-1;
          @extend .mb-3;
          font-size: $font-size-base;
          line-height: 1.25em;

          a {
            text-decoration: none;
          }

          .brand-name {
            color: $brown-7;
            font-size: $font-size-sm;
          }

          .product-name {

          }
        }

        .pricing {
          @extend .mb-2;
        }

        .price-box {
          .club-savings, .regular-savings, .earn-credit {
            //text-align: center;
            //font-style: italic;
            color: $red-3;
            @extend .my-2;
            font-weight: bold;
            font-size: 0.8125rem; // 13px default for sm and larger

            span {
              white-space: nowrap;
            }
          }

          .price-line {
            position: relative;
            overflow: hidden;

            font-size: 0.8125rem; // 13px default for sm and larger

            &:first-of-type { // First line is larger
              font-size: $font-size-base; // 14px for sm and larger

              &.sale-price {
                color: $red-3;
                font-weight: $font-weight-bold;

                .price {
                  font-weight: $font-weight-bold;
                }
              }
            }

            &.club-price {
              color: $red-3;
              font-weight: $font-weight-bold;
            }

            &.regular-price, &.sale-price {
              .price {
                font-weight: $font-weight-normal;
              }
            }

            &.former-price {
              color: $grey-1;
              font-size: 0.750rem; // 12px

              .price {
                @extend .text-strikethrough;
                font-weight: $font-weight-normal;
              }
            }

            > span { // .price-label and .price
              position: relative;
              background-color: $white;
              white-space: nowrap;
              z-index: 1; // Overlay the dotted border

              &.price-label {
                float: left;
              }

              &.price {
                float: right;
              }
            }

            &:after {
              content: '';
              border-bottom: 1px dotted;
              border-color: #ccc;
              position: absolute;
              left: 0;
              bottom: 25%;
              width: 100%;
            }
          }
        }

        .price-box-alt {

          .club-savings, .regular-savings, .earn-credit {
            text-align: center;
            font-style: italic;
            color: $red-3;
            @extend .my-2;
            font-weight: bold;

            span {
              white-space: nowrap;
            }
          }

          .price-line {
            color: $green;

            &:first-of-type {
              font-size: $font-size-base;
              font-weight: $font-weight-bold;
              color: $red-3;

              .price-label {
                > span {
                  font-weight: $font-weight-normal;
                  white-space: nowrap;

                  &:before {
                    content: '| ';
                  }
                }
              }
            }

            .price-label {
              font-size: $font-size-sm;
            }

            &.club-price {
              color: $red-3;
            }

            &.regular-price {
              //.price { font-weight: $font-weight-normal; }
            }

            &.former-price {
              color: $grey-1;
              font-size: $font-size-sm;

              .price {
                @extend .text-strikethrough;
                font-weight: $font-weight-normal;
              }
            }
          }
        }

      }
    }

    .store-credit-earned-summary {
      font-size: 0.75rem;
    }

    .returning-customer {
      .card-header {
        cursor: pointer;

        &[data-toggle="collapse"]:after {
          @include fa-solid();
          content: "\f078";
          float: right;
          color: $body-color;
        }

        &.collapsed:after {
          /* symbol for "collapsed" panels */
          @include fa-solid();
          content: "\f077";
        }
      }

      .card-body {
        font-size: 0.75rem;
      }
    }

    .add-to-next-box {
      &__wrapper {
        background-color: lightyellow;

        .card-body {
          font-size: 0.75rem;
        }
      }

      &__not-available-note {
        @extend .mt-2;
        @extend .mb-0;
        line-height: 1.4;

        p {
          @extend .text-muted;
        }

        ul {
          @extend .mt-2;
          @extend .mb-0;

          li {
            @extend .text-muted;
            @extend .mb-1;
            list-style-type: disc;

            &:last-of-type {
              @extend .mb-0;
            }
          }
        }
      }

      &__button{
        @include media-breakpoint-only('md') {
          font-size: .55rem;
        }
      }
    }

    .currency-note {
      font-size: 0.75rem;
      color: #8b887f;
      @extend .mt-2;
    }

    .credit-balance {
      color: $green-3;
      font-weight: 700;
    }

    .ugiftcert-remove {
      color: #cc0000;
      font-weight: bold;

      :hover {
        color: red;
      }
    }
  }
}
