/* Block hints */

.amfpc-block-info
{
  position:relative;
  border:1px dotted #008080;
  margin:6px 2px;
  padding:18px 2px 2px 2px;
  zoom: 1;
}

.amfpc-block-info.dynamic
{
  position:relative;
  border:1px dotted gold;
  margin:6px 2px;
  padding:18px 2px 2px 2px;
  zoom: 1;
}

.amfpc-block-info>.amfpc-block-handle,
.amfpc-block-info>.amfpc-template-info
{
  position:absolute;
  top:0;
  padding:2px 5px;
  background:#008080;
  color:white;
  font:normal 11px Arial;
  text-align:left !important;
  z-index:998;
  text-transform: none;
}

.amfpc-block-info.dynamic .amfpc-block-handle, .amfpc-block-info.dynamic .amfpc-template-info {
  background: gold;
}

.amfpc-block-info>.amfpc-block-handle
{
  cursor: default;
  left:0;
  white-space: nowrap;
}

.amfpc-block-info>.amfpc-template-info
{
  right:0;
}

.amfpc-block-info>.amfpc-block-handle:hover
{
  background-color: orange !important;
}

.amfpc-block-info.active
{
  -webkit-animation: amfpc-blocks 1s linear .5s infinite alternate;
  animation: amfpc-blocks 1s linear .5s infinite alternate;
}

@-webkit-keyframes amfpc-blocks {
  from {opacity: 1;}
  to {opacity: .1;}
}

@keyframes amfpc-blocks {
  from {opacity: 1;}
  to {opacity: .1;}
}

/* Updated blocks */

.amfpc-block-info.updated
{
  border-color: red;
}

.amfpc-block-info.updated>.amfpc-block-handle
{
  top:0;
  background:red;
}

/* Status popup */

.amfpc-info
{
  width: 350px !important;
  min-height: 95px !important;
  position: fixed !important;
  bottom: 10px !important;
  left: calc(100% - 360px) !important;
  background: #990000 !important;
  color: #fff !important;
  z-index: 99999 !important;
  font-size: 32px !important;
  padding: 10px !important;
}

.amfpc-info h1
{
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin-top: 0px !important;
}

.amfpc-info h2
{
  margin-top: 12px !important;
  text-align: center !important;
  font-size: 30px !important;
  font-weight: 400 !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

.amfpc-info .amfpc-content
{
  font-size: 14px !important;
  line-height: 1.4em !important;
}