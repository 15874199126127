@import "../colors";
@import "../fonts";
@import "../flex";
@import "../common/utils";

.checkout-success {

  h1, h2 {
    color: $red-3;
    font-family: $font-header-primary;
    font-weight: 600;
  }

  .mtb-welcome {
    h1 {
      font-size: 8rem;
      line-height: 0.65;
      margin: 0 0 35px;
    }

    h2 {
      margin: 0 0 16px;
    }

    img.karls-club-badge {
      transform: rotateZ(11deg);
    }

    @include media-breakpoint-down(md) {
      h1 {
        font-size: 4rem;
      }
    }
  }

  h3 {
    margin: 12px 0;
  }

  .bundle-card-prod-img {
    margin: 0 -15px;
  }

  .dancing-kvd {
    height: 78px;
    width: 65px;
    margin: 45px auto 0;
    background: url(-mage-img('checkout/confirmation/dancing-kvd.gif')) no-repeat center;
    background-size: cover;
  }

  .hands {
    position: relative;
    background-color: purple;
    width: 100%;

    .animated-hand {
      margin-top: -80px;
      position: absolute;
      background-repeat: no-repeat;
      transform: scale(.3) translateY(-150%);
      &.left-hand {
        background-image: url(-mage-img('checkout/confirmation/left-hand-desktop.svg'));
        left: 50%;
        @include media-breakpoint-up(lg) {
          left: 10px;
          transform: none;
        }
      }

      &.right-hand {
        background-image: url(-mage-img('checkout/confirmation/right-hand-desktop.svg'));
        right: 50%;
        @include media-breakpoint-up(lg) {
          right: 10px;
          transform: none;
        }
      }

      @keyframes fadein {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      @-moz-keyframes fadein { /* Firefox */
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      @-webkit-keyframes fadein { /* Safari and Chrome */
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      @-o-keyframes fadein { /* Opera */
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      height: 290px;
      width: 250px;
      transition-property: opacity, visibility;
      transition-duration: 0.25s, 0s;

      -webkit-animation: fadein 4s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 4s; /* Firefox < 16 */
      -o-animation: fadein 4s; /* Opera < 12.1 */
    }
  }

  p {
    .email {
      font-size: 16px;
      line-height: 28px;
      font-weight: 600;
      display: block;
      -ms-word-wrap: break-word;
      word-wrap: break-word;
      text-align: center;
    }
  }

  .btn-wrap {
    margin: 10px 0;
  }

  .marketing {
    margin: 40px 0;
    overflow: hidden;
  }

  .btn {
    margin: 0 auto;
  }

  .order-review-container, .shipping-address-container {
    margin-top: 10px;
    margin-bottom: 10px;
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  .shipping-address {
    .content {
      padding: 20px 10px;
    }
  }

  .order-review, .shipping-address {
    background-color: #fff;
    margin-bottom: 15px;
    text-align: left;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

    #address-container {
      margin: 5px 0;
      font-size: 16px;
      font-family: $font-primary;
      font-weight: 400;
    }

    .header {
      background-color: $blue-4;
      font-size: 16px;
      font-weight: 400;
      line-height: 35px;
      padding: 10px 0;
      margin-top: 0;

      h4 {
        padding: 0 15px;
        margin: 4px 0;
        text-transform: none;
        color: $white;
      }
    }

    #my-orders-table {
      @include table-default;
      tbody.odd {
        tr.bundle-item-row {
          background-color: $cream-bg;
        }
      }
    }

    .product-name {
      font-size: 14px;
      font-family: $font-primary;
      font-weight: 600;
    }
  }

  .clarification {
    color: #838989;
    font-size: 12px;
    line-height: 20px;
    font-weight: normal;
    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .value-prop {
    @extend %flex-column-center;
    @include media-breakpoint-up(md) {
      img {
        max-width: 80%;
      }
    }
  }
}

.blog-articles {
  .article-information {
    min-height: 78px;
    margin: 0 15px 15px;
    h3 {
      font-size: 24px;
      font-family: $font-primary;
      a {
        &:hover {
          text-decoration: none;
        }
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}

#address-modal {
  .button-row {
    .btn {
      margin-bottom: 6px;
      &:last-of-type(.btn) {
        margin-bottom: 0;
      }
    }
  }
}
