.gifts-funnel {
  &.species {
    img {
      border-radius: 50px;
      height: 100px;
      width: 100px;
    }
    @include media-breakpoint-up(md) {
      .caption {
        min-height: 3rem;
      }
    }
  }

  .delivery {

    @include media-breakpoint-down(sm) {
      min-height: 600px;
    }

    .description {
      min-height: 28px;
    }

    .btn-delivery-method {
      text-align: left;
      display: block;
      border-radius: 0.4rem;
      border: 3px solid #e9e9e9;
      background-color: white;
      padding: 0.75rem;
      width: 100%;
      color: $body-color;

      .heading {
        font-weight: bold;
        font-size: 1rem;
      }

      &:active, &:focus {
        border-color: #dd5638;
        outline: none;

        .fa-angle-right {
          color: #dd5638;
        }
      }

      .fa-angle-right {
        float: right;
        margin-top: 1.2rem;
        font-size: 2rem;
        color: #999999;
      }
    }
  }

  .recipient {
    .form-group {
      label {
        &:not(.error) {
          font-size: 0.75rem;
          text-transform: uppercase;
          font-weight: bold;
          color: #34534b;
        }
      }
    }
  }
}

.gifts-upsell-page {
  .page-title {
    @include media-breakpoint-down(sm) {
      font-size: 1.75rem;
    }
  }

  .card {
    border: 0;
    display: flex;
    flex-direction: column;
    height: 100%;

    .card-body {
      padding-bottom: 0;
      flex-grow: 1;
    }

    .product-name {
      color: $blue-4;
      margin-bottom: 0.5rem;

      .brand {
        font-size: 12px;
      }
      .name {
        font-size: 1.25rem;
        line-height: 1.2;
        font-family: $font-header-primary;
        text-transform: uppercase;
        font-weight: 600;
        @include media-breakpoint-up(md) {
          min-height: 3rem; /* Allow for 2 lines of text */
        }
      }
    }

    .reviews {
      margin-bottom: 1rem;
      min-height: 21px; // Avoid reflowing content when Powerreviews loads

      .p-w-r {
        margin-left: 0;
        .pr-snippet {
          margin: 0;
        }
      }
    }

    .product-image {
      margin: 0rem -1.25rem 0.5rem; /* Offset the padding on the card body */

      .img-fluid {
        width: 100%;
      }
    }

    .price {
      font-weight: bold;
      margin-bottom: 0.5rem;
      font-size: 1.25rem;
      font-family: $font-header-primary;
      font-weight: 600;

      .free-shipping {

      }
    }

    .product-description {
      p {
        font-size: 13px;
        flex-grow: 1;
      }
    }

    .card-footer {
      background: none;
      border: 0;
      justify-content: flex-end;

      label.error {
        background-color: #c00;
        color: white;
        border-radius: 4px;
        padding: 0.2rem 0.4rem;
        font-weight: bold;
      }
    }
  }
}