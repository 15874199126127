@import "../colors";

#xsell-gallery {

  .xsell-items-container {
    max-width: 100%;
    display: none;

    .slick-next, .slick-prev {
      &.slick-disabled {
        display: none!important;
      }
    }
    .slick-track {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .slick-slide {
      width: 200px;
      margin: 0 4px;
      box-shadow: 2px 4px 5px 0 $grey-2;
      border: 1px solid $grey-3;
    }
  }


  .xsell-item {
    .card {
      border: none;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      height: 240px;
      img {
        margin: 0 auto;
      }
      .prices {
        margin: auto 4px 6px;
        align-self: flex-start;
      }
      .regular-price {
        font-weight: normal;
        color: $grey-1;
      }
      .club-price {
        color: $blue-4;
      }
      .buttons {
        margin-top: auto;
        padding: 6px;
      }
    }
  }
}