.home {
  position: relative;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }

  @include media-breakpoint-up(md) {
    h1 {
      font-size: 3.75rem;
    }
  }

  h1 {
    margin-bottom: 1rem;
  }

  h4 {
    line-height: 0.5;
    margin-bottom: 0;
  }

  .karls-best-sellers, .new-products {
    position: relative;
    border-bottom: 1px dashed $cream-3;
    margin-bottom: 2rem;

    .card-wrapper {
      width: 100%;
      visibility: hidden;

      .slick-track{
        display: flex;

        .slick-slide{
          height: auto;

          .product-card{
            height: 100%;

            .pr-snippet{
              text-align: center;
            }
          }
        }
      }
    }

    .bug-bait {
      width: 100px;
      @include media-breakpoint-up(sm) {
        margin-top: -45px;
      }
    }

    .karl-looking-down {
      width: 60px;
      margin-left: -60px;
      margin-top: -90px;
    }

    @include media-breakpoint-down(sm) {
      .slick-arrow {
        display: block !important;
      }
    }

    @include media-breakpoint-down(xs) {
      .slick-arrow {
        &.slick-prev {
            transform: scaleX(-1) translateX(-60%) translateY(-73px)!important; border-radius: 20px 0 0 20px;
          }
        &.slick-next {
          transform: translateX(-60%) translateY(-73px)!important; border-radius: 20px 0 0 20px;
        }
      }
    }
  }

  .bug-bg {
    background-image: url(-mage-img('home/bug-bait.svg'));
    background-repeat: no-repeat;
    background-position: top 5px right -50px;
    z-index: 1;
    height: 100px;
    position: absolute;
    width: 100px;
    top: 0;
    right: 0;

    @include media-breakpoint-up(sm) {
      background-image: none;
    }
  }

  .shop-categories {
    border-bottom: 1px dashed $cream-3;
    @extend .mb-2;

    h3 {
      text-decoration: underline;
    }

    .category-img {
      $size: 68px;

      height: $size;
      width: auto;
      border-radius: $size;
      display: block;
      margin: 0 auto;
    }

    p {
      color: $green;
      min-height: 3rem;
    }

    .boat {
      background-image: url(-mage-img('home/boat.svg'));
      background-repeat: no-repeat;
      height: 100px;
      position: absolute;
      width: 85px;
      top: -50px;
      right: #{(calc(50% - 95px))};
      @include media-breakpoint-up(md) {
        right: #{(calc(50% - 145px))};
      }
    }
  }

  .social-cta {
    @include media-breakpoint-up(md) {
      font-size: 72px;
    }
    font-size: 40px;
    color: $orange;

    span.pop {
      @include media-breakpoint-up(md) {
        font-size: 56px;
        margin: 5px 0;
      }

      font-size: 32px;
      display: inline-block;
      margin: 2px 0;
      position: relative;
      vertical-align: top;

      &:after {
        @include media-breakpoint-up(md) {
          height: 5px;
          width: 51px;
          box-shadow: 7px 60px 0 0 $orange;
        }

        position: absolute;
        border-radius: 10px;
        height: 3px;
        width: 30px;
        box-shadow: 4px 35px 0 0 $orange;
        content: ' ';
        left: 0;
        top: 0;
      }
    }
  }

  // commons
  img.inverted {
    @include mirror();
  }
}
