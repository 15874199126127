// Headers
h1 {
  font-family: $font-header-primary;
  font-weight: 600;
  text-transform: uppercase;
  color: $brown-7;
}

h2, h3 {
  font-weight: $font-weight-bold;
  font-family: $font-header-primary;
  text-transform: uppercase;
  color: $green;
  line-height: 1;

  &.primary {
    font-family: $font-header-primary;
    color: $orange;
    font-weight: 600;
  }
}

h4, h5, h6 {
  font-family: $font-primary;
  font-weight: 600;
  text-transform: uppercase;
  color: $green;
  &.alternate {
    font-family: $font-header-primary;
    letter-spacing: 0.075rem;
    font-weight: 400;
  }
}

// List items
li {
  list-style-type: none;
}

// Containers
.container, .container-fluid {
  &.fluid-lg {
    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }
  }

  &.fluid-xs {
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      padding: 0;

      .row {
        margin-left: 0;
        margin-right: 0;
        > [class^="col"] {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

// Cards
.card {
  border: none;
  border-radius: 0;
}

.card-header {
  background-color: $blue-4;
  color: $white;
  &:first-child  {
    border-radius: 0;
  }
}

// Modals

.modal-content {
  background-color: $white;
  border: 0;
}
.modal-header {
  background-color: $blue-4;
  color: $white;
}

.modal-footer {
  flex-wrap: wrap;

  > .btn {
    margin-bottom: 0.5rem;
  }

  > :not(:last-child) {
    margin-right: 0;
  }

  > :not(:first-child) {
    margin-left: 0.5rem;
  }
}

p {
  a {
    text-decoration: underline;
    &:hover,
    &:active,
    &:visited {
      text-decoration: underline;
    }
  }
}
