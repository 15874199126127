@import "./fonts/gotham-ssm";
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$font-primary: $font-family-base;
$font-header-primary: brandon-grotesque, sans-serif;
$font-header-secondary: $font-primary;

.font-primary{
  font-family: $font-primary!important;
}
.font-header-primary{
  font-family: $font-header-primary!important;
}
.font-header-secondary{
  font-family: $font-header-secondary!important;
}

// Inspired in text-lowercase, text-capitalize and text-uppercase
.text-transform-none{
  text-transform: none!important;
}
// Inspired in text-primary, text-secondary, text-tertiary
.text-brown{
  color: $brown-3!important;
}
// Inspired in font-weight-light, font-weight-bold
.font-weight-medium{
  font-weight: 500;
}

@mixin fa() {
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 900 !important;
  display: inline-block !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-rendering: auto !important;
  -webkit-font-smoothing: antialiased !important;
}

@mixin fa-solid() {
  @include fa();
}

@mixin fa-brand() {
  @include fa();
  font-weight: 400 !important;
}
