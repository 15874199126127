.addons {
  .shipping-container {
    padding-left: 36px;
    line-height: 24px;
    background: url(-mage-img('shipping-icon.svg')) 0 2px no-repeat;
  }

  .select-container {
    padding: 0.5rem 0;
  }

  .product-wrap {
    margin-bottom: 1rem;

    .product-information {
      img { width: 100%; height: auto; }
    }
  }

  .last-product-wrapper .product-wrap {
    margin-bottom: 0;
  }

  .product-price {
    line-height: 1;
  }

  .review {
    text-align: left;
    min-height: unset;
    padding: 0;
    margin-bottom: 1rem;

    .rating-box {
      width: inherit;
      & > .stars {
        display: flex;
        justify-content: left;
        justify-items: left;
        align-items: left;
        .star {
          @include stars();
          &.star-empty {
            position: relative;
            margin-right: 8px;
          }
          &.star-wrapper {
            left: 0;
            margin: 0;
          }
          &.star-filled {
            left: 0;
          }
        }
        .amount {font-size: 0.750rem;}
      }
    }
  }

  .interested {
    .subtotal { color: #dd5638; font-weight: bold; }
  }

  .mobile-nav {
    .interested, .not-interested {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: white;
      left: 0;
      margin: 0;
      min-height: 60px;
      padding: 1rem 0;
      border-top: 3px solid #abb98d;
      box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.3);

      //.btn {
      //  width: 120px;
      //}
    }

    .interested {
      //padding-top: 0;



      .upsell-mycost {
        font-size: 1.25rem;
        line-height: 1;
      }

      .upsell-total {
        font-size: 1rem;
        line-height: 1;
      }

      .items {
        background-color: $blue-4;
        margin-top: -2rem;
        margin-bottom: -1.5rem;
        padding: 0.5rem 0 1rem;

        a:focus {
          outline: none;
        }

        .upsell-item-count > span {
          display: block;
          width: 80px;
        }
      }

      .next-step {
        margin-bottom: -0.5rem;
      }
    }
  }

  .addon-cart {
    h4 {
      text-transform: initial;
    }

    .in-your-box {
      padding: 1rem 0;
      margin: 0;
      background-color: $brown-1;
    }
  }

  @include media-breakpoint-up(md) {
    .last-product-wrapper {
      .product-information {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
    .product-information {
      border-bottom: 2px solid $grey-2;
      padding-bottom: 1rem;
    }
  }

  @include media-breakpoint-down(sm) {
    .products {
      .last-product-wrapper {
        .product-wrap {
          border-bottom: 0 !important;
          padding-bottom: 0 !important;
        }
      }

      .product-wrap:not(:last-child) {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 2px solid $grey-2;
      }
    }
  }

  .magnify-image {
    width: 41px;
    height: 41px;
    border: 1px solid #d2d4d4;
    border-radius: 22px;
    top: 0;
    left: 0;
    background: url(-mage-img('mag-glass-small.svg')) center no-repeat;

    position: absolute;
    background-color: #fff;

    &:hover {
      background-color: #d2d4d4;
      transition: background-color 0.5s ease;
      border: 1px solid #d2d4d4;
    }
    &:focus {
      outline: none;
    }
  }

  .item-in-cart {
    line-height: 18px;
    padding: 0.5rem 0;
    position: relative;

    &:last-of-type {
      padding-bottom: 0;
    }
    &:not(:last-of-type) {
      border-bottom: 2px solid $grey-2;
    }
    .remove-item {
      position: absolute;
      top: 0.5rem;
      right: 0;
    }
  }

  .upsell-mybox, .upsell-mycost, .upsell-total {
    font-size: 1.25rem;
  }

  .upsell-item-count > span {
    background: url(-mage-img('subscription/funnel/box-bg.svg')) no-repeat;
    width: 84px;
    height: 56px;
    background-position: right;
    text-align: center;
    padding-top: 6px;
  }
}