.contact-sidebar {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;

  $anim-timing-function: cubic-bezier(0.42, 0.0, 0.58, 1);
  $in-duration: 250ms;
  $out-duration: 200ms;

  &.visible {
    z-index: 112;
    pointer-events: all;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#333, .5);
    z-index: 0;
    transition: opacity $out-duration $anim-timing-function;
    opacity: 0;
  }

  #contact-app {
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
    background-color: $white;
    transition: transform $out-duration $anim-timing-function, opacity $out-duration $anim-timing-function;
    transform: translateX(100%);
    display: flex;
    flex-flow: column;
    max-width: 360px;
    opacity: 0;

    .root {
      padding: 1rem;
      flex: 1;
      overflow: auto;
    }
  }

  .contact-title {
    text-transform: none;
  }

  .search-bar {
    .form-control {
      font-size: 0.75rem;
      border-color: $primary;
      border-radius: 0;
      border-right-color: transparent;
      &:focus {
        box-shadow: none;
      }
    }

    .btn-outline-primary {
      border: 1px solid $primary;
      color: $primary;
      border-left-color: transparent;
      border-radius: 0;

      &:focus,
      &:active {
        color: white;
      }
    }
  }

  .popular-searches {
    display: flex;
    flex-flow: row nowrap;
    overflow: auto;
    margin: 0 -1rem 1rem;
    padding: 0 1rem 1rem;

    .btn {
      margin-right: 0.4rem;
    }
  }

  .contact-options {
    .option-title {
      text-transform: none;
      font-size: 1rem;
    }

    .fa {
      color: #495057;
    }

    .list-group-item {
      min-height: 4.5rem;
      padding: 0.75rem 1rem;
    }

    .list-group-item-action {
      &.active {
        .fa,
        .option-title,
        p {
          color: white;
        }
      }

      > div:first-child {
        min-width: 1.75rem;
        text-align: center;
      }
    }
  }

  .our-hours {
    margin-bottom: 1rem; /* Bit of bottom margin so text isn't at the very bottom of window if scrolling present */

    .title {
      text-transform: none;
    }
    
    p {
      font-size: 0.8rem;
    }

    dl {
      font-size: 0.75rem;
      margin-bottom: 0.25rem;

      dt {
        font-weight: $font-weight-bold;
      }

      dd {
        &.closed {
          text-transform: uppercase;
        }
      }
    }

    .karls-time {
      font-size: 0.75rem;
    }
  }

  &.open {
    .overlay {
      transition-duration: $in-duration;
      opacity: 1;
    }

    #contact-app {
      transition-duration: $in-duration;
      transform: translateX(0);
      opacity: 1;
    }
  }
}