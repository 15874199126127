@mixin responsive-background ($mobile, $desktop, $break-width) {
  background: url(-mage-img($mobile)) no-repeat bottom center;
  @include media-breakpoint-up($break-width) {
    background-image: url(-mage-img($desktop));
  }
  background-size: cover;
}

@mixin pop ($left, $top, $offset-x, $offset-y) {
  &.pop {
    left: $left + $offset-x;
    top: $top + $offset-y;
    opacity: 0;
    transition-property: opacity, left, top;
    transition-duration: .5s, 1s, 1s;
    transition-timing-function: ease;
    &.in {
      left: $left;
      top: $top;
      opacity: 1;
    }
  }
}

.try-mtb {
  #section-1 {
    @include responsive-background('home/mtb/mobile-hero.jpg', 'home/mtb/desktop-hero.jpg', lg);
    @include media-breakpoint-up(lg) {
      height: 700px;
    }
    position: relative;
    h1 {
      font-size: 2.4rem;
      @include media-breakpoint-up(md) {
        font-size: 50px;
      }
      line-height: 1;
    }

    .trustpilot-widget {
      margin: 32px 0 0;
      transform: scale(0.85);
    }

    p {
      margin: 0 auto 24px;
      max-width: 360px;
      color: #fff;
      font-size: 16px;
    }

    .box.img-fluid {
      max-width: 60%;
      margin-bottom: -2rem;
    }
  }

  h2 {
    font-size: 40px;
  }

  p.heading {
    font-size: 1rem;
  }

  .swipe {
    &::after {
      content: "";
      background: url(-mage-img('arrow-draw.svg')) no-repeat center bottom;
      width: 30px;
      height: 30px;
      display: inline-block;
      margin-bottom: -5px;
    }
  }

  #box {
    position: fixed;
    z-index: 3;
    width: 40%;
    max-width: 700px;
    min-width: 350px;
    left: 10%;
    opacity: 1;
    &.initial {
      left: -200px;
      opacity: 0;
    }
    &.initial-anim {
      transition-property: left, opacity;
      transition-duration: .8s, 1s;
      transition-timing-function: ease;
      left: 10%;
      opacity: 1;
    }
    &.fixed {
      position: fixed;
    }
    &.unfixed {
      position: absolute;
    }
  }

  #baits-container {
    z-index: 2;
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    #baits-wrapper {
      position: relative;
      top: 0;
      .bait {
        opacity: 0;
        display: block;
        position: absolute;
        &.one {
          @include pop(206px, 99px, 40, 90);
        }
        &.two {
          @include pop(109px, 341px, 30, 40);
        }
        &.three {
          @include pop(542px, 666px, -40, 30);
        }
        &.four {
          @include pop(187px, 781px, 30, 30);
        }
        &.five {
          @include pop(547px, 899px, -40, 30);
        }
        &.six {
          @include pop(287px, 969px, 30, 30);
        }
      }
    }
  }

  #section-2,
  #section-3,
  #section-4 {
    > .container {
      border-bottom: 1px dashed $brown-2;
    }
  }

  #section-1,
  #section-2,
  #section-3,
  #section-4,
  #section-5 {
    .callouts {
      img {
        display: block;
      }
    }
  }

  #section-2 {
    .callouts {
      margin-top: 2px;
      font-size: 1rem;
      letter-spacing: .12em;
      .callout {
        margin-top: 1rem;
        margin-bottom: 1rem;
        h5 {
          line-height: 26px;
          margin: 0;
        }

        p {
          letter-spacing: .04em;
          margin-bottom: 0;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
        }
      }
    }
  }

  #section-3 {
    .subscript {
      border-top: 1px dashed $brown-2;
      min-height: 48px;
    }

    @include media-breakpoint-between(md, lg) {
      .btn-wrap {
        min-height: 116.38px;
      }
    }

    .card {
      border-bottom: 2px solid $brown-1;
      position: relative;
      .icon-free-shipping-graphic {
        position: absolute;
        right: 0;
        margin-top: -56px;
        margin-right: 24px;
      }

      .btn-tertiary {
        padding: .85rem 1rem !important
      }
    }
  }

  #section-4 {
    .trustpilot-widget {
      margin-top: 30px;
      height: 130px;
    }
  }

  #section-5 {
    @include responsive-background('home/mtb/learn-hero-mobile.png', "home/mtb/learn-hero.png", lg);
    height: 700px;
    max-height: none;
    background-size: 250%;
    @include media-breakpoint-up(sm) {
      background-size: 175%;
    }
    @include media-breakpoint-up(md) {
      background-size: 150%;
      height: 780px;
    }
    // bigger than sm
    @include media-breakpoint-up(lg) {
      background-size: contain;
      height: 540px;
    }

    .callouts {
      text-align: center;
      & > div {
        h6 {
          border-bottom: $green-3 solid 4px;
          font-size: 14px;
          letter-spacing: .12em;
          line-height: 36px;
          width: 230px;
          margin: auto;
        }
        &:last-child {
          h6 {
            border: none;
          }
        }
      }
    }
  }
}
