$orange-4: #904004 !default;
$orange-3: #FF8426 !default;
$orange-2: #F8B583 !default;
$orange-1: #FCE1CD !default;

$yellow-4: #998D3C !default;
$yellow-3: #FFE63D !default;
$yellow-2: #FFF5B2 !default;
$yellow-1: #FFFBE0 !default;

$blue-5: #3b524b !default;
$blue-5-half: rgba(116, 116, 114, 0.5);
$blue-4: #34534B !default;
$blue-3: #40B599 !default;
$blue-2: #C1E6E2 !default;
$blue-1: #E0F2F0 !default;

$green-7: #008000 !default;
$green-6: #77ae6a !default;
$green-5: #83ac70 !default;
$green-4: #70873C !default;
$green-3: #ABB98D !default;
$green-2: #D5DCC6 !default;
$green-1: #EEF1E8 !default;

$red-5: #c00 !default;
$red-4: #C51910 !default;
$red-3: #DD5638 !default;
$red-2: #EDAA9B !default;
$red-1: #F8DDD7 !default;

$cream-6: #EEE9CE !default;;
$cream-5: #EDE9CC !default;
$cream-4: #CBC07D !default;
$cream-3: #E1DAB3 !default;
$cream-2: #F0ECD9 !default;
$cream-1: #F9F7EF !default;
$cream-0: #fffdf8 !default;
$cream-bg: #f9f7f0;

$brown-7: #856245 !default;
$brown-6: #8E8C83 !default;
$brown-5: #dcd7c8 !default;
$brown-4: #5F311F !default;
$brown-3: #A18669 !default;
$brown-2: #D0C2B4 !default;
$brown-1: #ECE6E1 !default;

$grey-7: #2B3336 !default;
$grey-6: #eae7dd !default;
$grey-5: #a4a198 !default;
$grey-4: #95938b !default;
$grey-3: #DCD7C9 !default;
$grey-2: #FAF6ED !default;
$grey-1: #5f5f5e !default;
$text-muted: $grey-3;

$default-link-colors: (
  main: $blue-4,
  hover: $red-3,
  active: $red-4,
  visited: $blue-4
);
