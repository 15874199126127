$name: "Gotham SSm";
$path: "./css/fonts/gotham_ssm";
$types: "standard", "narrow";
$fonts: (
        "200": "XLight",
        "300": "Light",
        "400": "Book",
        "500": "Medium",
        "700": "Bold",
        "800": "Black"
);

@each $type in $types {
  $suffix: "";

  @if $type != "standard" {
    $suffix: " #{to-upper-case(str-slice($type, 1, 1)) + str-slice($type, 2)}";
  }

  @each $weight, $file in $fonts {
    @font-face {
      font-family: quote('#{$name}#{$suffix}');
      src:  url("#{$path}/#{$type}/woff2/#{$file}.woff2") format("woff2"),
      url("#{$path}/#{$type}/woff/#{$file}.woff") format("woff");
      font-weight: #{$weight};
      font-style: normal;
    }
    @font-face {
      font-family: quote('#{$name} #{$suffix}');
      src:  url("#{$path}/#{$type}/woff2/#{$file}Italic.woff2") format("woff2"),
      url("#{$path}/#{$type}/woff/#{$file}Italic.woff") format("woff");
      font-weight: #{$weight};
      font-style: italic;
    }
  }
}