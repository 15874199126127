// consider: move to form overrides
.button-row {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;

  .b {
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }

    .btn {
      max-width: unset;
      width: 100%;
    }
  }
}

.btn-social-login {
  i {
    margin-right: 0.5rem;
  }

  &-google {
    background-color: #ea4335;
    color: $white;
    &:hover {
      background-color: #ff5d4f;
      color: $white;
    }
  }

  &-facebook {
    background-color: #3b5999;
    color: $white;
    &:hover {
      background-color: #5573b3;
      color: $white;
    }
  }
}
