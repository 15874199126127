@import "../colors";
@import "../flex";
@import "../common/utils";

#club-faq-modal {
  .value-prop {
    @extend %flex-column-center;
  }

  .props {
    img {
      max-width: 120px;
    }
  }

  .savings {
    font-size: 1.5rem;
  }

  .first-year {
    background-color: $cream-3;
    padding: 6px 8px;
  }

  .flex-column-center {
    @extend %flex-column-center;
  }

  .board {
    background: transparent url(-mage-img('club/creamwood.png')) repeat;
  }

  .stretch {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}