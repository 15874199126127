$selector: '.heading-box';

#{$selector}{
  text-align: center;
  margin-bottom: $spacer * 2;
  @extend .mt-4;

  &__intro,
  &__title{
    font-weight: $font-weight-bold;
  }

  &__intro{
    margin-bottom: 0;
    text-transform: uppercase;
  }

  &__title{
    color: $brown-7;
    font-family: $font-header-primary;
    font-weight: $font-weight-bold;
    font-size: 3rem;
  }

  &__sub-title{
    margin-bottom: .3rem;
  }
}