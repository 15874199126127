$selector: ".karls-quantity-control";

#{$selector} {
  $border-width: 1px;
  $width: (
    "desktop": 34px,
    "mobile": 40px
  );
  $height: (
    "desktop": 34px,
    "mobile": 32px
  );
  display: inline-flex;
  border: solid $border-width $grey-5;
  border-radius: 2px;

  &__button,
  &__input{
    flex-grow: 0;
    flex-shrink: 0;
    height: map_get($height, "desktop") - $border-width*2;
  }
  &__button{
    background: $grey-6;
    width: map_get($width, "desktop");
    position: relative;
    user-select: none;

    &--disabled{
      opacity: .4;
    }

    &:not(&--disabled){
      cursor: pointer;
    }

    .fa{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__input{
    border-radius: 0!important;
    width: map_get($width, "desktop") * 1.5;
    -moz-appearance: textfield!important;
    text-align: center;

    &,
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button{
      -webkit-appearance: none;
    }
  }

  @include media-breakpoint-down("xs") {
    &__button{
      height: map_get($height, "mobile");
      width: map_get($width, "mobile");
    }
    &__input{
      height: map_get($height, "mobile");
      width: map_get($width, "mobile") * 1.25;
    }
  }
}