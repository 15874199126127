@mixin media-ie11() {
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    @content;
  }
}

@mixin edge-only() {
  @supports (-ms-ime-align:auto) {
    @content;
  }
}

@mixin ie-and-edge() {
  @include media-ie11() {
    @content;
  }
  @include edge-only() {
    @content;
  }
}

@mixin webkit() {
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @content;
  }
}

@mixin firefox() {
  @-moz-document url-prefix() {
    @content;
  }
}

.col-ie-flex-fix {
  @include media-ie11() {
    flex: 0 0 auto;
  }
}