.sub-funnel.species {
  img {
    border-radius: 50px;
    height: 100px;
    width: 100px;
  }
  .species{
    &__name{
      font-size: $h4-font-size;
      margin-top: 1rem;
    }
  }
  @include media-breakpoint-up(md) {
    .caption {
      min-height: 3rem;
    }
  }
}

.sub-funnel.plans {
  .btn-primary{
    width: 180px;
  }
}
