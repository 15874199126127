$red-3-hover: #FF6E4E !default;

.btn {
  border-width: 2px;
  border-color: transparent;
  border-radius: 1.0625rem;
  box-shadow: none;
  color: $white;
  font-size: 0.75rem;
  font-weight: 500;
  max-width: 300px;
  padding: .375rem 1.2rem;
  text-align: center;
  text-transform: uppercase;

  &.btn-fill {
    width: 100%;
    max-width: unset;
  }

  &.btn-lg {
    padding: .85rem 2rem !important;
    border-radius: 60px !important;
    font-size: 0.8125rem;
  }
}

@mixin button-state-active ($bordercolor, $bgcolor, $color, $disabled, $disabled-border, $disabled-color) {
  &:active,
  &:hover,
  &.active:hover,
  &:active:hover,
  &:focus,
  &.active:focus,
  &:active:focus {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) !important;
    border: 2px solid;
    border-color: $bordercolor !important;
    background-color: $bgcolor !important;
    color: $color !important;
  }

  &:disabled,
  &.disabled {
    border: 2px solid;
    border-color: $disabled-border !important;
    background-color: $disabled !important;
    color: $disabled-color !important;
  }
}

.btn-primary {
  &,
  &.active:not(:disabled):not(.disabled){
    @include button-state-active($red-3-hover, $red-3-hover, $white, #EDAA9B, #EDAA9B, $white);
    background-color: $red-3;
    border-color: $red-3;
  }
}

.btn-secondary {
  &,
  &.active:not(:disabled):not(.disabled){
    @include button-state-active(#589F8E, #589F8E, $cream-6, #98A8A4, #98A8A4, $cream-6);
    background-color: $blue-4;
    border-color: $blue-4;
    color: $cream-6;

    &.dark {
      @include button-state-active($white, $white, #589F8E, #DCDCDC, #DCDCDC, #979797);
      background-color: $cream-6;
      border-color: $cream-6;
      color: $blue-4;
    }
  }
}

.btn-tertiary {
  &,
  &.active:not(:disabled):not(.disabled){
    @include button-state-active($blue-3, transparent, $blue-3, transparent, $blue-4, #99A9A5);
    background-color: transparent;
    border-color: $blue-4;
    color: $blue-4;
    
    &.dark {
      @include button-state-active(#FAF6ED, transparent, #FAF6ED, transparent, rgba(216,216,216,0.6), rgba(216,216,216,0.6));
      border-color: $cream-6;
      color: $cream-6;
    }
  }
}

.btn-danger {
  $accent: #C87405;
  @include button-state-active(#F16C07, #F16C07, $white, #E3A382, #E3A382, $white);
  background-color: $accent;
  border-color: $accent;
}

.btn-avoid {
  $accent: $red-5;
  @include button-state-active($accent, $red-1, $accent, transparent, rgba(216,216,216,0.6), rgba(216,216,216,0.6));
  background-color: #FFE6E6;
  border-color: $accent;
  color: $accent;
}

.btn-success {
  @extend .btn-secondary;
}

.btn-warning {
  $accent: #EED634;
  @include button-state-active(#FFEB65, #FFEB65, $blue-4, #F6EA99, #F6EA99, $blue-4);
  background-color: $accent;
  border-color: $accent;
  color: $blue-4;
}

.btn-info, .btn-info.disabled, .btn-info:disabled {
  @extend .btn-tertiary;
}

.btn {
  .loading {
    display: none;
  }
  .active {
    display: block;
    > * {
      vertical-align: middle;
    }
  }
  &.in-progress {
    .loading {
      display: block;
    }
    .active {
      display: none;
    }
  }
  &.btn-fill {
    width: 100%;
    max-width: unset;
  }

  img.loader {
    height: 16px;
    float: right;
  }
}