.account {
  h5 {
    text-transform: none;
  }
}

.myaccount-loader {
  text-align: center;
}

.myaccount-loader img {
  width: 15%;
}

.customer-account-create, .customer-account-login {
  .g-recaptcha {
    margin: 0 auto;
    max-width: 300px;
    @include media-breakpoint-down(sm) {
      transform: scale(0.77);
      -webkit-transform: scale(0.77);
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
      max-width: 240px;
    }
  }
}

.customer-account {
  .myaccount-modal .modal-header {
    button.close {
      order: 12;
    }
  }

  .btn .active img {
    height: 16px;
  }

  .modal.fade.in .modal-dialog {
    transform: none;
  }

  .myaccount-modal .modal-footer {
    display: block;
  }

  ul {
    padding-left: 0;
  }

  .highlighted-radio {
    display: block;
    background-color: #fafaf9;
    border: solid 1px #e3e3e3;
    border-top-color: #e7e7e7;
    border-bottom-color: #ccc;
    border-radius: 3px;
    font-weight: normal;
    cursor: pointer;
    position: relative;
    padding: 5px 10px 10px 45px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;

    input {
      position: absolute;
      top: 9px;
      left: 20px;
    }

    &.disabled {
      cursor: not-allowed;
    }

    span.sub-text {
      display: block;
      font-size: x-small;
    }
  }

  .card {
    .card-body {
      padding: 1.5rem;

      .card-title {
        text-transform: none;
        text-decoration: underline;
        margin-bottom: .5rem;
      }

      .card-text {
        font-size: .75rem;
        color: $grey-4;
      }
    }
  }

  a.card-wrapper-link {
    &:hover {
      text-decoration: none;

      .card {
        background-color: $grey-6;
      }
    }
  }
}

.my-account {
  .nav {
    &.nav-tabs {
      border-bottom: 1px solid #CCC;

      .nav-item {
        margin: 0 0.5rem;

        .nav-link {
          border: none;
          color: $green-4;
          @include media-breakpoint-down(xs){
            $padding-x: 0.45rem;
            padding-left: $padding-x;
            padding-right: $padding-x;
          }

          &:not(.active):hover {
            text-decoration: underline;
            color: $green-6;
          }

          &.active {
            color: #333;
            font-weight: bold;
            border-bottom: 3px solid $green-4;
          }
        }
      }
    }
  }

  .pager {
    .amount, .limiter {
      font-size: 0.75rem;
      height: 2rem;
      line-height: 2rem;
    }

    .limiter {
      select {
        font-size: inherit;
      }
    }
  }
}

.customer-credit-balance {
  .credit-amount {
    color: $green-7; // because this is meaningful
  }

  .table {
    a {
      text-decoration: underline;
    }
  }
}

.customer-credit-balance,
.sales-order-history {
  .table {
    font-size: 0.75rem;
    margin-bottom: 1.5rem;

    thead {
      th {
        border-top: none;
      }
    }
  }
}

.sales-order-view {
  .link-print {
    @extend .btn;
    @extend .btn-secondary;
    @extend .mb-3;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &:before {
      @include fa();
      content: "\f02f";
      margin-right: .5rem;
    }
  }
  .separator {
    margin: 0.875rem 0.25rem;
    opacity: 0;
  }
  .link-reorder {
    @extend .btn;
    @extend .btn-secondary;
    @extend .mb-3;

    &:before {
      @include fa();
      content: "\f07a";
      margin-right: .5rem;
    }
  }
  .card-body {
    address {
      margin-bottom: 0;
    }
  }
}

.customer-account,
.my-account {
  h1 {
    @include media-breakpoint-down(sm) {
      font-size: 2rem;
    }
  }
}

body.customer-account-buyagain {
  @include media-breakpoint-down(sm) {
    .buy-again-item{
      &:nth-child(odd){
        padding-left: 0;
      }
      &:nth-child(even){
        padding-right: 0;
      }
    }
  }

  #buy-again {
    a {
      &,
      &:hover {
        text-decoration: none;
      }
    }

    .card {
      display: flex; // .card already has the other flex properties to move footer to the bottom
      height: 100%; // make sure .card fills the column
      border: none;
      margin-bottom: 1rem;

      .card-body {
        padding: 0.25rem 0.5rem 0.5rem;

        .card-title {
          height: 36px; // TODO: Needs a better solution
          line-height: 1.4em;
          font-size: .750rem;
          font-weight: $font-weight-bold;
          @include media-breakpoint-up(md) {
            height: 30px; // TODO: Needs a better solution
            line-height: 1.25em;
          }
        }
      }

      .card-footer {
        padding: 0.5rem;
        border: none;
        background-color: $white;

        .btn {
          &.btn-sm {
            text-transform: none;
            padding: 0.25rem 0.75rem;
            border-radius: 0.5rem;
            width: 100%;
          }
        }
      }
    }

    .price {
      color: $red-3;
      line-height: 1.2;
      font-weight: $font-weight-bold;
      > span {
        color: $blue-3;
        display: block;
        font-weight: $font-weight-normal;
        font-size: 0.75rem;
      }
    }

    &.karls-club-member {
      .price.club-price{
        + .price{
          display: none;
        }
      }
    }

    &:not(.karls-club-member) {
      .price.club-price {
        display: none;
      }
    }
  }
}

.shipped-package {
  @extend .mb-2;
  border: 1px dotted silver;
  padding: 0.75rem 1rem;
  font-size: 13px;
  color: #34534b;

  small { font-size: 12px; }

  .contents {
    padding-left: 1.5rem;

    .contents-item {
      list-style-type: disc;
      font-size: 12px;
    }
  }

  &:last-child {
    @extend .mb-0;
  }
}

// Shitty place for this /shrug
.order-items {
  @include media-breakpoint-up(md) {
    .table-responsive {
      overflow-x: inherit; // Hide scroll bar on desktop
    }
  }
}

.sales-order-view {
  .card-columns {
    @include media-breakpoint-only(sm) {
      column-count: 1;
    }
    @include media-breakpoint-up(md) {
      column-count: 2;
    }
  }
}