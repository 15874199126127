$selector: '.karls-club';

body.karls-club-page{

  // Destroy container + row + col side gutters
  // to allow fullspan sections
  > .container{
    max-width: none!important;
    padding-left: 0!important;
    padding-right: 0!important;

    > .row{
      margin-left: 0!important;
      margin-right: 0!important;

      > .col-12{
        padding-left: 0!important;
        padding-right: 0!important;
      }
    }
  }

  &--fixed{
    #{$selector}--fixed-cta{
      transform: translateY(0);
      visibility: visible;
    }
  }
}

#{$selector}{
  padding: 2rem 8px;

  &:not(:first-child){
    border-top: solid 1px #e9e7de;
  }

  &__shield{
    display: block;
    width: 210px;
    margin: 0 auto 10px;

    @include media-breakpoint-down(sm){
      width: 112px;
    }
  }

  &__title{
    color: $blue-4;
    font-size: 2rem;
    text-align: center;
  }

  & ul#{&}__list{
    list-style: none;
    padding-left: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;

    li{
      position: relative;
      color: $blue-4;
      padding-left: 1rem;

      &:before{
        $width: 10.97;
        $height: 10;
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left:0;
        width: $width+px;
        height: $height+px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='#{$width}' height='#{$height}' viewBox='0 0 10.97 10'%3E%3Ctitle%3Echeck%3C/title%3E%3Cpath d='M.87,5.21A12,12,0,0,1,4.76,8.35h0l-1.82.2A17.17,17.17,0,0,1,6,4C7.21,2.65,8.49,1.45,9.69.23a.75.75,0,0,1,1.1,1c-1.16,1.33-2.35,2.58-3.4,3.9A15.69,15.69,0,0,0,4.87,9.33,1,1,0,0,1,3,9.53H3a10.39,10.39,0,0,0-2.8-3.3h0a.6.6,0,0,1,.64-1Z' style='fill:%23FF8426'/%3E%3C/svg%3E");
        page-break-inside: avoid;
      }
    }

    &--multiple{
      column-count: 2;
      column-gap: 1rem;
    }

    @include media-breakpoint-up(sm){
      &--multiple{
        column-count: 3;
      }

      li{
        font-size: .75rem;
      }
    }
  }

  &--hero,
  &--cta{
    #{$selector}{
      &__list{
        margin: 0 auto;
        max-width: 330px;
        li {
          margin-bottom: 2px;
        }
      }
    }
  }


  & &__button.btn{
    padding: .7rem 4.5rem;
    border-radius: 2rem;
    text-transform: none;
    font-weight: $font-weight-bold;
    max-width: none;
    font-size: .875rem;

    .loader{
      margin-left: 20px;
    }

    @include media-breakpoint-down(sm){
      width: 100%;
      max-width: 320px;
    }
  }

  &__link{
    display: inline-block;
  }

  &__footer{
    text-align: center;
    
    a{
      text-decoration: underline;
    }

    p{
      font-size: .6rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }

    #{$selector}{
      &__button{
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }

    @include media-breakpoint-down(sm){
      p{
        margin-bottom: .5rem;
      }
    }
  }

  &__content{
    position: relative;
    z-index: 1;
  }

  &__icon{
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;

    &--kvd-looking-down{
      width: 85px;
      height: 92px;
      background-image: url(-mage-img('home/kvd-lookingDown.svg'));
    }

    &--next{
      width: 11px;
      height: 23px;
      background-image: url(-mage-img('club/doodle-arrow.svg'));
    }

    &--more{
      width: 110px;
      height: 20px;
      background-image: url(-mage-img('club/and-more.svg'));
    }

    &--doodle{
      &-step{
        width: 31px;
        height: 22px;
        background-image: url(-mage-img('club/doddle-lines.svg'));
      }
      &-save{
        width: 130px;
        height: 50px;
        background-image: url(-mage-img('club/doddle-save-header.svg'));
      }
      &-faq{
        width: 124px;
        height: 64px;
        background-image: url(-mage-img('club/doodle-faq-header.svg'));
      }
    }
  }

  .heading-box{
    &__intro,
    &__sub-title{
      font-family: $font-header-primary;
      color: $brown-7;
      font-size: 1.5rem;
      letter-spacing: 1px;
    }

    &__sub-title{
      letter-spacing: .5px;
      font-weight: $font-weight-bold;
    }

    &__title{
      color: $blue-4;
      font-size: 2.5rem;
      margin-bottom: 0;
    }

    &--small{
      .heading-box{
        &__title{
          font-size: 2.25rem;
        }
      }
    }

    @include media-breakpoint-down(sm){
      &__intro,
      &__sub-title{
        font-size: .9375rem;
      }

      &__title{
        font-size: 1.5rem;
      }

      &--small{
        .heading-box{
          &__title{
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm){
    padding: 1rem 8px;
  }

  /*
   * Fixed CTA header
   */
  &--fixed-cta {
    position: fixed;
    top: 48px;
    left: 0;
    width: 100vw;
    background: $white;
    padding: .75rem 0;
    z-index: 2;
    visibility: hidden;
    transform: translateY(-100%);
    transition: $transition-base;
    box-shadow: 0 1px 1px 0 rgba($black, .1);

    #{$selector}{
      &__shield{
        width: 72px;
      }

      &__list,
      &__button{
        margin: 0 0 0 10px!important;
      }
    }

    #{$selector}__shield,
    p{
      margin: 0;
    }

    p{
      font-size: .6875rem;
      white-space: nowrap;
    }

    @include media-breakpoint-down(sm){
      #{$selector}{
        &__button{
          padding-left: 1rem!important;
          padding-right: 1rem!important;
          margin: 0!important;
        }
      }
    }
    @include media-breakpoint-up(md) {
      top: 0;
    }
  }

  /*
   * Hero
   */
  &--hero{
    background: $cream-bg;
    position: relative;
    overflow: hidden;
    min-height: 600px;
    padding-top: 0;
    padding-bottom: 1rem;

    &__decorator{
      position: absolute;
      transition: $transition-base;

      &--1{
        top: 0;
        left: 0;
        width: 282px;
        transform: translate(13%, -21%);
      }

      &--2{
        top: 0;
        right: 0;
        transform: translate(20%, -26%);
        width: 366px;
      }

      &--3{
        bottom: 0;
        left: 0;
        transform: translate(-31%, -20%);
        width: 459px;
      }

      &--4{
        bottom: 0;
        right: 0;
        transform: translateX(20%);
        width: 353px;
      }
    }

    .heading-box{
      margin-bottom: 0;
    }

    &__month-free{
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: center;

      p{
        margin-bottom: 0;
        line-height: 1.2;
        font-size: .875rem;
      }

      & &__feature{
        font-size: 1.5rem;
        color: $red-3;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
      }
    }

    #{$selector}{
      &__footer{
        a{
          text-decoration: none;
        }
      }

      &__button{
        margin-top: 0;
      }

      &__icon{
        &--kvd-looking-down{
          position: absolute;
          left: 50%;
          top: 10px;
          transform-origin: right bottom;
          transform: rotate(-8deg) translateX(-200px) scale(.7,.7);
        }
      }
    }

    @include media-breakpoint-down(md) {
      &__decorator{
        &--1{
          width: 200px;
        }

        &--2{
          width: 280px;
        }

        &--3{
          width: 380px;

        }
        &--4{
          width: 280px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      min-height: 0;

      &__wrapper{
        padding-top: 4rem;
      }

      &__decorator{
        &--1{
          width: 166px;
          transform: translate(-42%,-21%);
        }

        &--2{
          width: 249px;
          transform: translate(38%,-26%);
        }

        &--3,
        &--4{
          display: none;
        }
      }

      &__month-free{
        p{
          font-size: .6875rem;
        }

        & &__feature{
          font-size: 1.125rem;
          letter-spacing: -1px;
        }
      }

      #{$selector}{
        &__icon{
          &--kvd-looking-down{
            display: none;
          }
        }
      }
    }

    @include media-breakpoint-down(xs) {
      .heading-box{
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
      }

      &__decorator{
        &--1{
          width: 130px;
        }

        &--2{
          width: 150px;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      &__wrapper{
        padding-top: 2rem;
      }
    }
  }

  /*
   * Quote
   */
  &--quote{
    background: url(-mage-img('club/quote-bg.png')) no-repeat center 35%;
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 4rem;

    blockquote{
      display: block;
      margin: 0 auto;
      padding: 0;

      & + blockquote{
        margin-bottom: 1rem;
      }

      &,
      p {
        font-family: $font-header-primary;
      }

      p{
        font-size: 1.5rem;
      }

      cite{
        font-weight: $font-weight-bold;
        font-style: normal;

        &:before{
          content: '-';
        }
      }
    }

    @include media-breakpoint-down(sm){
      padding-top: 2rem;
      padding-bottom: 2rem;

      blockquote{
        p{
          font-size: 1.125rem;
        }
      }
    }
  }

  /*
   * Steps
   */
  &--steps{
    &__blurbs{
      text-align: center;
      padding: 0;

      &__title{
        font-size: .9375rem;
      }
    }

    @include media-breakpoint-down(sm){
      padding: 0;
    }
  }

  /*
   * Products
   */
  &--products{
    background: url(-mage-img('club/doddle-brands-soft.svg')) no-repeat 3% 98%;
    background-size: 190px 220px;

    &__brands{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;

      margin-left: auto;
      margin-right: auto;

      &--item{
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 25%;

        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 1rem;

        img {
          display: block;
          width: 100%;
        }
      }
    }

    #{$selector}{
      &__list{
        margin-left: auto;
        margin-right: auto;
      }

      &__icon--more{
        display: block;
        margin: 1rem auto 0;
      }
    }

    .category-products{
      margin: 0 auto;
      .products-grid{
        border: none;
        margin: 0!important;
        padding: 0!important;

        .product.card.item{
          box-shadow: 0 1px 0 0 rgba($black, 0.05);
        }
      }
    }

    &__title{
      font-family: $font-header-primary;
      font-weight: $font-weight-bold;
      margin-bottom: 1rem;
      text-transform: none;
      text-align: center;
    }

    @include media-breakpoint-down(sm){
      background: none;

      &__brands{
        &--item{
          flex-basis: 50%;
        }
      }

      &__title{
        font-size: 1.5rem;
      }
    }
  }

  /*
   * FAQ
   */
  &--faq{
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__title{
      font-family: $font-header-primary;
      font-weight: $font-weight-bold;
      margin-bottom: 2rem;
    }
  }

  /*
   * CTA
   */
  &--cta{
    &__doodle,
    &__starbust{
      display: block;
      width: 100%;
      margin-top: 4rem;
    }

    &__doodle{
      max-width: 320px;
    }

    &__starbust{
      max-width: 200px;
    }

    .heading-box{
      margin-bottom: 0;

      &__sub-title{
        color: $blue-4;
      }
    }

    #{$selector}{
      &__button{
        margin-top: 0;
      }
    }

    @include media-breakpoint-up(md){
      margin-bottom: 1rem;

      .heading-box{
        &__title{
          font-size: 3.0625rem;
        }

        &__sub-title{
          font-size: 1.5rem;
        }
      }

    }
  }
}