.article-card {
  background: $white;
  margin-bottom: 1rem;
}

.article-wrapper {
  padding: 1rem;
  position: relative;

  .kvd-overlay {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: -25px;
  }
}

.article-title {
  @include media-breakpoint-up(md) {
    min-height: 6rem;
  }
  @include media-breakpoint-only(lg) {
    font-size: 1.2rem;
    min-height: 5rem;
  }
  text-align: center;
  font-size: 1.5rem;
}

.article-description {
  @include media-breakpoint-up(md) {
    min-height: 5.25rem;
  }
  text-align: center;
  margin-bottom: 0.5rem;
}

.article-content {
  padding: 1.5rem 1rem;
  border: 1px solid $cream-3;

  a:not(.read-more) {
    text-decoration: none;
    cursor: default;
  }
}
