$selector: '.plp-filters';

#{$rootSelector} {
  #{$selector} {
    &__row {
      justify-content: space-between;
      align-items: center;

      #{$selector} {

        // Clear button
        &__clear{
          margin: 0.3rem 0 0;
          padding-left: 0;
          padding-right: 0;
          color: $blue-4;
          text-decoration: underline;
          cursor: pointer;
          user-select: none;
          border: none;
          background: transparent none;
          appearance: none;
          outline: none;

          &:focus,
          &:focus:active {
            background: transparent none;
            border: none;
            outline: none;
          }

          &:disabled {
            opacity: .4;
          }
        }

        &__tags {
          &__active {
            #{$selector}__clear {
              display: inline-block;
            }
          }
        }

        &__filter {
          margin-bottom: 1rem;

          &--sort {
            display: flex;
            justify-content: left;

            @include media-breakpoint-down(sm) {
              justify-content: right;
            }

            &__select {
              $padding: 0 2.55rem 0 4.4rem;
              position: relative;
              max-width: 258px;
              width: 100%;
              border-radius: .25rem;
              border: solid 2px $blue-4;
              overflow: hidden;

              &::after,
              &::before {
                background-color: $white;
                position: absolute;
                display: block;
              }

              &::after {
                content: attr(data-label);
                z-index: -2;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding: $padding;
              }

              &::before {
                content: "Sort by:";
                padding-left: .5rem;
                z-index: -1;
              }

              & .fa-chevron-down {
                overflow: hidden;
                position: absolute;
                top: 35%;
                right: 1rem;
                z-index: -1;
              }

              &,
              &:before,
              & .form-control{
                height: 34px;
                line-height: 30px;
              }

              & .form-control {
                color: transparent;
                background: none;
                width: 100%;
                cursor: pointer;
                box-sizing: border-box;
                border: none;
                display: block;
                padding: $padding;
                appearance: none;

                @include media-ie11 {
                  opacity: 0;
                }

                & option {
                  font-weight: $font-weight-normal;
                  color: #495057;
                }
              }
            }
          }

          &--tags {
            $spacing: .4rem;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 0 1rem;
            padding: 0;

            &__tag {
              flex-grow: 0;
              flex-shrink: 0;
              flex-basis: auto;
              margin: 0 #{$spacing} #{$spacing} 0;

              .btn {
                border-radius: $spacing;
                line-height: 1.4;
                max-width: none;
                padding-left: 0.7rem;
                padding-right: 0.7rem;

                &--close {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      &__row {
        &--sorting {
          align-items: flex-end;
        }

        #{$selector}__filter {
          &--sort {
            text-align: left;

            label {
              font-size: 0.7rem;
            }
          }

          &--tags {
            &__tag {
              .btn {
                max-width: 300px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          .btn-secondary {

            &:hover,
            &:focus,
            &:active {
              background-color: $blue-4 !important;
              border-color: $blue-4 !important;
              box-shadow: none !important;
            }
          }
        }
      }
    }
  }
}