body.cms-castandseek {

  font-family: 'Poppins', sans-serif;

  color: #0a253e;

  h1, h2, h3 {
    font-family: 'Poppins', sans-serif !important;
    color: #0a253e !important;
    font-weight: 900 !important;
    text-transform: uppercase;

    span { color: #e86851; }
  }

  h1 {
    font-size: 46px !important;
  }

  .btn-primary {
    border-color: #0a253e;
    background-color: #0a253e;
    color: white;
    text-transform: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    text-decoration: underline;
  }

  .container, .container-fluid {

    &.intro {
      background-color: #edede1;

      p { font-size: 1.25rem; }
    }

    &.bullets {
      background-color: white;
    }

    &.shop {
      background-color: #edede1;

      h2 { text-align: center; }

      .club-price {
        color: #dd5638;
        font-weight: bold;

        strong {
          font-size: 1.5rem;
        }
      }

      .regular-price {
        strong {
          font-size: 1.5rem;
        }
      }

      .club-savings {
        color: #dd5638;
        font-weight: bold;
        line-height: 3rem;
      }

      ul {
        li {
          list-style-type: disc;
        }
      }

    }

  }

}