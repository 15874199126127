$selector: ".pdp-technique";

#{$selector}{
  &__banner{
    display: block;
    margin: 0 auto;

    @include media-breakpoint-down(md){
      max-width: 100%;
    }

    @include media-breakpoint-up(lg){
      width: 100%;
    }
  }
  
  &__nav{
    text-align: center;
    padding: 2rem .5rem;
    background: $cream-bg;

    ul{
      margin: 0;
    }

    &-item{
      display: inline-block;
      padding: 0 1.25rem;
    }

    &-link{
      &:not(.btn){
        color: $brown-7;
        text-decoration: underline;
        font-size: $font-size-base;
        font-weight: bold;

        &.active{
          color: $blue-4;
          text-decoration: none;
        }
      }
    }

    &.sticked{
      left: 0!important;
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
      box-shadow: 0 5px 5px 0 rgba($black,.4);

      .sticky-hidden{
        display: none;
      }
    }

    &:not(.sticked){
      .sticky-visible{
        display: none;
      }
    }

    @include media-breakpoint-down(sm){
      &-item{
        padding: 0 0.25rem;
      }
    }
  }

  &__heading{
    margin: 0 auto 2.5rem;
    color: $blue-4;
    font-size: $h1-font-size;
    font-family: $font-header-primary;
    font-weight: $font-weight-bold;
    text-align: center;

    @include media-breakpoint-down(md){
      font-size: $h2-font-size;
    }
  }

  .responsive-video{
    position: relative;
    padding-bottom: 56.25%;

    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__carousel{
    #{$selector}__blurb{
      padding: 0 2rem;
    }

    @include media-breakpoint-down(md){
      text-align: center;
    }

    @include media-breakpoint-up(md){
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }

    .slick{
      &-arrow{
        top: 22%;

        &.slick-prev{
          transform: scaleX(-1) translateX(100%)!important;
        }
        &.slick-next{
          transform: translateX(100%)!important;
        }

        @include media-breakpoint-down(sm) {

          &.slick-prev,
          &.slick-next{
            border-radius: 40px 0 0 40px;
            width: 33px!important;
          }

          &.slick-prev{
            left: -8px;
          }

          &.slick-next{
            right: -8px;
          }
        }

        @include media-breakpoint-down(sm) {
          display: block!important;
          top: 14%;

          &.slick-prev{
            transform: scaleX(-1) translateX(0)!important;
          }
          &.slick-next{
            transform: translateX(0)!important;
          }
        }
      }

      &-dots{
        li{
          &,
          button{
            $size: 10px;
            width: $size;
            height: $size;
            border-radius: $size;
          }

          button{
            &:before{
              font-size: .9rem;
            }
          }
        }
      }
    }
  }

  &__blurb{
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__image{
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }

    &__title{
      color: $blue-4;
      font-size: $h4-font-size;
      font-family: $font-header-primary;
      font-weight: $font-weight-bold;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    p{
      margin-bottom: 1.5rem;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &__section{
    padding-top: 2rem;
    padding-bottom: 2rem;

    &,
    p{
      color: $grey-1;
    }

    + #{$selector}__section{
      border-top: dashed 2px $brown-2;
    }

    &--video{
      .responsive-video{
        @include media-breakpoint-up(md){
          width: 90%;
          margin-left: auto;
          margin-right: auto;

          iframe{
            box-shadow: 0 10px 20px 0 rgba($black, .4);
          }
        }
      }

      p{
        margin-top: 1rem;
        line-height: 1.8;
      }
    }

    &--buy{
      padding-bottom: 0!important;
    }

    @include media-breakpoint-up(md){
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }
}