.prop65-warning {
	margin-top: 1rem;

	.prop65-warning-link {
		font-weight: $font-weight-bold;
		text-transform: uppercase;
	}

	.fa-exclamation-triangle, .prop65-warning-link {
		color: $green-7;
	}
}

#prop65-warning-modal {
	.modal-body {
		font-size: 0.8125rem;
	}
}