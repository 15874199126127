@import "../fonts";
@import "../colors";

// Sizes
.text-xl {
  font-size: 2rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.1875rem;
}

.text-sm {
  font-size: 0.75rem;
  line-height: 0.9375rem;
}

.text-break-word {
  word-wrap: break-word;
}

// Decorations
.text-strikethrough {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

// Colors
.text-black {
  color: $black !important;
}

.text-brown {
  color: $brown-3;
}

.text-cream {
  color: $cream-2;
}

.text-cream-4 {
  color: $cream-4;
}

.text-green {
  color: $green !important;
}

.text-grey {
  color: $grey-1;
}

.text-lighter-grey {
  color: $grey-3;
}

.text-light-blue {
  color: $blue-3;
}

.text-orange {
  color: $red-3;
}

// faces
.font-face-primary {
  font-family: $font-primary;
}

.font-face-secondary {
  font-family: $font-header-primary;
}

.font-face-tertiary {
  font-family: $font-header-secondary;
}

// transform
.text-upper {
  text-transform: uppercase;
}

.text-capitalize-sentence:first-letter {
  text-transform: capitalize;
}

//special
.text-break-after:after {
  content: '<br/>';
}

// weight
.text-weight-700 {
  font-weight: 700;
}

.nobr {
  white-space:nowrap;
}