table {
  tbody {
    &.odd {
      tr.bundle-item-row {
        background-color: $table-accent-bg;
      }
    }
    &.even {
      tr.bundle-item-row {
          background-color: unset !important;
      }
    }
    tr.bundle-item-row:not(:last-of-type) td {
      border-top: none !important;
      border-bottom: none !important;
    }
    tr.bundle-item-row:last-of-type td {
      border-top: none !important;
    }
  }
}