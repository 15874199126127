$rootSelector: '.new-plp';
$plpZIndexMap: (
    'basic': 1,
    'overlay': 100,
    'menu': 110
);

#{$rootSelector}{
    > .container{
        max-width: none!important;
        padding-left: 0;
        padding-right: 0;

        > .row{
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0!important; // .mb-5 class persistent

            > .col-12{
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &__error{
        padding: 2rem 0;

        &__content{
            max-width: 80%;
            text-align: center;
            margin: 0 auto;

            img,
            video{
                max-width: 100%;
                display: block;
                margin: 0 auto;
            }
        }
    }

    .plp-breadcrumb{
        margin-top: 1rem;
        margin-bottom: 0.5rem!important;
    }

    .plp-heading{
        margin-bottom: 1rem;
        line-height: 1;
        font-family: $font-header-primary;
        font-weight: 600;
        text-transform: uppercase;
        color: $brown-7;

        &__result{
            text-transform: none;
            font-size: 1.5rem;
            color: #6c757d;
            display: inline-block;
        }
    }

    // Avoid flickering with display none, using visibility instead
    .plp-heading,
    .plp-breadcrumb{
        &--from-server{
            visibility: hidden;
        }
    }

    @include media-breakpoint-down(sm){
        &__overlay{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($black, .7);
            display: none;
            z-index: map-get($plpZIndexMap, 'overlay');
        }

        &--lock{
            overflow: hidden;

            #{$rootSelector}__overlay{
                display: block;
            }
        }

        .plp-heading{
            font-size: 2rem;
        }
    }
}
