.block-layered-nav {
  @include media-breakpoint-up(md) {
    //margin-top: 62px;
  }

  .currently {
    @extend .mb-4;
    border: 1px solid #EDE9CC;
    background-color: rgba(255,255,255,0.49);
    padding: 0.75rem;

    .block-subtitle {
      font-family: $font-header-primary;
      font-size: 1.125rem;
      font-weight: $font-weight-xbold;
      text-transform: uppercase;

      @include media-breakpoint-down(md) {
        font-size: 1rem;
      }
    }

    .filter {
      font-size: $font-size-base;

      @include media-breakpoint-down(md) {
        font-size: 0.75rem;
      }

      label {
        font-weight: $font-weight-bold;
      }

      a:hover {
          color: $grey-3;
      }
    }

    .clear-all {
      text-decoration: underline;

      @include media-breakpoint-down(md) {
        font-size: 0.75rem;
      }
    }

  }

  .toggle-content {
    .block-subtitle {
      @extend .mb-2;
      @extend .mb-1;

      text-align: center;
      display: none;
      cursor: hand;

      @include media-breakpoint-down(sm) {
        display: block;
		text-align: right;
		font-size: 1rem;
		margin-top: 0.5rem;
      }

      .fas.more:after {
          display: inline-block;
          font-family: $font-family-base;
          font-size: .85rem;
          margin-top: 0.25rem;
          content: "more filters";
          font-weight: normal;
          margin-left: 0.5rem;
          vertical-align: text-top;
          text-decoration: underline;
          cursor: pointer;
      }
      .fas.less:after {
          display: inline-block;
          font-family: $font-family-base;
          font-size: .85rem;
          margin-top: 0.25rem;
          content: "less filters";
          font-weight: normal;
          margin-left: 0.5rem;
          vertical-align: text-top;
          text-decoration: underline;
          cursor: pointer;
      }
      .fa-sliders-h:before {
        vertical-align: middle;
      }
    }
  }
}