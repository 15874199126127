@import "../colors";

.subscription-page {

  @include media-breakpoint-up(lg) {
    p.caption {
      min-height: 4rem;
    }
  }

  .btn {
    width: 180px;
  }

  .why-subscribe {
    background-color: $green-3;
  }

  .reviews {
    .trustpilot {
      background: $white;
    }
  }

  .subscription-option-box {
    .free-shipping {
      background-image: url(-mage-img('subscription/free-shipping.svg'));
      height: 65px;
      background-repeat: no-repeat;
      z-index: 1;
      position: absolute;
      width: 63px;
      top: -25px;
    }
  }

  .subscription-dropdown {
    min-height: 60px;
    margin: 16px auto;
    padding: 0;
    max-width: 330px;
    position: relative;

    &.error {
      border: 1px solid #F05630;
      background-color: #FFF9F8;
    }

    .dropdown-toggle {
      display: block;
      text-decoration: none;
      line-height: 1.75rem;
      width: 100%;
      max-width: 330px;
      text-align: left;
      &:after {
        position: absolute;
        right: 0.5rem;
        top: 50%;
      }

      &.box_count .option-value {
        padding: 0.75rem 0.25rem;
      }

      .option-value {
        display: inline-block;
        width: 100%;
        line-height: 1rem;
        margin-top: 0;
        margin-right: -3.5rem;
        padding: 0.25rem;

        &.pristine {
          padding: 1.25rem 0.25rem;
        }

        .subscription-dropdown-option {
          width: calc(100% - 14px);
          &.plan {
            height: 34px;
            .plan-pricing {
              font-size: .75rem;
            }
          }
        }
      }

      &.disabled {
        cursor: not-allowed;
        padding-left: initial;
      }
    }

    .dropdown-menu {
      margin-top: 2px;
      max-width: 330px;
      box-shadow: 0px 2px 6px #CCB;
      -webkit-box-shadow: 0px 2px 6px #CCB;
      -moz-box-shadow: 0px 2px 6px #CCB;
      width: #{(calc(100% + 2px))};
      left: -1px !important;
      padding-top: 0;
      padding-bottom: 0;

      li {
        padding: .5rem;
        border-bottom: 1px solid #eee9ce;

        &:hover, &.selected {
          background-color: $blue-2;
        }

        &:last-of-type {
          border-bottom: none;
        }

        cursor: pointer;
      }
    }

    .subscription-dropdown-option {
      display: flex;
      flex-direction: row;
      &.species {
        justify-content: flex-start;

        .species-descriptor {
          display: block;
          line-height: 1.25rem;
        }

        .species-name {
          font-weight: 700;
          text-align: left;
          align-self: center;
        }
      }
      &.plan {
        justify-content: space-between;
        height: 42px;

        .plan-name {
          align-self: center;
          font-weight: 700;
          text-align: left;
        }

        .plan-pricing {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;
          .annual-savings, .value-callout {
            color: #40b599;
            font-weight: 700;
          }
        }
      }
    }

    .subscription-option-img {
      height: 40px;
      width: 40px;
      display: inline-block;
      border-radius: 90px;
      -moz-border-radius: 90px;
      -webkit-border-radius: 90px;
      margin-right: 8px;
      margin-top: 4px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  .renewal-callout-container {
    max-width: 300px;
    min-height: 34px;
    margin: 10px auto 0;
  }
}
