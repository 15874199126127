@import "../browsers";
.card {
  background-color: $white;
  border-top: 3px solid $green-3;
  display: block;
}

@include media-ie11() {
  .position-relative {
    height: 100%;
  }
}