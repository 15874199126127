.shopping-cart {
  background: url(-mage-img('header/cart.svg')) no-repeat right top;
  background-size: contain;
  margin-left: .75rem;
  width: 2rem;
  height: 1.875rem;
  display: inline-block;
  text-decoration: none;
  position: relative;

  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
  }

  .in-the-cart {
    position: absolute;
    color: $white;
    background-color: $orange;
    text-align: center;
    padding: 0 0.25rem;
    border-radius: 1rem;
    top: -0.5rem;
    left: 1rem;
    min-width: 1.2rem;
  }
}

.header-icon {

  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin-right: 0.5rem;

  &.your-account {
    display: none;
  }

  &:hover {
    color: $orange;
    text-decoration: none;
  }

  svg {
    height: 1.875rem;
  }

  .in-the-cart {
    position: absolute;
    color: $white;
    background-color: $orange;
    text-align: center;
    padding: 0 0.25rem;
    border-radius: 1rem;
    top: -0.25rem;
    left: 1.5rem;
    min-width: 1.2rem;
    display: none; // Hide by default

    @include media-breakpoint-up(md) {
      left: 2.25rem;
    }
  }

  .header-icon-desc {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  /* Tablet and desktop adjustments */
  @include media-breakpoint-up(md) {

    margin-right: 1rem;

    &:last-of-type {
      margin-right: 0;
    }

    &.your-account {
      display: inline-block;
    }

    svg {
      height: 1.75rem;
    }
  }
}