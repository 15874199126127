@import "../fonts";
@import "../colors";
@import "../common/utils";
@import "../browsers";

.onestepcheckout-index-index {

  .page-title {
      font-size: 1.5rem;
  }

  .validation-advice {
    background-color: #c00;
    padding: 0.1rem 0.3rem;
    border-radius: 2px;
    color: white;
    font-weight: $font-weight-bold !important;
  }

  // Same "card" styling as the shopping cart
  .card {
    @extend .mb-3;
    .card-header {
      background-color: inherit;
      border: none;

      .card-title {
        @extend h5;
        font-size: 0.75rem;
      }
    }

    .card-body {
      @extend .pt-2;
      font-size: 0.75rem;

      a {
        text-decoration: underline;

        &.btn, &.pdp {
          text-decoration: none;
        }
      }

      small {
        font-size: 11px;
      }
    }
  }

  .btn-social-login {
    @extend .btn-fill;
  }

  ol, ul {
    padding: 0;
  }

  form {
    label {
      font-weight: 700;
      margin-bottom: .25rem;
    }
    .form-group {
      label.required em {
        color: $red-4;
        font-weight: $font-weight-bold;
      }
    }
  }

  #checkout-review-notices {
    margin-top: 20px;
    text-align: left;
    font-size: 0.75rem;
    border: 1px solid $green-4;
    border-radius: 5px;
    padding: 10px;
  }

  .one-step-checkout {
    padding-top: 10px;
    margin-bottom: 30px;

    button {
      &#remove-coupon,
      &#add-coupon,
      &#onestepcheckout-button-place-order {
        width: 100%;
        max-width: unset;
      }
    }

    .form-list li.control input[type="checkbox"] {
      margin-top: 4px;
    }

    .checkout-container {
      background-color: $white;
      margin-bottom: 15px;
      font-size: 0.75rem;

      //.row:not(.social-login-container) {
      //  margin-left: -15px;
      //  margin-right: -15px;
      //}

      .social-login-container .row {
        margin: 0;
      }

      > div {
        padding: 10px 15px;
      }
    }

    h3 {
      color: $white;
      font-size: 1rem;
      font-weight: $font-weight-bold;
      font-family: $font-family-base;
      text-transform: uppercase;
      padding: 10px 0;
      margin-top: 0;
      background-color: $blue-4;
      text-transform: none;

      &.step_1:before {
        content: '1';
      }
      &#shipping_step_header:before {
        content: '+';
      }
      &.step_2:not(#shipping_step_header):before {
        content: '2';
      }
      &.step_3:before {
        content: '3';
      }
      &.step_4:before {
        content: '4';
      }
      &.step_5:before {
        content: '5';
      }
      &#review_step_header:before {
        content: '\2713';
      }
      &:before {
        font-family: $font-header-primary;
        display: inline-block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        vertical-align: middle;
        text-align: center;
        line-height: 35px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        margin-right: 10px;
        margin-left: 17px;
        background-color: $red-3;
      }
    }
  }

  // shipping methods
  #shipping-method-notice {
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }

  #shipping-method-promise {
    font-size: 0.75rem;
  }

  .btn-shipping-method-group {
    margin-bottom: 1rem;

    .btn-shipping-method {
      // reset/undo a bunch of the default .btn styling
      width: 100%;
      max-width: 100%;
      border-radius: 4px;
      border: 1px solid #d0d0d0;
      padding: 1rem 1.5rem;
      margin-bottom: 1rem;
      color: $body-color;
      font-weight: normal;
      text-transform: none;
      text-align: left;
      white-space: normal;

      &.active {
        border: 2px solid #c3e6cb;
        color: #155724;
        background-color: #d4edda;
      }

      // when not active, hide the checkmark
      &:not(.active) {
        .fa-check {
          display: none;
        }
      }

      .carrier-name {
        font-weight: $font-weight-bold;
      }

      .price {
        font-weight: normal;
      }
    }
  }

  // payment methods
  #credit-card-form {
    float: none;
    .valid-creditcards img {
      width: 140px;
    }
    .braintree-card-input-field {
      height: 40px !important;
      border-color: #eee9ce !important;
      .card-type img {
        height: 38px !important;
      }
      #card-number {
        height: 38px !important;
        position: relative;
        top: -6px;
        padding-left: 45px !important;
      }
    }
    .braintree-expiration, .braintree-cvv {
      border-color: #eee9ce !important;
      height: 36px !important;
      input {
        text-align: center;
      }
    }
    a.cvv-what-is-this {
      margin-top: 10px;
      display: block;
    }
  }

  #paypal-container {
    float: none;
    .braintree-paypal-button {
      @extend .btn;
      height: 34px;
      margin: auto;
      color: #fff !important;
      float: none;
      > span {
        line-height: initial;
        height: initial;
        display: inline;
        width: 100%;
        background-size: 60px;
      }
    }
  }

  .sp-methods {
    .karls-payment-method {
      width: 205px;
      height: 42px;
      border-radius: 8px;
      display: inline-block;
      margin-left: 18px;
      vertical-align: middle;

      &.braintree-paypal {
        background: #019cde url(-mage-img-base('gene/braintree/paypal.png')) no-repeat 50%/auto 60%;
      }

      &.braintree-creditcards {
        background: #f0ecd9 url(-mage-img('checkout/credit-cards.svg')) no-repeat 50%/auto 60%;
      }

      &.affirm {
        background: url('https://cdn-assets.affirm.com/images/buttons/42x205-white.svg');
      }
    }

    span.saved-expiry-date {
      font-weight: bold !important;
      em {
        font-style: normal;
        color: #333 !important;
      }
    }
  }

  // Payment method overrides (magestore)
  #creditcard-saved-accounts, #paypal-saved-accounts {
    font-size: 14px;
    margin-left: 5%;
    width: 95%;
  }

  #payment_form_gene_braintree_paypal p,
  #container_payment_method_gene_braintree_creditcard p {
    font-size: 14px;
  }

  #creditcard-saved-accounts label,
  #paypal-saved-accounts label {
    img {
      height: auto;
    }
  }

  #paypal-complete {
    padding: 0;
    width: 100%;
  }

  // review
  .data-table {
    tr > th {
      font-size: 12px;
    }

    .item-options {
      margin: 0;
    }
  }

  div.button-onestepcheckout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 20px;
  }

  .subscription-payment-toggle.subscription-notice {
    margin: 0;
    position: relative;

    input[type="checkbox"] {
      position: absolute;
      top: 10px;
      width: 18px;
      height: 18px;
      margin-top: 3px;
      float: none;
    }

    .message {
      font-size: 14px;
      font-weight: 400;
      padding: 10px 0;
      margin: 0 0 0 30px;
      text-align: left;
      border: none;
    }

    .tip-container {
      position: relative;
    }
  }

  // Keeping the loader for now until we have something cleaner, visual UX is handy, otherwise it looks like something is happening w/ no indicators
  .ajax-loader3 {
    width: 24px;
    height: 24px;
    background: url(-mage-img('loaders/green.gif')) center/contain no-repeat;
    z-index: 999;
    clear: both;
    float: none;
    margin: 0 !important;
    position: absolute;
    top: 16px;
    right: 20px;
  }

  #onestepcheckout-login-loading {
    background: url(-mage-img('loaders/red-lg.gif')) center center no-repeat;
    height: 70px;
    float: left;
    width: 100%;
    padding: 10px 0;

  }
  @include media-ie11 {
    .billing_address {
      .social-login-container {
        display: block;
        .b {
          margin: 8px auto;
        }
      }
    }
  }

  #checkout-review-table {
    &.data-table {
      tbody {
        border-bottom: 1rem solid transparent;

        td {
          @extend .pt-3;
          border-bottom: 1px dotted #eeeeee;

          .product-name {
            font-weight: $font-weight-bold;
          }

          .item-options {
            dt {
              display: none;
            }
            dd {
              margin-bottom: 0;
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
  }
}

h5 {
  &.subhead {
    font-size: 0.875rem;
  }
}
