@import "../colors";
@import "../fonts";
@import "../common/utils";

.modal {
  &.fade.in {
    opacity: 1 
  } 
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $blue-4;
    padding: .8rem;
    .modal-title, .close {
      color: $white;
    }
    .modal-title {
      text-transform: uppercase;
      font-family: $font-header-primary;
      font-weight: $font-weight-bold;
      font-size: 1.25rem;
      letter-spacing: 0;
      order:1;
    }

    .close {
      opacity: 1;
      margin: 0 0 0 auto;
      padding: 0;
      order: 99;
    }
  }

  &.modal-light {
    .modal-header {
      background-color: $white;
      .modal-title, .close {
        color: $blue-4;
      }

      &.branded {
        background: url(-mage-img('karls-arch.svg')) center no-repeat;
      }

      &.borderless {
        border: none;
      }
    }
  }

  /*
  welcome back only
   */
  &#welcome-back {
    .welcome {
      border-bottom: 1px dashed $grey-3;
      * {
        text-transform: none;
      }
      .welcome-back {
        color: $black;
        font-weight: 600;
      }

      .item-count {
        color: $blue-4;
        font-family: $font-header-secondary;
        font-size: 1.3875rem;
        @include media-breakpoint-up(md) {
          font-size: 1.6875rem;
        }
      }
    }

    .subtotal {
      color: $black;
      font-weight: 600;
    }

    .continue {
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}

.modal-backdrop {
   &.in {  
    opacity: 0.5;
  }
}
