$success: #40B599;
$error: #DD5638;

#toast-container {
  & > div {
    opacity: 1;
    border-radius: 0;
    padding: 16px;
  }
  &.toast-top-full-width > div {
    width: 100%;
  }
  & > div.toast {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    .toast-close-button {
      order: 1;
      align-self: flex-end;
      position: unset;
      right: unset;
      top: unset;
      float: unset;
      color: $white;
      opacity: 1;
      margin: auto 0;
      &:hover, &:focus {
        color: $white;
        opacity: 1;
      }
    }
    .toast-message {
      order: 0;
    }
    &.toast-success {
      background-image: none!important;
      background-color: $success;

    }
    &.toast-error {
      background-image: none!important;
      background-color: $error;
    }
  }
}

ul.messages {
  @extend .pl-0;
  font-size: 0.75rem;

  ul {
    @extend .pl-0;
  }

  li li {
    @extend .alert;

    a {
      @extend .alert-link;
    }
  }

  .error-msg li {
    @extend .alert-danger;
    a {
      color:inherit;
      background-color: inherit;
      text-decoration: underline;
      white-space: nowrap;
      font-weight: $font-weight-normal;
    }
  }

  .success-msg li {
    @extend .alert-success;
  }

  .notice-msg li {
    @extend .alert-info;
  }

  .warning-msg li {
    @extend .alert-warning;
  }

}
