$selector: ".member-only";

#{$selector} {
  $font-size: .75rem;
  margin-bottom: .75rem;
  font-size: $font-size;
  font-weight: $font-weight-bold;
  color: $green;
  display: flex;
  align-items: center;

  &:before{
    content: "";
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: .2rem;

    width: 20px;
    height: 23px;
    background: url(-mage-img('club/karls-hook.png')) no-repeat center center;
    background-size: contain;
  }

  a.learn-more {
    text-decoration: underline;
    font-size: $font-size;
    font-weight: $font-weight-normal;
    color: $grey-1;
    margin-left: 0.3rem;
  }
}